import React, { useState } from "react";
import { Alert, Button, DatePicker } from "rsuite";
import Print from '../../assets/print-reports.png'
import axios from "../../utilities/axios";
import dayjs from "dayjs";

const locale = {
    sunday: "DON",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "Ok",
    today: "Hoje",
    yesterday: "Ontem",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
};

const ReportModalNew = (props: any) => {
    const [filterValues, setFilterValues] = useState<any>({})
    const [isLoading, setIsLoading] = useState(false)

    const onChange = (alias: any, field: any, op: any, value: any) => {
        setFilterValues({ ...filterValues, [alias]: { field: field, op: op, value: value } })
    };


    const onClickButton = () => {
        console.log('props KKK ', props.searchProps);
        
        if(props.searchProps) {
            let filterValuesClick = {};
            const { operators, search } = props.searchProps;
            console.log({operators},{search})
            if (operators.searchAll) {
                filterValuesClick = { ...filterValuesClick, searchAll: operators.searchAll };
            }
            for(const column in search) {
                if(search[column] && !operators.searchAll) {
                    filterValuesClick =
                    {
                        ...filterValuesClick,
                        [column] : {
                            field : column,
                            op: operators[column],
                            value: search[column]
                        }
                    }
                }
            }
            setIsLoading(true)
            axios.post("/api/v1/reports/generate", {
                crud: props.globals.table,
                params: filterValuesClick
            }).then((res) => {
                setTimeout(() => {
                    window.open('https://' + window.location.host.split('25565').join('25567') + res.data.outputUrl)
                    props.onComplete()
                }, 4500);
    
            }).catch(() => {
                Alert.error("Falha ao gerar arquivo XLSX");
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }

    return (<>
        <div style={
            {
                display: "flex",
                flexDirection: 'column'
            }
        }>
            <p
                style={
                    {
                        fontWeight: '600'
                    }
                }
            >
                Seu relatório será realizado a partir dos filtros informados na tela.
            </p>
            <div>
                <img
                    alt="imagem mostra o formato padrao para impressao"
                    src={Print}
                    style={
                        {
                            width: '100%',
                            height: "40%",
                        }
                    }

                />
            </div>
        </div>
        <div style={{ float: "right" }}>

            <Button loading={isLoading} onClick={(() => onClickButton())} appearance="primary" color="green">Gerar Excel</Button>
        </div>
    </>)
}

export { ReportModalNew }