import { Switch, Route, useRouteMatch, useLocation } from "react-router-dom";
import DefaultDashboardRouteChat from "../chat/DefaultDashboardRouteChat";
import { ReadListItems } from "../chat/ReadListItems";
import { ChatList } from "../chat/ChatLIst";
import { ChatPage } from "../chat/ChatPage";
import { ChatProvider } from "../../components/chatClient/chatProvider/main";
import { useEffect } from "react";

export default function ChatRoutes(props: any) {
    const { path } = useRouteMatch();
    const location = useLocation();
    
    return (
        <div>
            <ChatProvider>
                <Switch location={location}>
                    <Route exact path={`${path}/:token`}>
                        <DefaultDashboardRouteChat hiddenSidebar={props.hiddenSidebar} />
                    </Route>
                    <Route exact path={path + '/item/:projectId'} component={ReadListItems} />
                    <Route exact path={path + '/item/:projectId/chat-client'} component={ChatPage} />
                    <Route exact path={path + '/item/:projectId/chat-client/:ppgstfId'} component={ChatPage} />
                    <Route exact path={path + '/item/:projectId/chats/:projectProductId/:projectProductFkProductId'} component={ChatList} />
                    <Route exact path={path + '/item/:projectId/chats/:projectProductId/:projectProductFkProductId/:ppgstfId'} component={ChatPage} />
                </Switch>
            </ChatProvider>
        </div>
    );
}


