import React from "react";
import { Button, Input, Nav, Panel, Placeholder, Table, Pagination, Checkbox, List, Modal, DatePicker, Notification, Drawer } from "rsuite";
import axios from "../../../../utilities/axios";
import _ from "lodash"
import dayjs from "dayjs";


const { Column, HeaderCell, Cell } = Table;

const locale = {
    sunday: "DON",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "Ok",
    today: "Hoje",
    yesterday: "Ontem",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
};

export class MbmClients extends React.Component<any, {}> {
    public state = {
        values: [],
        isLoading: false,
        currentPage: 1,
        totalPages: 0,
        totalRegisters: 0,
        registerPerPage: 10,
        selectedValues: [],
        selectedValuesData: [],
        filter: {},
        showModal: false,
        successMessage: ""
    }

    getData = (page = this.state.currentPage) => {
        this.setState({ isLoading: true })
        axios.get("/api/v1/integrations/mbm/clients", {
            params: {
                currentPage: this.state.currentPage,
                perPage: this.state.registerPerPage,
                filters: this.state.filter

            }
        }).then((res) => {
            this.setState({
                values: res.data.data,
                currentPage: res.data.pagination.currentPage,
                totalPages: res.data.pagination.totalPage,
                totalRegisters: res.data.pagination.total
            })
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    changePage = (page: any) => {
        this.setState({ currentPage: page }, () => {
            this.getData()
        })

    }

    toggleItem = (item: any) => {
        let array = this.state.selectedValues
        let arrayData: any = this.state.selectedValuesData
        let newData: any = []
        let codigo = item?.cod_clifor
        // Verifica se o item já existe no array
        if (_.includes(array, codigo)) {
            // Remove o item se ele já existir
            newData = _.without(array, codigo);
            arrayData = arrayData.filter((i: any) => i?.cod_clifor != codigo)
        } else {
            // Adiciona o item se ele não existir
            newData = [...array, codigo];
            arrayData.push(item)
        }
        // Remove duplicatas usando Set
        // newData = Array.from(new Set(newData));

        return this.setState({
            selectedValues: newData,
            selectedValuesData: arrayData

        })
    };

    componentDidMount(): void {
        this.getData()
    }

    changeFilter = (field: any, item: any) => {
        let newFilter: any = {
            ...this.state.filter,
        }

        if ((field.toLowerCase()).includes("codigo")) {

            newFilter = {
                ...newFilter,
                [field]: item.includes("/") ? item : `${item}`
            }


        } else {
            newFilter = {
                ...newFilter,
                [field]: item.includes("/") ? item : `%${item}%`
            }
        }


        if (item?.length < 3) {
            delete newFilter[field]
        }
        this.setState({
            filter: { ...newFilter }
        })
    }

    submitImportData = () => {
        this.setState({ isLoading: true, successMessage: "" })
        axios.post("/api/v1/integrations/mbm/import/customers", {
            data: this.state.selectedValuesData
        }).then((res) => {
            if (res.status == 200) {
                this.setState({
                    successMessage: `Inserido: ${res?.data?.totalInserted} \n | Atualizado ${res?.data?.totalUpdated}`
                })

                Notification.success({
                    title: "Sucesso",
                    description: `Inserido: ${res?.data?.totalInserted} \n | Atualizado ${res?.data?.totalUpdated}`
                })

                if (res.data.errors && (res?.data?.errors)?.length > 0) {
                    Notification.error({
                        title: "Erros",
                        description: `${res.data.errors?.toString()?.split(",")}`
                    })
                } else if (res?.data?.totalUpdated > 0 || res?.data?.totalInserted > 0) {
                    this.modalAction("close")
                }

            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }


    modalAction = (action = "close") => {
        return this.setState({ showModal: action == "close" ? false : true })
    }

    renderModalImport = () => {
        return (<>
            <Drawer
                full
                show={this.state.showModal}
                onHide={() => this.modalAction()}
            >
                <Drawer.Header>
                    <Drawer.Title>Importação MBM | Produtos</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <div>
                        <Table
                            height={400}
                            data={this.state.selectedValuesData || []}
                            loading={this.state.isLoading}
                        >

                            <Column flexGrow={4} width={300} align="left" fixed>
                                <HeaderCell>Cliente</HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        return (<>
                                            <span>{rowData?.cgc_cpf} - {rowData?.fantasia}</span>
                                        </>)
                                    }}
                                </Cell>
                            </Column>
                            <Column flexGrow={2} width={300} align="left" fixed>
                                <HeaderCell>Email</HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        return (<>
                                            <span>{rowData?.email}</span>
                                        </>)
                                    }}
                                </Cell>
                            </Column>
                            <Column width={70} align="left" fixed>
                                <HeaderCell>Ação</HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        return (<>
                                            <Button style={{ marginTop: -8 }} appearance="primary" color="red" onClick={() => this.toggleItem(rowData)}><i className="fas fa-fw fa-trash"></i></Button>

                                        </>)
                                    }}
                                </Cell>
                            </Column>
                        </Table>
                    </div>
                </Drawer.Body>
                <Drawer.Footer>
                    <Button onClick={() => this.submitImportData()} loading={this.state.isLoading} appearance="primary" color="green"><i className="fas fa-fw fa-sync"></i> Importar Dados</Button>
                    <Button onClick={() => this.modalAction()} appearance="subtle">Fechar</Button>
                </Drawer.Footer>
            </Drawer >
        </>)
    }

    render(): React.ReactNode {
        return (<>
            <div style={{ width: "100%" }}>
                <Panel bordered>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: 5 }}>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <label><b>De</b></label>
                                <DatePicker locale={locale} format="DD/MM/YYYY" onChange={(value) => { this.changeFilter("dInclusaoInicial", dayjs(value).format("DD/MM/YYYY")) }} />
                            </div>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <label><b>Até</b></label>
                                <DatePicker locale={locale} format="DD/MM/YYYY" onChange={(value) => { this.changeFilter("dInclusaoFinal", dayjs(value).format("DD/MM/YYYY")) }} />
                            </div>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <label><b>Código</b></label>
                                <Input onChange={(value) => { this.changeFilter("ordem_producao.cod_clifor", value) }} />
                            </div>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <label><b>Descrição</b></label>
                                <Input onChange={(value) => { this.changeFilter("cDescricao", value) }} />
                            </div>

                        </div>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: 5 }}>
                            <Button style={{ height: 40, marginTop: 16 }} appearance="primary" color="blue" onClick={() => { this.getData(1) }}><i className="fas fa-fw fa-search"></i> Buscar</Button>
                            <Button style={{ height: 40, marginTop: 16 }} appearance="primary" color="violet" onClick={() => { this.setState({ filter: {} }, () => this.getData()) }}><i className="fas fa-fw fa-filter"></i> Limpar Filtro</Button>
                            <Button style={{ height: 40, marginTop: 16 }} appearance="primary" color="green" onClick={() => { this.modalAction("open") }}><i className="fas fa-fw fa-sync"></i> Importar</Button>
                        </div>
                    </div>
                </Panel>
                {this.state.showModal && this.renderModalImport()}

                <Table
                    height={400}
                    data={this.state.values || []}
                    loading={this.state.isLoading}
                >
                    <Column width={70} align="left" fixed>
                        <HeaderCell>#</HeaderCell>
                        <Cell>
                            {(rowData: any) => {
                                return (<>
                                    <Checkbox checked={_.includes(this.state.selectedValues, rowData?.cod_clifor)} onChange={(value, check) => {
                                        this.toggleItem(rowData)
                                    }} />
                                </>)
                            }}
                        </Cell>
                    </Column>
                    <Column flexGrow={4} width={300} align="left" fixed>
                        <HeaderCell>Cliente</HeaderCell>
                        <Cell>
                            {(rowData: any) => {
                                return (<>
                                    <span>{rowData?.cgc_cpf} - {rowData?.fantasia}</span>
                                </>)
                            }}
                        </Cell>
                    </Column>
                    <Column flexGrow={2} width={300} align="left" fixed>
                        <HeaderCell>Email</HeaderCell>
                        <Cell>
                            {(rowData: any) => {
                                return (<>
                                    <span>{rowData?.email}</span>
                                </>)
                            }}
                        </Cell>
                    </Column>
                    {/* <Column flexGrow={2} width={300} align="left" fixed>
                        <HeaderCell>Ordem Prod.</HeaderCell>
                        <Cell>
                            {(rowData: any) => {
                                return (<>
                                    <span>{rowData?.cod_clifor}</span>
                                </>)
                            }}
                        </Cell>
                    </Column> */}

                    {/* <Column flexGrow={2} width={300} align="left" fixed>
                        <HeaderCell>Data</HeaderCell>
                        <Cell>
                            {(rowData: any) => {
                                return (<>
                                    <span>{dayjs(rowData?.dt_solicitada_limite).format("DD/MM/YYYY")}</span>
                                </>)
                            }}
                        </Cell>
                    </Column> */}
                </Table>

                <div style={{ padding: 20 }}>
                    Total: {this.state.totalRegisters}
                    <Pagination
                        style={{ float: "right" }}
                        activePage={this.state.currentPage}
                        pages={this.state.totalPages}
                        maxButtons={5}
                        prev={true}
                        first={true}
                        next={true}
                        last={true}
                        onSelect={(page: number) => this.changePage(page)}
                    />
                </div>

            </div>
        </>)
    }
}