import React from "react";
import { Container, Content, Drawer, Loader, Panel } from "rsuite";
import { Helmet } from "react-helmet";
import axios from "../../../../utilities/axios";
import { motion } from "framer-motion";
import { zoomIn } from "../../../motion/motionSettings";

export class ProjectMedia extends React.Component<any, {}> {
    state: any = {
        pathRoute: "projects",
        paramsRoute: {},
        loadings: false,
        isDrawerOpen: true,
        projectData: null,
        stepData: null,
        mediaData: null,
        isExpanded: false,
    };

    constructor(props: any) {
        super(props);
        // Vinculando o método ao contexto do componente
        this.nextRouter = this.nextRouter.bind(this);
        this.previousRoute = this.previousRoute.bind(this);
    }

    componentDidMount(): void {
        this.mountedRouterInitial();
        console.log(this);
    }

    componentDidUpdate(prevProps: any) {}

    mountedRouterInitial() {}

    nextRouter({
        route,
        paramsRoute,
    }: {
        route: string;
        paramsRoute: Record<string, any>;
    }) {
        this.setState({
            pathRoute: route,
            paramsRoute: paramsRoute,
        });
    }

    previousRoute({
        route,
        dataParams,
    }: {
        route: string;
        dataParams: Record<string, any>;
    }) {
        dataParams.pathUrl.pop();
        this.setState({
            pathRoute: route,
            paramsRoute: dataParams,
        });
    }

    getProject(projectId: string) {
        if (projectId) {
            axios
                .get(`/api/v1/projects/${projectId}`)
                .then(async (response) => {
                    console.log(response);

                    // this.setState({ projectData: response.data.item });
                });
        }
    }

    getMedia(chatId: number) {
        if (chatId) {
            axios
                .get(`/api/v1/chats/get/media/${chatId}`)
                .then(async (response) => {
                    this.setState({ projectData: response.data.item });
                });
        }
    }

    getStep = (ppgstfId: string) => {
        if (ppgstfId) {
            axios.get(`/api/v1/get-step-by-ppgstf/${ppgstfId}`).then((response) => {
                this.setState({ stepData: response.data.item });
            });
        }
    };

    handlePanelToggle = () => {
        this.setState({ isExpanded: !this.state.isExpanded });
    };

    render(): React.ReactNode {
        return (
            <div className="container">
                <Helmet>
                    <title>FastFlow - Chats</title>
                </Helmet>
                <Panel bordered className="rs-no-body mb-4">
                    <div className="d-flex justify-contents-between align-items-center">
                        <div></div>
                        <div>
                            <h5 className="d-flex align-items-center">
                                <i className="fas fa-fw mr-4 fa fa-comments"></i>
                                Mídias
                            </h5>
                        </div>
                        <div></div>
                    </div>
                </Panel>
                <Panel>
                    <Container
                        style={{
                            height: "75vh",
                            width: "100%",
                            gap: "10",
                        }}
                    >
                        <Content>
                            <Panel
                                defaultExpanded={this.state.isExpanded}
                                // eventKey={index}
                                // key={index}
                                expanded={this.state.isExpanded}
                                collapsible
                                // onExit={() => this.deselectItem({ data: value })}
                                header={
                                    <>
                                        <div
                                            style={{
                                                width: "100%",
                                                minHeight: "4rem",
                                                // borderRadius: 10,
                                                boxShadow:
                                                    "0 2px 8px rgba(0, 0, 0, 0.4)",
                                                display: "flex",
                                                flexDirection: "row",
                                                backgroundColor: "#131047",
                                                cursor: "pointer",
                                            }}
                                            onClick={() =>
                                                this.handlePanelToggle()
                                            }
                                        >
                                            <div
                                                style={{
                                                    width: "8%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <i
                                                    className="far fa-box fa-2x "
                                                    style={{ color: "#fff" }}
                                                ></i>
                                                <p style={{ color: "#fff" }}>
                                                    Chats
                                                </p>
                                            </div>
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                    flexWrap: "wrap",
                                                    background: "#f5f5f5",
                                                    paddingLeft: 10,
                                                    // borderRadius: 10,
                                                    // alignItems: "center",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            flexDirection:
                                                                "column",
                                                            marginLeft: 12,
                                                        }}
                                                    >
                                                        <label>Item</label>

                                                        <h6>Mídias</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            ></Panel>
                        </Content>
                    </Container>
                </Panel>
            </div>
        );
    }
}
