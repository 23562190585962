import { Event } from "../Event";

export class ProjectProductEvents extends Event {
    public onLoad = async (value: any, setField: any, setState: any) => {
        if (this.globalProps.parentValues) {
            const values = this.globalProps.parentValues
            this.setField('project_product_fk_product_id', {
                "api": {
                    "method": "GET",
                    "uses": [
                        {
                            "type": "value",
                            "field": values?.project_type_fk_product_type_id,
                            "as": "product_type_fk_product_type_id"
                        }
                    ],
                    "url": "/api/v1/products/select"
                }
            });

            // console.log('onLoad project_products', this.globalProps.values?.products?.product_type_fk_product_type_id)
            if (this.globalProps.values?.products?.product_type_fk_product_type_id === 1 || this.globalProps.values?.products?.product_type_fk_product_type_id === 2) {             

                this.setField('project_product_quantity', {
                    hidden: false
                });

                this.setField('project_product_value', {
                    hidden: false
                });
            } else {
                this.setField('project_product_quantity', {
                    hidden: true
                });

                this.setField('project_product_value', {
                    hidden: true
                });
            }
        }
    }

    
}