import React from "react";
import { Icon, InputGroup, Loader, SelectPicker, SelectPickerProps } from "rsuite";
import axios from "../../utilities/axios";
import _ from "lodash";
import { AdvancedSearchModal } from "./AdvancedSearchModal";
import { Link } from "react-router-dom";
import pluralize, { singular } from "pluralize";
import { CustomExtensions } from "../../custom/CustomExtensions";
import { Permission } from "../Permission";
import { UserContext } from "../authProvider/main";

interface AxiosSelectPickerProps extends SelectPickerProps {
    api?: any;
    uses?: any;
    onChange?: any;
    [key: string]: any;
}

function getDiff(a: any, b: any) {
    var diff = isArray(a) ? [] : {};
    recursiveDiff(a, b, diff);
    return diff;
}

function recursiveDiff(a: any, b: any, node: any) {
    for (var prop in a) {
        if (typeof b[prop] === "undefined") {
        } else if (JSON.stringify(a[prop]) !== JSON.stringify(b[prop])) {
            if (typeof b[prop] !== "object" || b[prop] == null) {
                addNode(prop, b[prop], node);
            } else {
                if (isArray(b[prop])) {
                    addNode(prop, [], node);
                    recursiveDiff(a[prop], b[prop], node[prop]);
                } else {
                    addNode(prop, {}, node);
                    recursiveDiff(a[prop], b[prop], node[prop]);
                }
            }
        }
    }
}

function addNode(prop: any, value: any, parent: any) {
    parent[prop] = value;
}

function isArray(obj: any) {
    return Object.prototype?.toString.call(obj) === "[object Array]";
}

export class AxiosSelectPicker extends React.Component<AxiosSelectPickerProps, {}> {
    static contextType = UserContext;
    public state = {
        search: "",
        selectedData: {},
        data: _.uniqBy(this.props.data, "value"),
        preloadedData: _.uniqBy(this.props.data, "value"),
        value: (this.props.defaultValue && this.props?.isNumber != true) ? this.props.defaultValue?.toString() : this.props.defaultValue,
        lastSelectedValue: {},
        isAdvancedModalSearchOpen: false,
        isAdvancedOpenModal: false,
        isLoading: false,
        teste: false,
    };

    public inputRef: React.RefObject<HTMLElement>;
    constructor(props: AxiosSelectPickerProps) {
        super(props);
        this.inputRef = React.createRef();
    }


    public eventHandler: any = null;
    loadCustoms = () => {
        // if (this.state.form !== state.form) {
        if (this.props?.name) {
            const eventHandlerName = _.upperFirst(_.camelCase(pluralize.singular(this.props?.name ?? ''))) + "Events";


            const customList: any = CustomExtensions;
            // console.log(customList);
            //     console.log(customList);
            // alert(eventHandlerName);
            if (customList[eventHandlerName]) {
                this.eventHandler = new customList[eventHandlerName]();
                this.eventHandler.value = this.state.value;
            }
        }
        // }
    };

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.value !== this.state.value) {
            this.loadCustoms();
        }
    }

    componentDidMount() {
        //
        if (this.props.value) {
            if (typeof this.props.value == "object") {
                if (this.props.value["label"] && this.props.value["value"]) {
                    this.setState({ data: _.uniqBy([this.props.value, ...(this.props.options ?? [])], "value"), value: this.props.value["value"] });
                    this.props.onChange(this.props.value["value"], [this.props.value]);
                }
            }
            //
        }
        this.loadCustoms();
        this.handleUpdate("", true,'open');
    }

    shouldComponentUpdate(props: any, state: any) {
        // if (props.value) {
        if (this.props.value !== props.value) {
            if (typeof props.value == "object" && props.value !== null) {
                // alert(JSON.stringify(props.value));
                if (props?.value["label"] && props?.value["value"]) {
                    // this.setState({ data: [props.value], value: props.value["value"] }, () => {
                    //     this.props.onChange(props.value["value"], this.state.data);
                    // });
                }
            } else if (props?.value === null || props?.value === undefined) {
                this.setState({ data: _.uniqBy(this.props.data ?? [], "value") });
            }
            //
        }
        // if(this.state.data !== _.uniqBy(props.options ?? [], "value")) {
        // this.setState({ data: _.uniqBy(props.options ?? [], "value") });
        // alert("DIFF");
        // }
        if (this.state.data?.length <= 0 && this.props.data?.length > 0) {
            this.setState({ data: _.uniqBy(this.props.data, "value") });
        }
        // }
        if (Object.values(getDiff(this.props.uses, props.uses)).length > 0 && this.state.data.length) {
            this.setState({ data: [] });
            if (this.props.onChange) {
                this.props.onChange(undefined, null);
            }
        }
        return true;
    }

    public value: null | string | void = null;

    public handleUpdate = (value: string | void, cache = false, type?: 'open' | 'search' | undefined) => {
        if (value === undefined) {
            value = "";
        }
        // if (this.value !== value || this.state.data?.length <= 0) {
        this.value = value;

        var searchValue = this.props.searchValue == false ? null : { ...this.props.searchValue };
        const nameCrud = this.props.api ? this.props.api.url.split('/select').shift().split('/v1/').pop() : '';  
        const uses = this.props.useActive ? { ...this.props.uses, [`${singular(nameCrud)}_active`]: true} : {...this.props.uses}
        var params = { ...uses, cache: false, search: value, concat: this.props.displayLabel !== undefined ? this.props.displayLabel : undefined, searchValue: searchValue };
        
        // console.log({type})
        // if(type === undefined){
            if (this.props.value && (!value || value == "")) {
                params = { ...params, cache: false, currentValueSearch: this.props.value }
    
            }
        // }
        if (sessionStorage.getItem("currentParentIndex")) {
            params["parentIndex"] = sessionStorage.getItem("currentParentIndex");
        }
        if (this.props.api) {
            // console.log("Axios > ", this.props.api);
            this.setState({ isLoading: true, data: [] });
            axios
                .get(this.props.api.url, { params })
                .then((res) => {
                    var arr = [...res.data.items];
                    arr = _.uniqBy(arr, "value");
                    if (!['project_product_appointment_fk_step_id', 'project_product_appointment_fk_project_product_id', 'project_product_appointment_fk_user_id'].includes(this.props.name)) {
                        if (this.state.preloadedData) {
                            var totalData = _.map([...this.state.preloadedData, ...arr], (item) => {
                                if (item && item.value) {
                                    item.value = item.value?.toString();
                                    return item;
                                }
                                return undefined;
                            });
    
                            arr = _.uniqBy(totalData, "value");
                        }
                    }
                    if (!_.isArray(arr)) {
                        arr = [];
                    }
                    arr = _.compact(arr);
                    //console.log({ arr });
                    if (this.props.onFirstLoad) {
                        this.props.onFirstLoad(arr);
                    }
                    this.setState({ data: arr, isLoading: false });
                })
                .catch((e) => {
                    this.setState({ isLoading: false });
                })
                .finally(() => {
                    try {
                        var searchInput = document.getElementsByClassName("rs-picker-search-bar-input")[0] as HTMLInputElement;
                        searchInput.focus();
                    } catch (e) { }
                });
        } else {
            setTimeout(() => {
                try {
                    var searchInput = document.getElementsByClassName("rs-picker-search-bar-input")[0] as HTMLInputElement;
                    searchInput.focus();
                } catch (e) { }
            }, 100);
        }
        // }
    };

    searchBy(keyword: string, label: any, item: any): boolean {
        var striped = label
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLocaleLowerCase();
        //
        return striped.includes(
            keyword
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
                .toLocaleLowerCase(),
        );
    }

    getItemByValue(value: any) {
        for (var i in this.state.data) {
            var data = this.state.data[i];
            if (data.value === value) {
                return data;
            }
        }
        return null;
    }

    onChange = (value: any) => {
        var preloadedData = [this.getItemByValue(value)];
        // alert(JSON.stringify());
        this.setState({ value, preloadedData }, () => {
            if (this.props.onChange) {
                // alert(value);
                this.props.onChange(value, this.state.data);
            }
        });
    };

    onAdvancedSelect = (value: any) => {
        this.setState({ data: [value], value: value["value"], preloadedData: [value], isAdvancedModalSearchOpen: false });
        // alert("ADV "+value['value'])
        this.props.onChange(value["value"], [value]);
        this.closeModalCancel()
        // this.setState({  })
    };

    onAdvancedInclude = () => {
        const apiValue = this.props.api.url
        const apiValueSplit = apiValue.split("/api/v1/").join("")
        const apiName = apiValueSplit.split("/select").join("")

        return (
            <>
                <Link key={1} target={"_blank"} to={`/dashboard/registrations/${apiName}/add`}>
                    <InputGroup.Button color="green">
                        <i className="fas fa-plus fa-fw"></i>
                        {/* {item} */}
                    </InputGroup.Button>
                </Link>
            </>
        );
    }

    checkPermission = (key: string, user: any) => {
        // console.log("teste ::: ", {key}, {user});
        if (user?.role?.role_permissions) {
            // alert(user?.role?.role_id);
            if (user?.role?.role_id === 2) {
                return true;
            }
            var perms: string[] = user?.role?.role_permissions.map((item: any) => {
                return item.role_permission_value;
            })
            for (var index in perms) {
                var perm = perms[index].split('-').join('_');
                // if(!perm.includes('-')) {
                //     perms = [...perms,perm+'-browse',perm+'-read',perm+'-edit',perm+'-add',perm+'-delete']
                // }
            }
            // perms.map((value:any,index:any) => {
            //     return console.log('novo teste ::: ', {value},{index})
            // })
            let permissionTeste = false
            for (let i in perms) {
                if (perms[i].includes(key)) {
                    permissionTeste = true;
                    continue
                }
            }
            return permissionTeste
        }
        return false;
    }

    closeModalCancel = () => {
        this.setState({
            isAdvancedOpenModal: false,
        })
    }

    render() {
        var validProps = { ...this.props };
        if (validProps["isEdit"] !== undefined) {
            delete validProps["isEdit"];
        }
        if (validProps["value"] !== undefined) {
            delete validProps["value"];
        }
        // alert();
        var fieldComponent = (
            <SelectPicker
                {...validProps}
                block
                ref={this.inputRef}
                onEntered={() => { }}
                onOpen={() => {
                    this.handleUpdate("");
                }}
                onSearch={(value) => {
                    this.handleUpdate(value);
                }}
                disabled={this.props.disabled || this.props.readOnly || (!this.props.canEdit && this.props.isEdit != null && !this.props.typesCustom)}
                onChange={this.onChange}
                data={this.state.data}
                searchBy={this.searchBy}
                value={this.state.value}
                renderMenu={(menu) => {
                    if (this.state.isLoading === false && this.state.data.length > 0) {
                        return menu;
                    }
                    if (this.state.data.length <= 0 && this.state.isLoading === false) {
                        return <p style={{ padding: 6, paddingBottom: 12, color: "#999", textAlign: "center" }}>Nenhum item encontrado.</p>;
                    }
                    if (this.state.isLoading === true) {
                        return (
                            <p style={{ padding: 6, paddingBottom: 12, color: "#999", textAlign: "center" }}>
                                <Loader />
                            </p>
                        );
                    }
                }}></SelectPicker>
        );
        if (this.props.isEdit && this.state.value !== undefined) {
            fieldComponent = (

                <SelectPicker
                    {...validProps}
                    ref={this.inputRef}
                    block
                    onOpen={this.handleUpdate}
                    onSearch={(value) => {
                        this.handleUpdate(value);
                    }}
                    searchBy={this.searchBy}
                    onChange={this.onChange}
                    disabled={this.props.disabled || this.props.readOnly || (!this.props.canEdit && this.props.isEdit != null && !this.props.typesCustom)}
                    data={_.uniqBy(this.state.data, "value")}
                    value={this.state.value}
                    renderMenu={(menu) => {
                        if (this.state.isLoading === false && this.state.data.length > 0) {
                            return menu;
                        }
                        if (this.state.data.length <= 0 && this.state.isLoading === false) {
                            return <p style={{ padding: 6, paddingBottom: 12, color: "#999", textAlign: "center" }}>Nenhum item encontrado.</p>;
                        }
                        if (this.state.isLoading === true) {
                            return (
                                <p style={{ padding: 6, paddingBottom: 12, color: "#999", textAlign: "center" }}>
                                    <Icon icon="spinner" spin></Icon>
                                </p>
                            );
                        }
                    }}></SelectPicker>
            );
        }
        if (this.props.advanced || this.props.shortcut || this.eventHandler?.getSuffix) {
            const parsedApiUrl = this.props.api.url.split("/api/v1/").pop().split("/select").shift();
            // console.log({parsedApiUrl})
            return (
                <>
                    <InputGroup>
                        {fieldComponent}
                        {this.props.shortcut == true && (this.state.value !== null && this.state.value !== undefined) && (
                            <>
                                <Link target={"_blank"} to={"/dashboard/registrations/" + parsedApiUrl + `${this.checkPermission(parsedApiUrl, this.context.data) !== false ? '/' : '/view/'}` + this.state.value}>
                                    <InputGroup.Button color="blue" onClick={() => this.setState({ isAdvancedModalSearchOpen: true })}>
                                        <i className="fas fa-fw fa-external-link-alt"></i>
                                    </InputGroup.Button>
                                </Link>
                            </>
                        )}
                        {this.props.advanced == true && (
                            <InputGroup.Button color="blue" onClick={() => this.setState({ isAdvancedOpenModal: true })}>
                                <i className="fas fa-fw fa-search"></i>
                            </InputGroup.Button>
                        )}
                        {/* {JSON.stringify(this.props.uses[0])} */}
                        {this.props.advancedInclude == true &&
                            // _.map(this.props.uses, (item: any, index: any) => {

                            //     //console.log("Tste do use ::: ",this.props.uses)
                            //     var target = item;
                            //     var apiValue = this.props.api.url
                            //     var apiValueSplit = apiValue.split("/api/v1/").join("")
                            //     var apiName = apiValueSplit.split("/select").join("")

                            //     if (!item) return null;
                            //     if (item === "true") {
                            //         target = "add";
                            //     }
                            //     return (
                            //         <>
                            //             <Link key={index} target={"_blank"} to={`/dashboard/registrations/${apiName}/add`}>
                            //                 <InputGroup.Button color="green">
                            //                     <i className="fas fa-plus fa-fw"></i>
                            //                     {/* {item} */}
                            //                 </InputGroup.Button>
                            //             </Link>
                            //         </>);

                            // })
                            this.onAdvancedInclude()
                            }

                            { this.eventHandler?.getSuffix ? this.eventHandler?.getSuffix() : null }
                    </InputGroup>
                    {this.props.advanced == true && (

                        <AdvancedSearchModal
                            //colocando o LabelProps para o Advanced entender qual o valor mostrado em tela - Mateus
                            labelProps={(this.props.displayLabel !== undefined) && (this.props.displayLabel !== "") ? this.props.displayLabel : undefined}
                            uses={this.props.uses}
                            closeModal={this.closeModalCancel}
                            onSelect={this.onAdvancedSelect}
                            api={this.props.api.url}
                            onHide={() => this.setState({ isAdvancedOpenModal: false })}
                            show={this.state.isAdvancedOpenModal}
                        />

                    )}
                </>
            );
        }
        return <>{fieldComponent}</>;
    }
}
