import React from "react";
import { Button, Dropdown, Icon, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { ConfirmModal } from "../../../components/confirmModal/ConfirmModal";

interface PrintProjectComponentProps {
    parentProps: any;
    parentState: any;
}


interface PrintProjectComponentState {
    loading: boolean;
    showDialogModal: boolean;
}

class ProjectComponent extends React.Component<PrintProjectComponentProps, PrintProjectComponentState> {
    state: PrintProjectComponentState = {
        loading: false,
        showDialogModal: false
    }

    printProjects = () => {
        const { parentState } = this.props
        if (parentState.checked.length < 1) {
            Notification.info(
                {
                    title: "Aviso",
                    description: "Não é possível imprimir sem nenhum projeto selecionado!"
                }
            )
            return;
        }
        this.setState({ loading: true });
        for (const i in parentState.checked) {
            this.setState({ loading: true });
            axios.get(`/api/v1/projects/reports/print-project/${parentState.checked[i]}`).then((res) => {
                if (res.data && res.data.url) {
                    window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                }
            }).catch((e) => {
                throw ("Erro");
            }).finally(() => {
                this.setState({ loading: false });
            });
        }
    }

    printProjectsDetailed = () => {
        const { parentState } = this.props
        if (parentState.checked.length < 1) {
            Notification.info(
                {
                    title: "Aviso",
                    description: "Não é possível imprimir sem nenhum projeto selecionado!"
                }
            )
            return;
        }
        this.setState({ loading: true });
        for (const i in parentState.checked) {
            this.setState({ loading: true });
            axios.get(`/api/v1/projects/reports/print-project-detailed/${parentState.checked[i]}`).then((res) => {
                if (res.data && res.data.url) {
                    window.open('http://' + window.location.host.split('25565').join('25567') + res.data.url, "_blank");
                }
            }).catch((e) => {
                throw ("Erro");
            }).finally(() => {
                this.setState({ loading: false });
            });
        }
    }

    openCloneModal = () => {
        const { parentState } = this.props;
        if (parentState.checked.length < 1) {
            Notification.info({
                title: "Aviso",
                description: "Não é possível duplicar sem nenhum projeto selecionado!"
            });
            return;
        }
        this.setState({ showDialogModal: true });
    }

    cloneProjects = () => {
        const { parentState } = this.props

        for (const i in parentState.checked) {
            this.setState({ loading: true });
            axios.get(`/api/v1/clone/project/${parentState.checked[i]}`).then((res) => {
                Notification.success(
                    {
                        title: "Sucesso",
                        description: "Projetos duplicados com êxito!"
                    }
                )

                setTimeout(() => {
                    if (res.data.status == 200) {
                        if (parentState.checked.length === 1) {
                            const { project_id } = res.data.result[0];

                            window.open(`/dashboard/project/projects/${project_id}`, "_blank");
                        }
                        window.location.reload();
                    }
                }, 3000)

            }).catch((e) => {
                throw ("Erro");
            }).finally(() => {
                this.setState({ loading: false });
            });
        }

    }

    render(): React.ReactNode {
        return (
            <div className="col-md-12 mt-4 d-flex">
                <Button
                    color="red"
                    onClick={this.printProjects}
                    style={
                        {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5
                        }
                    }
                    loading={this.state.loading}
                >
                    <i className="fas fa-file"></i>
                    Projeto Resumido
                </Button>
                <Button
                    className="ml-2"
                    color="red"
                    onClick={this.printProjectsDetailed}
                    style={
                        {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5
                        }
                    }
                    loading={this.state.loading}
                >
                    <i className="fas fa-file-alt"></i>
                    Projeto Detalhado
                </Button>

                <Button
                    className="ml-2"
                    color="blue"
                    onClick={this.openCloneModal}
                    style={
                        {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5
                        }
                    }
                    loading={this.state.loading}
                >
                    <i className="fas fa-clone"></i>
                    Duplicar Projeto
                </Button>

                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            showDialogModal: false,
                        })
                    }}
                    funcaoclose={() => {
                        this.setState({
                            showDialogModal: false,
                        })
                    }}
                    body="Ao duplicar este registro o mesmo já será gravado. Deseja continuar?"
                    content=""
                    showConfirm={true}
                    confirmColor="green"
                    cancelText={"Cancelar"}
                    onConfirm={() => this.cloneProjects()}
                    onCloseButton={true}
                    title={<>
                        <Icon
                            icon="remind"
                            style={{
                                color: '#ffb300',
                                fontSize: 18,
                                marginTop: 6
                            }}
                        />
                        &nbsp;Atenção!
                    </>}
                    show={this.state.showDialogModal}
                />
            </div>
        )
    }
}

export class ProjectBrowseEvents {
    onRenderFooterBrowserlize = (props: any, state: any) => {
        return <ProjectComponent parentProps={props} parentState={state} />;
    }
}