import _ from "lodash";
import React from "react";
import { CheckTreePicker as RSuiteCheckTreePicker } from "rsuite";
import axios from "../../utilities/axios";
import { singular } from "pluralize";

export class CheckTreePicker extends React.Component<any, {}> {
    public state = {
        preloadedData: [],
        data: this.props.data ?? [],
        inputValue: "",
        loading: true,
        isLoadingClean: false,
        value: this.props.value ?? null,
    };

    componentDidMount() {
        this.handleUpdate();
    }


    componentDidUpdate(prevProps: any) {
        // var fieldValue = this.props.value;
        // fieldValue.map((item:any) => {
        //     console.log({item},this.props.value);
        // })
        //console.log([parseInt(this.props.value)]);

        //  this.setState({value: 18})
        //  console.log(parseInt(this.props.value));
        //   var items = [];
        //   for(var i in this.props.value){
        //       var fieldvalue = this.props.value[i];
        //       //console.log(fieldvalue)
        //       items.push(fieldvalue);
        //   }
        //   this.setState({value: items})

        // //     console.log("log::",this.state.value);
        //     console.log(Object.values(this.props.value))
        // let test2 = [18]
        //this.handleUpdate();
        //if(this.props.value !== props.value) {
        //this.setState({value: props.value})
        //}
        if (this.props.isLoadingClean !== undefined) {
            if (this.state.isLoadingClean !== prevProps.isLoadingClean) {
                this.setState({ value: [], isLoadingClean: prevProps.isLoadingClean });
            }
        }
    }



    find(text: any, array: any): any {
        var result = array.filter((item: any) => {
            if (item['label'] && item['label'].toLocaleLowerCase().includes(text.toLocaleLowerCase())) {
                if (item['children']) {
                    return this.find(text, item['children']).length > 0;
                } else {
                    return true;
                }
            }
            return false;
        })
        // array.some((o: any) => (o.label === text && (result = o)) || (result = this.find(text, o.children || [])));
        return result;
    }

    handleUpdate = (value: any = null) => {
        // alert(value);
        const nameCrud = this.props.api.url.split('/select').shift().split('/v1/').pop();
        let uses = this.props.useActive ? { ...this.props.uses, [`${singular(nameCrud)}_active`]: true } : { ...this.props.uses }

        if (this.props.api) {
            if (nameCrud === "people" && this.props?.values?.project_client_fk_person_id) {
                uses = { ...uses, ignore_person_id: this.props?.values?.project_client_fk_person_id }
            }
            if(nameCrud === 'users' && this.props.name === 'project_users') {
                uses = { ...uses, ignore_user_id: true}
            }

            this.setState({ loading: true });
            axios
                .get(this.props.api.url, { params: { ...uses, search: value, concat: this.props.displayLabel, CheckTreePicker: true } })
                .then((res) => {
                    var arr: any = [...res.data.items];
                    if (this.state.preloadedData) {
                        arr = _.uniqBy([...this.state.preloadedData, ...arr], "value");
                    }
                    if (value) {
                        arr = this.find(value, arr);
                        //(arr);
                    }
                    this.setState({ data: arr, loading: false });
                })
                .catch((e) => {
                    this.setState({ loading: false });
                })
                .finally(() => {
                    // var searchInput = document.getElementsByClassName("rs-picker-search-bar-input")[0] as HTMLInputElement;
                    // searchInput.focus();
                });
        } else {
            setTimeout(() => {
                // var searchInput = document.getElementsByClassName("rs-picker-search-bar-input")[0] as HTMLInputElement;
                // searchInput.focus();
            }, 100);
        }
    };

    onChange = (incomingValue: any) => {
        var value = _.uniq(incomingValue);
        if (this.props.onChange) {
            this.props.onChange(value, this.state.data);
        }
        this.setState({ value });
    };

    onSearch = (value: any) => {
        alert(value)
    }

    public timeout: any = null;
    onChangeInput = (value: string) => {
        //(value);
        // this.setState({ inputValue: value });
        if (this.timeout) {
            clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
            this.handleUpdate(value);
        }, 600);
    };


    shouldComponentUpdate(props: any, state: any) {
        if (this.state.inputValue !== state.inputValue) {
            return false;
        }
        return true;
    }

    render() {
        // if(this.props.inline === false) {
        return <>
            <RSuiteCheckTreePicker
                style={this.props.style}
                placeholder={"Selecione..."}
                preventOverflow
                virtualized
                cascade={true}
                onChange={this.onChange} /*onSearch={this.onChangeInput}*/
                onOpen={() => this.handleUpdate()} value={this.state.value}
                data={this.state.data}
                disabled={this.props.disabled}
                onSearch={(value) => {
                    const trimmedValue = value?.trimStart()?.trimEnd();
                    this.handleUpdate(trimmedValue);
                }}
                searchBy={(value, item: any) => {
                    const trimmedValue = value?.trimStart()?.trimEnd();
                    return item?.toLowerCase()?.includes(trimmedValue?.toLowerCase());
                }}
                onClean={this.props.onClean}
            />
            {/* {JSON.stringify(this.state.value)} */}
        </>;
        // }
        // return (
        //     <>
        //         <InputGroup style={{ width: "100%" }}>
        //             <Input onChange={this.onChangeInput} />
        //             <InputGroup.Button>
        //                 {this.state.loading && <Loader></Loader>}
        //                 {!this.state.loading && <i className="fas fa-search"></i>}
        //             </InputGroup.Button>
        //         </InputGroup>

        //         <CheckTree virtualized cascade={true} onChange={this.onChange} onOpen={this.handleUpdate} value={this.state.value} data={this.state.data} />


        //     </>
        // );
    }
}
