import axios from "../../utilities/axios";
import { Event } from "../Event";

export class DefinitionEvents extends Event {
    public onLoad = () => {
        this.setState('loadings', ['definition_week_days'])

        const weekDays = this.globalProps.values.definition_week_days
        const definitionId = this.globalProps.values.definition_id

        if ((!Array.isArray(weekDays) || weekDays.length == 0) && typeof definitionId === 'number') {
            axios.get(`/api/v1/definition_week_days/list?definition_id=${definitionId}`).then((response) => {
                this.setFieldValue('definition_week_days', response.data.items)
                this.setState('loadings', [])
            })
        } else {
            this.setState('loadings', [])
        }
    }
}

