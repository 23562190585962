import dayjs from "dayjs";
import React from "react";
import { Loader } from "rsuite";

interface NotificationItemProps {
    id: string | number;
    type: string;
    date: string; // ou o tipo correto para datas se for diferente de string
    message: string;
    onClick: (id: string | number) => void; // Corrigido para receber 'key' como segundo parâmetro
}


class NotificationItem extends React.Component<NotificationItemProps, {}> {
    renderTypeIcon() {
        switch (this.props.type) {
            default:
                return <i className="fas fa-info-circle text-primary"></i>;
            case "danger":
                return <i className="fas fa-times-octagon text-danger"></i>;
            case "warning":
                return <i className="fas fa-exclamation-triangle text-warning"></i>;
            case "success":
                return <i className="fas fa-check text-success"></i>;
        }
    }

    render() {
        return (
            <div className="notification" onClick={() => this.props.onClick(this.props.id)}>
                <div className="notification-icon">{this.renderTypeIcon()}</div>
                <div className="notification-text">{this.props.message}</div>
                <div className="notification-meta">{dayjs(this.props.date).format("DD/MM/YYYY HH:mm:ss")}</div>
            </div>
        );
    }
}

interface NotificationProps {
    messages: Record<string, any>;
    onClick: (id: string | number) => void; 
    loading: boolean;
}

class NotificationsComponent extends React.Component<NotificationProps, {}> {
    renderItems() {
        var output = [];

        for (var i in this.props.messages) {
            var data = this.props.messages[i];
            output.push(<NotificationItem onClick={this.props.onClick} key={i} id={data.id} type={data.type ?? "primary"} date={data.date} message={data.message} />);
        }
        return output;
    }

    render() {
        if (this.props.loading) {
            return <Loader speed={"slow"} size="md" />;
        }
        if (this.props.messages.length <= 0) {
            return <div style={{ opacity: 0.6 }}>Nenhuma mensagem encontrada.</div>;
        }
        return this.renderItems();
    }
}


export { NotificationsComponent };