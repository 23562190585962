import _ from "lodash";
import React from "react";
import { InputGroup, Input, Dropdown } from "rsuite";

export class FilterPopover extends React.Component<any, {}> {
    public state = {
        value: typeof this.props.value === "string" ? this.props.value : this.props.field.type === "checkbox" ? "Todos" : "",
        advanced: this.props.advanced,
        operator: this.props.operator,
    };

    onChange = (value: string | boolean) => {
        this.setState({ value });

        if (this.props.onChange) {
            this.props.onChange(value, this.state.advanced === true ? this.state.operator : "like");
        }
    };

    onExit = () => {
        if (this.props.onExit) {
            this.props.onExit();
        }
    };

    onClick = () => {
        this.setState({ value: "" });
        if (this.props.onChange) {
            this.props.onChange("");
        }
    };

    onSelect = (value: string | boolean) => {
        this.onChange(value);
        // this.setState({})
    };

    onChangeOperator = (operator: string) => {
        this.setState({ operator });
        if (this.props.onChange) {
            this.props.onChange(this.state.value, this.state.advanced === true ? operator : "like");
        }
    };

    public types: any = {
        like: "Contem",
        notlike: "Não contem",
        equal: "Igual",
        eq: "Mesmo que",
        gt: "Maior que",
        lt: "Menor que",
        gte: "Maior ou igual",
        lte: "Menor ou igual",
        startswith: "Começa com",
        endswith: "Termina com",
        gtstrlen: "Comprimento maior que",
        ltstrlen: "Comprimento menor que",
        eqstrlen: "Comprimento igual a",
    };

    getValuesFromAllowedTypes(types: string[]) {
        var output: any = [];
        var inverted = _.invert(this.types);

        for (var i in types) {
            if (inverted[types[i]]) {
                output.push({ value: inverted[types[i]], name: types[i] });
            }
        }
        //(types,inverted,output);
        return output;
    }

    allowedTypes() {
        // if
        
        var type = this.props.field?.type ?? "text";
        if(this.props.isIndex) {
            type = 'number';
        }
        // //(this.props.field.type);
        switch (type) {
            case "numeric":
                return ["Mesmo que", "Maior que", "Menor que", "Maior ou igual", "Menor ou igual"];
            case "number":
                return ["Mesmo que", "Maior que", "Menor que", "Maior ou igual", "Menor ou igual"];
            case "select":
                return ["Contem", "Não contem", "Igual", "Começa com", "Termina com","Comprimento menor que", "Comprimento maior que", "Comprimento igual a"];
            case "checkbox":
                return ["Contem", "Não contem", "Igual", "Começa com", "Termina com"];
            case "text":
                return ["Contem", "Não contem", "Igual", "Começa com", "Termina com", "Comprimento menor que", "Comprimento maior que", "Comprimento igual a"];
            default:
                return ["Contem", "Não contem", "Igual", "Maior que", "Menor que", "Maior ou igual", "Menor ou igual", "Começa com", "Termina com"];
        }
    }

    render() {
        if (this.state.advanced === true && this.props.hasAdvanced !== false) {
            // alert(this.props.value);
            const operators = this.getValuesFromAllowedTypes(this.allowedTypes());
            const typeByOperator: any = {
                gt: "number",
                lt: "number",
                gte: "number",
                lte: "number",
                gtstrlen: "number",
                ltstrlen: "number",
                eqstrlen: "number",
            };

            const selectedOperatorType: any = typeByOperator[this.state.operator];

            return (
                <>
                    {(this.props.field === undefined || (this.props.field !== undefined && this.props.field.type !== "checkbox")) && (
                        <InputGroup>
                            <div className="rs-input-suffix">
                                <Dropdown title={this.types[this.state.operator] ?? "Contem"}>
                                    {operators.map((item: any) => {
                                        return (
                                            <Dropdown.Item active={this.state.operator === item.value} onSelect={() => this.onChangeOperator(item.value)}>
                                                {item.name}
                                            </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown>
                                {/* {this.state.operator} */}
                            </div>
                            <Input type={selectedOperatorType} value={this.state.value} onPressEnter={this.onExit} onChange={this.onChange} />
                            {this.state.value.length > 0 && (
                                <InputGroup.Button onClick={this.onClick}>
                                    <i className="fas fa-times"></i>
                                </InputGroup.Button>
                            )}
                        </InputGroup>
                    )}
                    {this.props.field !== undefined && this.props.field.type === "checkbox" && (
                        <InputGroup>
                            <Dropdown title={this.state.value === "" ? "Todos" : this.state.value}>
                                <Dropdown.Item active={this.state.value === "Todos" || this.state.value === ""} onSelect={() => this.onSelect("Todos")}>
                                    {"Todos"}
                                </Dropdown.Item>
                                <Dropdown.Item active={this.state.value === "Não"} onSelect={() => this.onSelect("Não")}>
                                    {"Não"}
                                </Dropdown.Item>
                                <Dropdown.Item active={this.state.value === "Sim"} onSelect={() => this.onSelect("Sim")}>
                                    {"Sim"}
                                </Dropdown.Item>
                            </Dropdown>
                            {this.state.value.length > 0 && (
                                <InputGroup.Button onClick={this.onClick}>
                                    <i className="fas fa-times"></i>
                                </InputGroup.Button>
                            )}
                        </InputGroup>
                    )}
                    {/* {this.props.field.type} - {this.props.operator} */}
                    {/* {this.state.advanced ? 'S':'N'} */}
                    {this.props.hasAdvanced !== false && (
                        <div className="rs-meta" onClick={this.props.onToggleMode}>
                            Basico...
                        </div>
                    )}
                </>
            );
        }
        return (
            <>
                <InputGroup>
                    <Input value={this.state.value?.toLocaleUpperCase()} onPressEnter={this.onExit} onChange={this.onChange} />
                    {this.state.value.length > 0 && (
                        <InputGroup.Button onClick={this.onClick}>
                            <i className="fas fa-times"></i>
                        </InputGroup.Button>
                    )}
                </InputGroup>
                {/* {this.state.advanced ? 'S':'N'} */}
                {this.props.hasAdvanced !== false && (
                    <div className="rs-meta" onClick={this.props.onToggleMode}>
                        Avançado...
                    </div>
                )}
            </>
        );
    }
}
