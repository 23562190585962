import React from "react";
import axios from "../../../../../utilities/axios";
import { io } from "../../../../../utilities/io";
import { Avatar, Button, Checkbox, Dropdown, Modal, Notification } from "rsuite";
import { ExpandComponent } from "./forward/ExpandComponent";


interface ChatForwardProps {
    nonForward?: boolean;
    params: Record<string, any>;
    messageForward: Record<string, any>;
    chatsSteps: any[]
    closeModal: () => void
    opened: boolean
}

type ChatForwardState = {
    forwardMessage: any[];
    opened: boolean;
}


export class ChatForward extends React.Component<ChatForwardProps, ChatForwardState> {

    constructor(props: ChatForwardProps) {
        super(props);
        this.state = {
            forwardMessage: [],
            opened: this.props.opened
        }
        this.handleSetForwardMessage = this.handleSetForwardMessage.bind(this);
    }


    handleSetForwardMessage({ stepItemProjectProduct, indiceName }: { stepItemProjectProduct: Record<string, any>, indiceName: string }) {
        this.setState(
            {
                forwardMessage: { ...this.state.forwardMessage, [`${indiceName}`]: stepItemProjectProduct }
            }
        )
    }


    onHandleSendForward() {
        const params = {
            chatsForward: this.state.forwardMessage,
            messageForward: this.props.messageForward,
            params: this.props.params
        }
        io.emit('forwardMessageChat',
            {
                ...params
            },
            (response: {message: string}) => {
                Notification.success(
                    {
                        title: "Encaminhada",
                        description: response.message
                    }
                )
            }
        )
        this.props.closeModal()
        this.setState(
            {
                opened: false,
                forwardMessage: [{}]
            }
        )
    }



    renderChats() {
        const output: any = [];
        const { chatsSteps } = this.props;
        for (const item of chatsSteps) {
            if (item.chats) {
                output.push(
                    // <>
                    //     <div
                    //         style={
                    //             {
                    //                 display: 'flex',
                    //                 flexDirection: 'column',
                    //                 background: '#044e45',
                    //                 padding: 16,
                    //                 borderRadius: '6px 6px 0 0',
                    //                 color: '#fff'
                    //             }
                    //         }
                    //     >
                    //         <div
                    //             style={
                    //                 {
                    //                     display: 'flex',
                    //                     flexDirection: 'row',
                    //                     justifyContent: 'space-between'
                    //                 }
                    //             }
                    //         >
                    //             <div

                    //             >
                    //                 {item.product_name}
                    //             </div>
                    //             <i className="fas fa-chevron-circle-down fa-2x"
                    //                 style={
                    //                     {
                    //                         cursor: 'pointer'
                    //                     }
                    //                 }
                    //             ></i>
                    //         </div>
                    //     </div>
                    //     {item.chats.map((item: Record<string, any>, index: number) => {
                    //         return (
                    //             <div
                    //                 style={
                    //                     {
                    //                         display: 'flex',
                    //                         flexDirection: 'row',
                    //                     }
                    //                 }
                    //             >
                    //                 <Checkbox
                    //                     // checked={() => this.handleSetForwardMessage()}
                    //                     // checkable={true}
                    //                     onChange={(value: any, event) => this.handleSetForwardMessage({ stepItemProjectProduct: item, indiceName: item.step_name })}
                    //                 />
                    //                 <Avatar
                    //                     circle
                    //                 >
                    //                     I
                    //                 </Avatar>
                    //                 <div>
                    //                     {item ? item?.step_name : ''}
                    //                 </div>
                    //             </div>

                    //         )
                    //     })}
                    // </>
                    <ExpandComponent 
                        item={item}
                        handleSetForwardMessage={this.handleSetForwardMessage}
                    />
                )
            } else {
                output.push(
                    <div
                        style={
                            {
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                minHeight: 45,
                                backgroundColor: '#dcf8c6',
                                borderRadius: 6,
                                borderWidth: 1,
                                borderColor: '#044e45',
                                alignItems: 'center',
                                gap: 10,
                                padding: 10
                            }
                        }

                    >
                        <div>
                            <Checkbox
                                // checked={() => this.handleSetForwardMessage()}
                                // checkable={true}
                                onChange={(value: any, event) => this.handleSetForwardMessage({ stepItemProjectProduct: item, indiceName: item.steps[0].step_name })}
                            />
                        </div>
                        <Avatar
                            circle
                        >
                            I
                        </Avatar>
                        <div>
                            {item.steps ? item?.steps[0]?.step_name : ''}
                        </div>
                    </div>
                )
            }
        }
        return output
    }


    render(): React.ReactNode {
        return (
            <>
                {/* {this.renderChats()} */}
                <Modal
                    show={this.state.opened}
                    onHide={() => {
                        this.props.closeModal()
                        this.setState(
                            {
                                opened: false,
                                forwardMessage: [{}]
                            }
                        )
                    }}
                    size="xs"
                >
                    <Modal.Header>
                        Encaminhamento de mensagens....
                    </Modal.Header>
                    <Modal.Body
                        style={
                            {
                                display: 'flex',
                                flexDirection: "column",
                                gap: 6,
                                height: '50vh',
                                maxHeight: '50vh',
                                overflowY: 'hidden'
                            }
                        }
                    >
                        {this.props.chatsSteps.length > 0 ? (
                            <>
                                {this.renderChats()}
                            </>
                        ) : (
                            <span>Não a chats para encaminhar</span>
                        )}
                    </Modal.Body>
                    <Modal.Footer
                        style={
                            {
                                display: 'flex',
                                width: '100%'
                            }
                        }
                    >
                        <Button
                            style={
                                {
                                    width: '100%'
                                }
                            }
                            disabled={this.state.forwardMessage?.length <= 0}
                            color="green"
                            onClick={() => this.onHandleSendForward()}
                        >
                            Encaminhar Mensagem
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}