import React from "react";
import { withRouter } from "react-router";
import { ModuleContext } from "../components/authProvider/main";
import { LoginAnimationSvg } from "../svg/LoginAnimationSvg";
import LogoPng from "../assets/mind.png";
import { ModuleBlock } from "./modules/ModuleBlock";
import { CSSTransition } from "react-transition-group";
import config from "../globals";
import { ModuleContainer } from "./modules/ModuleContainer";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import { container, item, visible, zoomIn } from "../components/motion/motionSettings";

class ModulesPage extends React.Component<any, {}> {
    static contextType = ModuleContext;

    public constructed = false;

    public state = {
        isMounted: false,
        logo: localStorage.getItem('url_logo_company')
    };

    public nodeRef: any = null;

    constructor(props: any) {
        super(props);
        this.nodeRef = React.createRef();
    }

    componentDidUpdate() {
    }

    // componentWillUnmount = () => {
    //     this.setState({isMounted:false})
    // }

    componentDidMount = () => {
        this.setState({ isMounted: true });
        // if (this.props.match.path === "/dashboard" && (this.context.name !== null || this.context.name !== undefined)) {
        //     // this.context.setModule(null,null);
        // }
    };

    goTo(link: string) {
        // this.context.setModule(link.split('/').pop());
        this.props.history.push(link);
    }

    renderModules = (variableName = "modules") => {
        var output = [];
        let index = 0;
        for (var i in config[variableName]) {
            let moduleData = config[variableName][i];
            index += 1.5;
            if(moduleData.label?.includes("Home")){
                continue;
            }
            output.push(
                <motion.div initial="hidden" animate="visible" variants={visible}>
                    <ModuleBlock
                        key={i}
                        visible={moduleData.visible}
                        color={moduleData.color}
                        delay={index}
                        onClick={() => this.goTo("/dashboard/" + (moduleData.url === "project" ? "project/kanban" : moduleData.url) )}
                        icon={moduleData.icon}
                        name={moduleData.label}
                    />
                </motion.div>
            );
        }
        return output;
    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>FastFlow - Home</title>
                </Helmet>
                <div style={{
                    position: "absolute",
                    width: "100%",
                    height: "100vh",
                    overflow: "hidden",
                    zIndex: -1,
                    objectFit: "cover",
                    objectPosition: "center",
                    backgroundColor: "black"  // Fundo preto
                }}>
                    <video
                        autoPlay
                        loop
                        muted
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                            opacity: 0.5  // Opacidade do vídeo
                        }}
                    >
                        <source
                            src="https://fastflow.com.br/assets/img/resized_small.mp4"
                            type="video/mp4"
                        />
                    </video>
                </div>
                <CSSTransition nodeRef={this.nodeRef} in={this.state.isMounted} timeout={200} unmountOnExit classNames="fade">
                    <div className="modules-page">
                        {/* <LoginAnimationSvg /> */}
                        <div className="side-logo">
                            {this.state.logo && (
                                <img src={this.state.logo} alt="" />
                            )}
                        </div>
                        <ModuleContainer size={2} title="">
                            {this.renderModules()}
                        </ModuleContainer>
                        {/* <ModuleContainer size={2} title="NOVO">
                            {this.renderModules("main")}
                        </ModuleContainer> */}
                    </div>
                </CSSTransition>
            </div>
        );
    }
}

export default withRouter(ModulesPage);
