import { options } from "less";
import axios from "../../../utilities/axios";
import { Event } from "../../Event";

export class ProjectProductAppointmentFkProjectProductIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'project_product_appointment_fk_step_id',
        ]);

        if (value) {
            const values = this.globalProps.values;
            const userAppointment = values.project_product_appointment_fk_user_id
            const projectProductAppointment = values.project_product_appointment_fk_project_product_id
            this.setFieldValue('project_product_appointment_fk_step_id', )
            this.setField('project_product_appointment_fk_step_id', {
                api: {
                    method: "GET",
                    url: `/api/v1/steps/select?${projectProductAppointment ? `project_product_id=[${projectProductAppointment}]` : ''}${userAppointment ? `&user_id=${userAppointment}` : ''}`,
                    uses: [
                        {
                            type: "prop",
                        }
                    ],
                }
            });
        } else {
            this.setField('project_product_appointment_fk_step_id', {
                api: {
                    method: "GET",
                    url: `/api/v1/steps/select`,
                    uses: [
                        {
                            type: "prop",
                        }
                    ],
                }
            });
        }

        setTimeout(() => {
            this.setState('loadings', []);
        }, 1000);
    }
}