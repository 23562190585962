import dayjs from "dayjs";
import React from "react";
import { Button, Modal, Placeholder, Steps, Tooltip, Whisper } from "rsuite";
import axios from "../../utilities/axios";
import { getSituationChatIcon } from "../../utilities/situations/situationsFunctions";

interface IProjectProductStatusModalProps {
    show: boolean;
    onClose: any;
    project_product_id: number | null;
    project_product_fk_product_id: number | null;
}

interface IProjectProductStatusModalState {
    show: boolean;
    loading: boolean;
    project_product_group_step_table_flow: any;
    project_product_id: number | null;
    project_product_fk_product_id: number | null;
    modalKey: number;
}

export class ProjectProductStatusModal extends React.Component<IProjectProductStatusModalProps, IProjectProductStatusModalState> {
    state = {
        show: this.props.show,
        loading: true,
        project_product_group_step_table_flow: [],
        project_product_id: this.props.project_product_id,
        project_product_fk_product_id: this.props.project_product_fk_product_id,
        modalKey: 0
    }

    componentDidMount(): void {
        this.fetchData();
    }

    componentDidUpdate(prevProps: IProjectProductStatusModalProps) {
        if (this.props.show && !prevProps.show) {
            this.fetchData();
        }
    }

    fetchData = () => {
        const { project_product_id, project_product_fk_product_id } = this.state;
        this.setState({ loading: true });
        axios
            .get(`/api/mobile/steps/${project_product_id}/${project_product_fk_product_id}/get`)
            .then((res: any) => {
                this.setState({ project_product_group_step_table_flow: res.data.item });
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

    getStepStatus(status: string) {
        switch (status) {
            case "NÃO INICIADO":
                return "wait";
            case "INICIADO":
                return "process";
            case "URGENTE":
                return "error";
            case "FINALIZADO":
                return "finish";
            case null:
                return "wait";
            default:
                return "wait";
        }
    };

    render(): React.ReactNode {
        const { loading, project_product_group_step_table_flow } = this.state;
        return (
            <Modal
                key={this.state.modalKey}
                show={this.props.show}
                onHide={this.props.onClose}
            >
                <Modal.Header>
                    <h4>Detalhes do Item</h4>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            gap: 15,
                            padding: 2
                        }}
                    >
                        {loading ? (
                            <>
                                <Placeholder.Paragraph active graph="circle" />
                                <Placeholder.Paragraph active graph="circle" />
                                <Placeholder.Paragraph active graph="circle" />
                                <Placeholder.Paragraph active graph="circle" />
                                <Placeholder.Paragraph active graph="circle" />
                            </>
                        ) : (
                            <Steps current={1} vertical>
                                {project_product_group_step_table_flow.map((flow: any, index: number) => (
                                    <Steps.Item
                                        key={this.getStepStatus(flow.steps[0].situation_chat_name)}
                                        title={flow.steps[0].step_name}
                                        status={this.getStepStatus(flow.steps[0].situation_chat_name)}
                                        icon={
                                            <Whisper trigger="hover" placement="left" speaker={<Tooltip placement="top">{flow.steps[0].situation_chat_name ? flow.steps[0].situation_chat_name : "NÃO INICIADO"}</Tooltip>}>
                                                <i className={getSituationChatIcon(flow?.steps[0].situation_chat_name ? flow?.steps[0].situation_chat_name : '#0d5452')} style={{ cursor: "help" }}></i>
                                            </Whisper>
                                        }
                                        description={
                                            <>
                                                <p>
                                                    SITUAÇÃO: <strong style={{ color: flow?.steps[0].situation_chat_color ? flow?.steps[0].situation_chat_color : "#0d5452" }}>
                                                        {flow?.steps[0].situation_chat_name ? flow?.steps[0].situation_chat_name : "NÃO INICIADO"}
                                                    </strong>
                                                </p>
                                                {!["NÃO INICIADO", null].includes(flow?.steps[0].situation_chat_name) && (
                                                    <>
                                                        <p>
                                                            {flow.project_product_group_step_table_flow_initiated_at && `Iniciado em: ${dayjs(flow.project_product_group_step_table_flow_initiated_at).format("DD/MM/YYYY HH:mm")}`}
                                                        </p>
                                                        <p>
                                                            {flow.project_product_group_step_table_flow_finalized_at && `Finalizado em: ${dayjs(flow.project_product_group_step_table_flow_finalized_at).format("DD/MM/YYYY HH:mm")}`}
                                                        </p>
                                                    </>
                                                )}
                                            </>
                                        }
                                    />
                                ))}
                            </Steps>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="mt-1">
                    <div className="col-md-12 mt-2 d-flex" style={{ marginBottom: 10, float: "right" }}>
                        <Button appearance="subtle" onClick={this.props.onClose}>
                            <i className="fas fa-times fa-fw mr-2"></i>
                            Fechar
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }
}
