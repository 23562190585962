import React from "react";
import { Panel } from "rsuite";
import { AppointmentReport } from "./reportPages/AppointmentReport";
import { Helmet } from "react-helmet";

export class ReportPage extends React.Component<any, {}> {
    header = () => {
        return (
            <div className="d-flex justify-contents-between align-items-center">
                <div></div>
                <div>
                    <h5 className="d-flex align-items-center">
                        <i className="fas fa-fw mr-4 fa fa-file"></i>
                        Relatórios
                    </h5>
                </div>
                <div></div>
            </div>
        );
    };

    render(): React.ReactNode {
        return (
            <div className="container" style={{ marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }}>
                <Helmet>
                    <title>FastFlow - Relatórios</title>
                </Helmet>
                <Panel bordered className="rs-no-body mb-4">
                    {this.header()}
                </Panel>

                <Panel header={<b>Apontamentos de Projeto</b>} collapsible defaultExpanded bordered style={{ background: "white", width: "100%" }}>
                    <AppointmentReport />
                </Panel>
            </div>
        )
    }
}
