import '@asseinfo/react-kanban/dist/styles.css';
import dayjs from "dayjs";
import { Button, ControlLabel, DateRangePicker, Icon, Loader, Message, Notification, Panel, Placeholder, SelectPicker, Tag, Toggle, Tooltip, Whisper } from "rsuite";
import { io } from '../../../utilities/io';
//@ts-ignore
import Board, { moveCard } from "@asseinfo/react-kanban";
import React, { SyntheticEvent } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { CheckTreePicker } from '../../../components/formfields/CheckTreePicker';
import axios from '../../../utilities/axios';
import { KanbanCard } from './KanbanCard';
import { UserContext } from '../../../components/authProvider/main';
import _ from 'lodash';
import { locale } from '../../../utilities/locale/DateRangePicker';
import { Permission } from '../../../components/Permission';
import { ProjectProductStatusModal } from '../ProjectProductStatusModal';

export class KanbanPage extends React.Component<any, {}> {
    static contextType = UserContext;

    public state: any = {
        data: { kanbans: {} },
        groups: [],
        steps: [],
        projects: [],
        filter: [],
        isLoading: true,
        isLoadingClean: false,
        isLoadingKanban: false,
        isDrawerOpen: false,
        isProjectProductStatusModalOpen: false,
        viewCard: null,
        receiver: false
    };

    scrollContainer: any;

    constructor(props: any) {
        super(props);
        this.scrollContainer = React.createRef();
    }

    componentDidMount(): void {
        this.getData();
        this.getGroupsData();
        this.getProjectsData();

        io.on("kanban:receiver", async (data: any) => {
            if (data.company_id === this.props.companyId) {
                this.setState({ receiver: dayjs().unix() });
                this.getData();
            }
        });

        io.on(`kanban:error`, (error) => {
            Notification.warning({ title: "Aviso!", description: error.message, duration: 5000 });
            this.getData();
        });
    }

    handleDrag = (event: any) => {
        const threshold = 10;
        const scrollSpeed = 20;

        const { clientX } = event;

        if (this.scrollContainer) {
            const { left, right } = this.scrollContainer.getBoundingClientRect();

            if (clientX - left < threshold) {
                const firstChild = this.scrollContainer.firstChild;
                firstChild.scrollIntoView({ behavior: 'smooth', inline: 'start' });
            }

            if (right - clientX < threshold) {
                const lastChild = this.scrollContainer.lastChild;
                lastChild.scrollIntoView({ behavior: 'smooth', inline: 'end' });
                console.log("entrou handleDrag");
            }
        }
    };

    componentDidUpdate(): void {
        //     if (this.state.data?.kanbans?.length <= 0) {
        //         io.on("kanban:receiver", async (data: any) => {
        //             if (data.company_id === this.props.companyId) {
        //                 this.setState({ receiver: dayjs().unix() });
        //                 this.getData();
        //                 this.getGroupsData();
        //             }
        //         });
        //     }
        this.scrollContainer = document.getElementById('kanban-scroll-container');
        // console.log("scrollContainer", document.getElementById('kanban-scroll-container'));
    }

    getData = () => {
        // if (this.state.groups?.length > 0) {
        this.setState({ isLoadingKanban: true });
        axios.post(`/api/v1/kanban-get-map`, {
            params: {
                ...this.state.filter
            }
        }).then((res: any) => {
            this.setState({ data: res?.data });
        }).finally(() => {
            this.setState({ isLoading: false, isLoadingKanban: false });
        });
        // }
    }

    getGroupsData = () => {
        axios.get(`/api/v1/groups/select${this.state.filter.projects?.length > 0 ? `?project_id=[${this.state.filter.projects}]` : ''}${this.state.filter.products?.length > 0 ? `&product_id=[${this.state.filter.products}]` : ''}`).then((res: any) => {
            const items = res.data.items;
            this.setState({ groups: items });
        }).finally(() => {

        });
    }

    getProjectsData = () => {
        axios.get(`/api/v1/projects/select?sortBy=project_id&sortType=desc&filtered_by_envolved${this.state.filter.show_exclude ? '&show_exclude' : ''}`).then((res: any) => {
            const items = res.data.items;
            this.setState({ projects: items });
        }).finally(() => {

        });
    }

    selectFilter = (field: any, value: any) => {
        this.setState({ filter: { ...this.state.filter, [field]: value } }, this.getData);
    }

    cleanAllFilters = () => {
        this.setState({ isLoadingClean: !this.state.isLoadingClean });
    }

    renderKanbanCard = () => {
        return (
            <KanbanCard
                item={this.state.viewCard}
                open={this.state.isDrawerOpen}
                onClose={() => this.setState({ isDrawerOpen: false })}
            />
        )
    }

    renderProjectProductStatusModal = () => {
        if (this.state.viewCard) {
            return (
                <ProjectProductStatusModal
                    show={this.state.isProjectProductStatusModalOpen}
                    onClose={() => this.setState({ isProjectProductStatusModalOpen: false, viewCard: null })}
                    project_product_id={this.state.viewCard.project_product_id}
                    project_product_fk_product_id={this.state.viewCard.project_product_fk_product_id}
                />
            )
        }
    }

    moveCardFn = async (currentCard: any, from: any, to: any, groupId: any) => {
        if (from?.fromColumnId === to?.toColumnId) {
            return
        }
        // console.log({ currentCard, from, to, groupId })
        // if (!currentCard.envolved_users?.includes(this.context?.data?.user_id) && ![groupId + '-auto-0', groupId + '-auto-99999999999'].includes(from.fromColumnId)) {
        //     return Notification.warning({ title: "Aviso", description: "seu usuário não tem permissão para executar essa ação!" });
        // }

        if ((from.fromColumnId === 4 && to.toColumnId !== 1) || (from.fromColumnId === 1 && to.toColumnId === 4)) {
            return Notification.warning({ title: "Aviso", description: "Não é possivel mover!" });
        }
        this.setState({ isLoadingKanban: true })
        io.emit("kanban:emitmap", { currentCard, from, to, groupId, user_id: this.context?.data?.user_id });
        const newData = moveCard(this.state.data.kanbans[groupId], from, to);
        this.setState({ data: { kanbans: { ...this.state.kanbans, [groupId]: newData } } });
    }

    render(): React.ReactNode {
        const { data, filter, isLoading, isLoadingKanban, isLoadingClean } = this.state;
        // const kanbanData = { ...data };

        return (
            <>
                <div style={{ marginBottom: 15 }}>
                    <div style={{ display: "flex", justifyContent: "left", width: "100%" }}>
                        <div style={{ width: "40%" }}>
                            <ControlLabel>Projetos</ControlLabel>
                            <CheckTreePicker
                                name="projects"
                                api={{
                                    method: "GET",
                                    url: `/api/v1/projects/select?sortBy=project_id&sortType=desc${filter.products?.length > 0 ? `&product_id=[${filter.products}]` : ''}${filter.groups?.length > 0 ? `&group_id=[${filter.groups}]` : ''}&show_exclude=${filter.show_exclude ?? false}&filtered_by_envolved`,
                                }}
                                displayLabel="projects.project_code + ' - ' + projects.project_description"
                                value={filter.projects || []}
                                isLoadingClean={isLoadingClean}
                                onChange={(value: any) => this.selectFilter("projects", value)}
                                onClean={() => this.selectFilter("projects", null)}
                                style={{ width: "100%" }}
                            />
                        </div>
                        {/* <div style={{ width: "40%", marginLeft: 6 }}>
                            <ControlLabel>Itens</ControlLabel>
                            <CheckTreePicker
                                name="products"
                                api={{
                                    method: "GET",
                                    url: `/api/v1/products/select${filter.projects?.length > 0 ? `?project_id=[${filter.projects}]` : ''}${filter.groups?.length > 0 ? `${filter.projects?.length > 0 ? "&" : "?"}group_id=[${filter.groups}]` : ''}`,
                                }}
                                displayLabel="products.product_name"
                                value={filter.products || []}
                                isLoadingClean={isLoadingClean}
                                onChange={(value: any) => this.selectFilter("products", value)}
                                onClean={() => this.cleanAllFilters()}
                                style={{ width: "100%" }}
                            />
                        </div> */}
                        <div style={{ width: "40%", marginLeft: 6 }}>
                            <ControlLabel>Grupo de Fases</ControlLabel>
                            {/* <SelectPicker
                                placeholder="Selecione..."
                                style={{ width: "100%" }}
                                value={filter.group_id}
                                // defaultValue={groups?.[0]?.value}
                                onChange={(value: any) => this.selectFilter("group_id", value)}
                                onOpen={() => this.getGroupsData()}
                                data={this.state.groups ?? []}
                                onClean={() => this.cleanAllFilters()}
                            /> */}

                            <CheckTreePicker
                                name="groups"
                                api={{
                                    method: "GET",
                                    url: `/api/v1/groups/select${filter.projects?.length > 0 ? `?project_id=[${filter.projects}]` : ''}${filter.products?.length > 0 ? `&product_id=[${filter.products}]` : ''}`
                                }}
                                displayLabel="groups.group_name"
                                value={filter.groups || []}
                                isLoadingClean={isLoadingClean}
                                onChange={(value: any) => this.selectFilter("groups", value)}
                                onClean={() => this.selectFilter("groups", null)}
                                style={{ width: "100%" }}
                            />
                        </div>
                        <div style={{ width: "20%", marginLeft: 6 }}>
                            <ControlLabel>Exibir Arquivados?</ControlLabel>
                            <Toggle
                                defaultChecked={filter.show_exclude ?? false}
                                checked={filter.show_exclude ?? false}
                                onChange={(value: boolean) => this.selectFilter("show_exclude", value)}
                                style={{ width: "100%" }}
                            ></Toggle>
                        </div>
                        <div style={{ width: "20%", marginLeft: 6 }}>
                            <ControlLabel>Data Inicial/Final da Criação Projeto</ControlLabel>
                            <DateRangePicker
                                preventOverflow
                                locale={locale}
                                onClean={() => this.selectFilter("date", null)}
                                placement="bottomEnd"
                                format="DD/MM/YYYY"
                                onChange={(value: any) => this.selectFilter("date", value)}
                            />
                        </div>
                    </div>

                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: 10 }}>
                        <Permission name="projects-add">
                            <Button
                                style={{ width: 200 }}
                                size="md"
                                color="green"
                                onClick={() => this.props.history.push(`/dashboard/project/projects/add`)}
                            >
                                <Icon icon="plus" /> &nbsp; Adicionar Projeto
                            </Button>
                        </Permission>

                        <Button
                            style={{ width: 125 }}
                            size="md"
                            color="blue"
                            onClick={() => this.getData()}
                        >
                            <Icon icon="refresh" /> &nbsp; Atualizar
                        </Button>
                    </div>
                </div>

                {
                    isLoading && (
                        <div style={{ width: "100%", marginBottom: 15 }}>
                            <Placeholder.Paragraph rows={12} />
                        </div>
                    )
                }

                {
                    !isLoading && data && (
                        <>
                            {_.isEmpty(data?.kanbans) && (
                                <Message type="info" showIcon description={data.message ? data.message : "Não existem projetos com o filtro aplicado"} />
                            )}
                            {!_.isEmpty(data?.kanbans) && (
                                <Panel bordered bodyFill>
                                    {isLoadingKanban && (
                                        <Loader backdrop content="Carregando..." size="lg" />
                                    )}
                                    {Object.keys(data?.kanbans)?.map((groupId, groupIndex: number) => {
                                        console.log("debugs", data?.kanbans?.[groupId.toString()])
                                        return (
                                            <div key={groupId} id="kanban-scroll-container" style={{ overflowX: "auto" }} ref={(ref) => (this.scrollContainer = ref)}>
                                                <h4 style={{ paddingLeft: 10, marginTop: groupIndex === 0 ? 8 : 0 }}>
                                                    {/* <Link
                                                        key={groupIndex}
                                                        target={"_blank"}
                                                        to={`/dashboard/project/chats/${groupId?.split("-")[1]}`}
                                                    > */}
                                                        {_.find(this.state.groups, { value: Number(groupId?.split("-")[0]) })?.label}{groupId?.split("-")[2] ? ` - ${_.find(this.state.projects, { value: Number(groupId?.split("-")[2]) })?.label}` : ""}
                                                    {/* </Link> */}
                                                </h4>
                                                {data?.kanbans?.[groupId.toString()] && (
                                                    <Board
                                                        key={groupId}
                                                        style={{ width: "100%", marginBottom: 30 }}
                                                        disableColumnDrag
                                                        // disableCardDrag
                                                        onCardDragEnd={(currentCard: any, from: any, to: any) => this.moveCardFn(currentCard, from, to, groupId)}
                                                        renderColumnHeader={(column: any, index: number) =>
                                                            <div style={{
                                                                padding: '10px',
                                                                backgroundColor: column?.color,
                                                                borderBottom: '2px solid #ddd'
                                                            }}>
                                                                <p style={{ margin: 0, color: "#FFF" }}>{column.title}</p>
                                                            </div>
                                                        }
                                                        renderCard={(item: any, { index, dragging }: any) => {
                                                            // const handleMouseMove = (event: any) => {
                                                            //     if (dragging) {
                                                            //         this.handleDrag(event);
                                                            //     }
                                                            // };

                                                            // React.useEffect(() => {
                                                            //     if (dragging) {
                                                            //         window.addEventListener('mousemove', handleMouseMove);
                                                            //     }

                                                            //     return () => {
                                                            //         window.removeEventListener('mousemove', handleMouseMove);
                                                            //     };
                                                            // }, [dragging]);
                                                            return (<div className='kanbanAdjustment2-step'>
                                                                <div className="card-a card-a-custom-step" style={{ background: "white" }}>
                                                                    <div className="card-header-a-step" style={{ background: item.situation_chat_color }}>
                                                                        <h6 className="card-title-a-step">
                                                                            {item.show_alert && (
                                                                                <Whisper trigger="hover" placement="top" speaker={<Tooltip placement="bottom">Atenção!</Tooltip>}>
                                                                                    <Button
                                                                                        appearance="primary"
                                                                                        color="yellow"
                                                                                        size='sm'
                                                                                        style={{ float: "left", marginTop: -10, cursor: "default" }}
                                                                                    >
                                                                                        <i className="fas fa-exclamation-triangle"></i>
                                                                                    </Button>
                                                                                </Whisper>
                                                                            )}
                                                                            <Link
                                                                                style={{ color: "#FFF" }}
                                                                                key={index}
                                                                                target={"_blank"}
                                                                                to={`/dashboard/project/chats/${item?.project_id}`}
                                                                            >
                                                                                {item?.project_code}
                                                                            </Link>
                                                                            {(item?.envolved_users?.includes(this.context?.data?.user_id) && item?.situation_chat_id !== 3) ? (
                                                                                <Whisper trigger="hover" placement="top" speaker={<Tooltip placement="bottom">Chat da Fase</Tooltip>}>
                                                                                    <Button
                                                                                        appearance="primary"
                                                                                        color="blue"
                                                                                        size='sm'
                                                                                        style={{ float: "right", marginTop: -10 }}
                                                                                        onClick={() => this.setState({ viewCard: item, isDrawerOpen: true })}>
                                                                                        <i className="fas fa-comments"></i>
                                                                                    </Button>
                                                                                </Whisper>
                                                                            ) : (
                                                                                <></>
                                                                            )}

                                                                            {/* <Button
                                                                                appearance="primary"
                                                                                color="blue"
                                                                                size='sm'
                                                                                style={{ float: "right", marginTop: -10, marginRight: 5 }}
                                                                                onClick={() => window.open(`/dashboard/project/chats/${item?.project_id}/${item?.project_product_id}/${item?.step_id}`, "_blank")}>
                                                                                <i className="fas fa-fw fa-external-link-alt"></i>
                                                                            </Button> */}
                                                                        </h6>
                                                                    </div>
                                                                    <div className='card-body-a-step'>
                                                                        <p className='break-word-step' style={{ marginBottom: -5 }}>
                                                                            <strong>Cliente</strong><br />
                                                                            <Link
                                                                                key={index}
                                                                                target={"_blank"}
                                                                                to={`/dashboard/registrations/people/${item?.project_client_fk_person_id}`}
                                                                            >
                                                                                {item?.cliente}
                                                                                <i className="fas fa-fw fa-external-link-alt ml-2"></i>
                                                                            </Link>
                                                                        </p>
                                                                        <p className='break-word-step' style={{ marginBottom: -5 }}>
                                                                            <strong>Descrição</strong><br />
                                                                            <Link
                                                                                key={index}
                                                                                target={"_blank"}
                                                                                to={`/dashboard/project/projects/${item?.project_product_fk_project_id}`}
                                                                            >
                                                                                {item?.title} - {item?.product_name}
                                                                                <i className="fas fa-fw fa-external-link-alt ml-2"></i>
                                                                            </Link>
                                                                        </p>
                                                                        <p className='break-word-step' style={{ marginBottom: -5 }}>
                                                                            <strong>Status</strong><br />
                                                                            <Tag style={{ backgroundColor: item.situation_chat_color, color: "#FFF" }}>
                                                                                {item.situation_chat_name}
                                                                            </Tag>
                                                                        </p>
                                                                        <p className='break-word-step' style={{ marginBottom: -5 }}>
                                                                            {dayjs(item?.project_product_group_step_table_flow_finalized_at).isValid() && item?.project_fk_situation_id == 5 ? (
                                                                                <p className='break-word-step'>
                                                                                    <strong>Data de Finalização</strong><br />
                                                                                    {dayjs(item.project_product_group_step_table_flow_finalized_at).format("DD/MM/YYYY HH:mm")}
                                                                                </p>
                                                                            ) : (
                                                                                <>
                                                                                    {item?.project_deadline_project != null && (
                                                                                        <p className='break-word-step'>
                                                                                            <strong>Prazo Final do Projeto</strong><br />
                                                                                            {
                                                                                                item?.project_deadline_project == 0 ? (<> Hoje </>)
                                                                                                    : item?.project_deadline_project > 0 ? (<> {item?.project_deadline_project} Dias </>)
                                                                                                        : item?.project_deadline_project < 0 && (<> {Math.abs(item?.project_deadline_project)} Dias em atraso </>)
                                                                                            }
                                                                                        </p>
                                                                                    )}
                                                                                    {item?.project_deadline_step != null && (
                                                                                        <p className='break-word-step'>
                                                                                            <strong>Prazo da Fase</strong><br />
                                                                                            {
                                                                                                item?.project_deadline_step == 0 ? (<> Hoje </>)
                                                                                                    : item?.project_deadline_step > 0 ? (<> {item?.project_deadline_step} Dias </>)
                                                                                                        : item?.project_deadline_step < 0 && (<> {Math.abs(item?.project_deadline_step)} Dias em atraso </>)
                                                                                            }
                                                                                        </p>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            <div style={{ float: "right" }}>
                                                                                <Whisper trigger="hover" placement="top" speaker={<Tooltip placement="bottom">Detalhes</Tooltip>}>
                                                                                    <Button
                                                                                        appearance="primary"
                                                                                        color="cyan"
                                                                                        size='sm'
                                                                                        style={{ float: "left", marginTop: -10, marginRight: 5 }}
                                                                                        onClick={() => this.setState({ viewCard: item, isProjectProductStatusModalOpen: true })}>
                                                                                        <i className="fas fa-info"></i>
                                                                                    </Button>
                                                                                </Whisper>
                                                                            </div>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                        }}
                                                    >
                                                        {data?.kanbans?.[groupId.toString()]}
                                                    </Board>
                                                )}
                                                {this.renderKanbanCard()}
                                                {this.renderProjectProductStatusModal()}
                                            </div>
                                        )
                                    })}
                                </Panel>
                            )}
                        </>
                    )
                }
            </>
        )
    }
}

export default withRouter(KanbanPage);