import React, { useContext, useState, useEffect } from "react";

interface AudioContextData {
    playAudio: (source: HTMLAudioElement) => boolean
    stopAudio: () => void
}

export const AudioContext = React.createContext<AudioContextData>(
    {} as AudioContextData
)

interface AudioProviderProps {
    children: React.ReactNode
}


export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
    let playingAudio: HTMLAudioElement | null = null

    const playAudio = (source: HTMLAudioElement) => {
      try {
        playingAudio?.pause()
        source.play()
        playingAudio = source
        return true
      } catch (error) {
        console.log("Erro ao tocar o audio:", error);
        return false
      }
    }

    const stopAudio = () => {
      if (playingAudio) {
        playingAudio.pause()
        playingAudio = null
      }
    }

    const AudioContextValues: AudioContextData = {
        playAudio,
        stopAudio
    };

    return (
        <AudioContext.Provider value={AudioContextValues}>
            {children}
        </AudioContext.Provider>
    )
}


export function useAudioPlayer() {
    const context = useContext(AudioContext);
    return context
}