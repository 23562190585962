import React from "react";
import { Container, Content, Drawer, Loader, Panel } from "rsuite";
import { BrowserPage } from "./pages/BrowserPage";
import { ReadListItems } from "./pages/ReadListItems";
import { ChatList } from "./pages/ChatList";
import { ChatPage } from "./pages/ChatPage";
import { HeaderComponent } from "./components/HeaderComponent";
import { Helmet } from "react-helmet";
import { ChatClientRender } from "./components/ChatClientRender";
import axios from "../../../../utilities/axios";

export class ChatUserComponent extends React.Component<any, {}> {
    state: any = {
        pathRoute: 'projects',
        paramsRoute: {},
        loadings: false,
        isDrawerOpen: true,
        projectData: null,
        stepData: null
    }

    constructor(props: any) {
        super(props);
        // Vinculando o método ao contexto do componente
        this.nextRouter = this.nextRouter.bind(this);
        this.previousRoute = this.previousRoute.bind(this);
    }

    componentDidMount(): void {
        this.mountedRouterInitial();
        const { projectId, ppgstfId } = this.props.match.params;

        if (projectId) {
            this.getProject(projectId);
        }
        if (ppgstfId) {
            this.getStep(ppgstfId);
        }
    }

    componentDidUpdate(prevProps: any) {
        const { projectId, ppgstfId } = this.props.match.params;

        if (projectId !== prevProps.match.params.projectId) {
            this.getProject(projectId);
        }

        if (ppgstfId !== prevProps.match.params.ppgstfId) {
            this.getStep(ppgstfId);
        }
    }

    mountedRouterInitial() {

    }

    nextRouter({ route, paramsRoute }: { route: string, paramsRoute: Record<string, any> }) {
        this.setState(
            {
                pathRoute: route,
                paramsRoute: paramsRoute,
            }

        )
    }

    previousRoute({ route, dataParams }: { route: string, dataParams: Record<string, any> }) {
        dataParams.pathUrl.pop()
        this.setState(
            {
                pathRoute: route,
                paramsRoute: dataParams
            }
        )
    }

    getProject(projectId: string) {
        if (projectId) {
            axios.get(`/api/v1/projects/${projectId}`)
                .then(async (response) => {
                    this.setState({ projectData: response.data.item });
                });
        }
    }

    getStep = (ppgstfId: string) => {
        if (ppgstfId) {
            axios.get(`/api/v1/get-step-by-ppgstf/${ppgstfId}`)
                .then((response) => {
                    this.setState({ stepData: response.data.item });
                });
        }
    }

    renderComponentRoute = () => {
        const output: any = [];
        let { pathRoute, paramsRoute } = this.state;

        const { projectId, projectProductId, ppgstfId } = this.props.match.params;

        if (localStorage.getItem('chatWithNotification')) {
            const paramsNotification: any = localStorage.getItem('chatWithNotification');
            pathRoute = 'chatPage';
            paramsRoute = JSON.parse(paramsNotification).params;
            localStorage.removeItem('chatWithNotification')
        }

        // console.log({ projectProductId })

        if (ppgstfId) {
            // this.getProject();
            // this.getStep();
            return (
                <>
                    <ReadListItems
                        {...this.props}
                        nextRouter={this.nextRouter}
                        previousRoute={this.previousRoute}
                        paramsRoute={paramsRoute}
                    />
                    <Drawer
                        placement="right"
                        size="md"
                        backdrop
                        autoFocus
                        show={ppgstfId ? true : false}
                        onHide={() => { this.props.history.push(`/dashboard/project/chats/${projectId}/${projectProductId}`); }}
                    >
                        <Drawer.Header>
                            <Drawer.Title>
                                <i className="far fa-comments fa-lg" style={{ marginTop: 3 }}></i>&nbsp;
                                {this.state.projectData?.project_id ? (
                                    <>
                                        {this.state.projectData?.project_code} - {this.state.projectData?.project_description} - {this.state.projectData?.people?.person_name} - {this.state?.stepData?.step_name}
                                    </>
                                ) : (
                                    <Loader size="sm" speed="fast" content="Carregando..." />
                                )}
                            </Drawer.Title>
                        </Drawer.Header>
                        <Drawer.Body>
                            <ChatPage
                                {...this.props}
                                disableHeader
                                nextRouter={this.nextRouter}
                                previousRoute={this.previousRoute}
                                paramsRoute={paramsRoute}
                            />
                        </Drawer.Body>
                    </Drawer>
                </>
            )
        }

        if (projectProductId) {
            return (
                // <ChatList
                //     {...this.props}
                //     nextRouter={this.nextRouter}
                //     previousRoute={this.previousRoute}
                //     paramsRoute={paramsRoute}
                // />
                <ReadListItems
                    {...this.props}
                    nextRouter={this.nextRouter}
                    previousRoute={this.previousRoute}
                    paramsRoute={paramsRoute}
                />
            )
        }

        if (projectId && this.props.location.pathname.includes("chat-client")) {
            // this.getProject();
            return (
                <>
                    <ReadListItems
                        {...this.props}
                        nextRouter={this.nextRouter}
                        previousRoute={this.previousRoute}
                        paramsRoute={paramsRoute}
                    />
                    <Drawer
                        placement="right"
                        size="md"
                        backdrop
                        autoFocus
                        show={this.props.location.pathname.includes("chat-client") ? true : false}
                        onHide={() => { this.props.history.push(`/dashboard/project/chats/${projectId}`); }}
                    >
                        <Drawer.Header>
                            <Drawer.Title>
                                <i className="far fa-comments fa-lg" style={{ marginTop: 3 }}></i>&nbsp;
                                {this.state.projectData?.project_id ? (
                                    <>
                                        {this.state.projectData?.project_code} - {this.state.projectData?.project_description} - {this.state.projectData?.people?.person_name} - CHAT DO CLIENTE
                                    </>
                                ) : (
                                    <Loader size="sm" speed="fast" content="Carregando..." />
                                )}
                            </Drawer.Title>
                        </Drawer.Header>
                        <Drawer.Body>
                            <ChatPage
                                {...this.props}
                                disableHeader
                                nextRouter={this.nextRouter}
                                previousRoute={this.previousRoute}
                                paramsRoute={paramsRoute}
                            />
                        </Drawer.Body>
                    </Drawer>
                </>
            )
        }

        if (projectId) {
            return (
                <ReadListItems
                    {...this.props}
                    nextRouter={this.nextRouter}
                    previousRoute={this.previousRoute}
                    paramsRoute={paramsRoute}
                />
            )
        }

        return (
            <BrowserPage
                {...this.props}
                nextRouter={this.nextRouter}
            />
        )

        // switch (pathRoute) {
        //     case 'projects':
        //         output.push(<BrowserPage
        //             {...this.props}
        //             nextRouter={this.nextRouter}
        //         // previousRoute={this.previousRoute}
        //         />)
        //         // return <BrowserPage
        //         //     nextRouter={this.nextRouter}
        //         // />
        //         break;
        //     case 'products':
        //         output.push(
        //             <ReadListItems
        //                 {...this.props}
        //                 nextRouter={this.nextRouter}
        //                 previousRoute={this.previousRoute}
        //                 paramsRoute={paramsRoute}
        //             />
        //         )
        //         // return <ReadListItems 
        //         // />
        //         break;
        //     case 'chatList':
        //         output.push(
        //             <ChatList
        //                 nextRouter={this.nextRouter}
        //                 previousRoute={this.previousRoute}
        //                 paramsRoute={paramsRoute}
        //             />
        //         )
        //         break;
        //     case 'chatPage':
        //         output.push(
        //             <ChatPage
        //                 nextRouter={this.nextRouter}
        //                 previousRoute={this.previousRoute}
        //                 paramsRoute={paramsRoute}
        //             />
        //         )
        //         break;
        // }
        // return output;
    }

    render(): React.ReactNode {
        return (
            <div className="container" style={{ marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }}>
                <Helmet>
                    <title>FastFlow - Chats</title>
                </Helmet>
                <Panel bordered className="rs-no-body mb-4">
                    <div className="d-flex justify-contents-between align-items-center">
                        <div></div>
                        <div>
                            <h5 className="d-flex align-items-center">
                                <i className="fas fa-fw mr-4 fa fa-comments"></i>
                                Chats
                            </h5>
                        </div>
                        <div></div>
                    </div>
                </Panel>
                <Panel bordered bodyFill className="rs-no-body">
                    <Container
                        style={
                            {
                                height: '75vh',
                                width: "100%",
                                gap: '10'
                            }
                        }
                    >
                        <Content>
                            {this.renderComponentRoute()}
                        </Content>
                    </Container>
                </Panel>
            </div>
        )
    }
}