import classNames from "classnames";
import dayjs from "dayjs";
import React from "react";
import { Avatar, Button, Divider, List, Loader, Panel, Notification, Input, CheckPicker, ControlLabel, Pagination, InputGroup, FormGroup, SelectPicker } from "rsuite";
import axios from "../../utilities/axios";
import { io } from "../../utilities/io";
import { UserContext } from "../authProvider/main";
import { CheckTreePicker } from "../formfields/CheckTreePicker";
import { WYSIWYG } from "../formfields/WYSIWYG";

class InboxReplyMessage extends React.Component<any, any> {
    render() {
        return <div>a</div>;
    }
}

class InboxMessage extends React.Component<any, {}> {
    render() {
        if (this.props?.inbox_title) {
            const seen = this.props.inbox_seen_by?.includes(this.props.user.toString());
            const isReply = this.props.inbox_reply_id !== null;
            return (
                <List.Item className={classNames("inbox-message", { "inbox-not-seen": !seen })} onClick={this.props.onClick}>
                    <div className="inbox-message-icon">
                        <Avatar>{this.props?.inbox_sent_by_name[0]}</Avatar>
                    </div>
                    <div className="inbox-message-from">{this.props?.inbox_sent_by_name}</div>
                    <div className="inbox-message-text">
                        <div className="inbox-message-title">{this.props?.inbox_title}</div>
                        <div className="inbox-message-content" dangerouslySetInnerHTML={{ __html: this.props.inbox_content }}></div>
                    </div>
                    <div className="inbox-message-date">{dayjs(this.props.inbox_created_at).format("DD/MM/YYYY HH:mm")}</div>
                    <div className="inbox-message-meta">
                        <div className="inbox-message-meta-icons">
                            {seen && <i className="fas text-primary fa-eye"></i>}
                            {/* <i className="fas text-warning fa-calendar"></i> */}
                            {/* <i className="fas text-danger fa-flag"></i> */}
                            {isReply && <i className="fas fa-reply text-secondary"></i>}
                        </div>
                    </div>
                </List.Item>
            );
        }
        return null;
    }
}

class InboxReadMessage extends React.Component<any, {}> {
    public state = {
        replying: false,
        loading: false,
        value: "",
        sendTo: this.props.inbox_users?.map((item: any) => {
            return parseInt(item);
        }),
        departments: this.props.inbox_departments?.map((item: any) => {
            return parseInt(item);
        }),
    };
    private header = () => (
        <div className="inbox-read-header">
            <div className="inbox-read-actions">
                <Button onClick={this.props.onCancel}>Voltar</Button>
                <Button className="ml-2" color={"blue"} onClick={() => this.setState({ replying: !this.state.replying })}>
                    {this.state.replying == true ? "Cancelar" : "Responder"}
                </Button>
            </div>
            {this.state.replying == true && (
                <div className="inbox-read-send">
                    <ControlLabel style={{ fontSize: 12 }}>Para</ControlLabel>
                    <CheckTreePicker
                        value={this.state.sendTo}
                        onChange={(value: any) => this.setState({ sendTo: value })}
                        api={{
                            url: "/api/v1/users/select",
                            method: "get",
                            uses: [],
                        }}
                    />
                    {/* {JSON.stringify(this.state.sendTo)} */}
                    {/* <CheckPicker data={[]} /> */}
                </div>
            )}
            {this.state.replying == true && (
                <div className="inbox-read-send">
                    <ControlLabel style={{ fontSize: 12 }}>Departamentos</ControlLabel>
                    <CheckTreePicker
                        value={this.state.departments}
                        onChange={(value: any) => this.setState({ departments: value })}
                        api={{
                            url: "/api/v1/departments/select",
                            method: "get",
                            uses: [],
                        }}
                    />
                    {/* <CheckPicker data={[]} /> */}
                </div>
            )}
            {/* <div className="inbox-read-title">{this.props.inbox_title}</div>
            <div className="inbox-read-meta">{dayjs(this.props.inbox_created_at).format("DD/MM/YYYY HH:mm")}</div> */}
        </div>
    );

    onSendReply = () => {
        this.setState({ loading: true });
        axios
            .post("/api/v1/inbox/reply", {
                inbox_id: this.props.inbox_id,
                inbox_content: this.state.value,
                inbox_users: this.state.sendTo,
                inbox_departments: this.state.departments,
            })
            .then(() => {
                Notification.success({
                    title: "Sucesso",
                    description: "Mensagem enviada com sucesso",
                });
                this.setState({ value: "", replying: false });
                if (this.props.onCancel) {
                    this.props.onCancel();
                }
            })
            .finally(() => {
                this.setState({ loading: false });
            })
            .catch(() => {});
    };

    renderReplies = () => {
        return this.props.replies?.map((item: any) => {
            return (
                <>
                    <Divider style={{ marginBottom: 0, color: "#272c36" }}>
                        <span style={{ fontWeight: "bold", fontSize: 16 }}>{item.inbox_title}</span>
                    </Divider>
                    <div style={{ marginBottom: 10, opacity: 0.6, fontSize: "0.8rem", display: "flex", justifyContent: "space-between" }}>
                        <div></div>
                        <div>
                            {item.inbox_sent_by_name} - {dayjs(item.inbox_created_at).format("DD/MM/YYYY HH:mm")}
                        </div>
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: item.inbox_content }}></div>
                </>
            );
        });
    };

    render() {
        return (
            <Panel className="inbox-read-message" bordered header={this.header()}>
                {this.state.loading == false && (
                    <>
                        {this.state.replying == false && (
                            <>
                                <Divider style={{ marginBottom: 0, color: "#272c36" }}>
                                    <span style={{ fontWeight: "bold", fontSize: 16 }}>{this.props.inbox_title}</span>
                                </Divider>
                                <div style={{ marginBottom: 10, opacity: 0.6, fontSize: "0.8rem", display: "flex", justifyContent: "space-between" }}>
                                    <div></div>
                                    <div>
                                        {this.props.inbox_sent_by_name} - {dayjs(this.props.inbox_created_at).format("DD/MM/YYYY HH:mm")}
                                    </div>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: this.props.inbox_content }}></div>
                                {this.renderReplies()}
                            </>
                        )}
                        {this.state.replying == true && (
                            <>
                                <WYSIWYG defaultValue={this.state.value} onChange={(value: string) => this.setState({ value })} />
                                <div style={{ display: "flex", justifyContent: "space-between" }} className="mt-4">
                                    <div></div>
                                    <div>
                                        <Button onClick={this.onSendReply} disabled={this.state.value.length <= 0}>
                                            Enviar
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </>
                )}
                {this.state.loading == true && (
                    <>
                        <div style={{ display: "flex", height: 180, justifyContent: "center", paddingBottom: 16, alignItems: "center" }}>
                            <Loader size={"lg"} />
                        </div>
                    </>
                )}
            </Panel>
        );
    }
}

export default class UserInbox extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;

    public state = {
        page: "list",
        readingEmailId: null,
        loading: true,
        inbox: [],
        activeEmail: null,
        filterColumn: 'all',
        searchValue: undefined as string | undefined,
        meta: {
            page: 1,
            perPage: 10,
            pages: 0,
        },
    };

    loadInbox = (page = 1) => {
        var filter = this.state.searchValue ?? undefined;

        this.setState({ loading: true });
        axios
            .get("/api/v1/inbox", {
                params: {
                    page,
                    filter,
                    column: this.state.filterColumn !== 'all' ? this.state.filterColumn : undefined,
                },
            })
            .then((res) => {
                this.setState({ inbox: res.data.list, meta: res.data.meta });
            })
            .catch((err) => {})
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    componentDidMount() {
        this.loadInbox();
    }

    private hasNotificationEvent = false;

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.readingEmailId !== this.state.readingEmailId && this.state.readingEmailId == null) {
            this.loadInbox();
        }
        if (this.context?.data?.user_id !== undefined && this.hasNotificationEvent == false) {
            io.on("notification", (msg) => {
                if (msg.users.includes(parseInt(this.context?.data?.user_id))) {
                    if(this.state.page == 'list') {
                        this.loadInbox();
                    }
                }
            });
            this.hasNotificationEvent = true;
        }
    }

    componentWillUnmount() {
        io.off("notification");
    }

    readEmail = (emailId: number) => {
        const email = this.state.inbox.find((email: any) => email.inbox_id.toString() === emailId.toString());
        if (email) {
            this.setState({ loading: true });
            axios
                .get("/api/v1/inbox/seen/" + emailId)
                .then((res) => {
                    this.setState({ readingEmailId: emailId, activeEmail: email, page: "read" });
                    // this.setState({ inbox: res.data.list });
                })
                .catch((err) => {})
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    };

    render() {
        return (
            <div className="inbox">
                {this.state.loading == true && (
                    <Panel bordered>
                        <div style={{ display: "flex", height: 180, justifyContent: "center", paddingBottom: 16, alignItems: "center" }}>
                            <Loader size={"lg"} />
                        </div>
                    </Panel>
                )}
                {this.state.loading == false && this.state.page === "list" && (
                    <>
                        <FormGroup className="mb-3">
                            <InputGroup>
                                <Input
                                    defaultValue={this.state.searchValue}
                                    onChange={(searchValue) => this.setState({ searchValue })}
                                    onPressEnter={(event: any) => this.loadInbox(1)}
                                    placeholder={"Pesquisar por..."}
                                />
                                <SelectPicker 
                                    cleanable={false}
                                    value={this.state.filterColumn}
                                    onChange={(filterColumn) => this.setState({ filterColumn })}
                                    data={[
                                    { label: "Todos", value: "all" },
                                    { label: "Data", value: "inbox_created_at" },
                                    { label: "Assunto", value:"inbox_title"},
                                    { label: "Conteúdo", value:"inbox_content"}]} preventOverflow></SelectPicker>
                                <InputGroup.Button onClick={(event: any) => this.loadInbox(1)}>
                                    <i className="fas fa-search fa-fw"></i>
                                </InputGroup.Button>
                            </InputGroup>
                        </FormGroup>
                        {/* <Panel bordered className="mb-2"> */}

                        {/* </Panel> */}
                        <List bordered>
                            <List.Item disabled className="inbox-header">
                                <div>#</div>
                                <div>Enviado por</div>
                                <div>Assunto</div>
                                <div>Data</div>
                                <div>
                                    <Button size={"sm"} onClick={() => this.loadInbox()}>
                                        <i className="fas fa-sync clickable"></i>
                                    </Button>
                                    {/* <i onClick={this.loadInbox} className="fas fa-sync clickable"></i> */}
                                </div>
                            </List.Item>
                            {this.state.inbox.map((item: any) => {
                                return <InboxMessage user={this.context.data?.user_id} onClick={() => this.readEmail(item.inbox_id)} {...item} />;
                            })}
                            <List.Item>
                                <Pagination
                                    onSelect={this.loadInbox}
                                    activePage={this.state.meta.page}
                                    pages={this.state.meta.pages}
                                    maxButtons={4}
                                    prev={true}
                                    first={true}
                                    next={true}
                                    last={true}
                                />
                            </List.Item>
                        </List>
                    </>
                )}
                {/* {this.state.loading == false && this.state.page === "read" && (
                    <InboxReadMessage {...this?.state?.activeEmail} onCancel={() => this.setState({ readingEmailId: null, page: "list" })} />
                )} */}
                {/* {this.state.loading == false && this.state.page == "reply" && <div>Respondendo email....</div>} */}
            </div>
        );
    }
}
