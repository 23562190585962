import React from "react";
import { Button, Nav, Panel, Placeholder } from "rsuite";
import { IntegrationOmiePage } from "./modules/IntegrationOmiePage";
import { IntegrationMbmConfigPage } from "./modules_config/IntegrationMbmConfigPage";
import { UserContext } from "../../components/authProvider/main";
import axios from "../../utilities/axios";
import { IntegrationMbmPage } from "./modules/IntegrationMbmPage";
import qs from "query-string";

export class IntegrationPage extends React.Component<any, {}> {
    static contextType = UserContext;
    public state:any = {
        values: {},
        isLoading: false,
        currentTab: (qs.parse(this.props.location.search)?.integration || -1),
        tabs: [
            {
                key: "IntegrationOMIE",
                component: <IntegrationOmiePage />,
                name: "OMIE"
            }
            , {
                key: "IntegrationMBM",
                component: <IntegrationMbmPage/>,
                name: "MBM Solutions"
            }
        ]
    }


    changeValues = (newValues: any) => {
        this.setState({
            values: { ...this.state.values, ...newValues }
        })
    }

    renderNavbar = () => {
        return (<>
            <Nav appearance="tabs" onSelect={(eventKey) => this.setState({ currentTab: eventKey })} style={{ background: "white" }} activeKey={this.state.currentTab}>
                {this.state.tabs?.map((tab:any, index:any) => {
                    return (
                        <Nav.Item eventKey={index}>
                            {tab?.name}
                        </Nav.Item>)
                })}
            </Nav>
        </>)
    }

    renderComponent = () => {
        return (<>
            {this.state.tabs[this.state.currentTab] && (
                <>
                    {React.cloneElement(this.state.tabs[this.state.currentTab].component, { ...this.props, ...this.state, onChange: this.changeValues })}
                </>
            )}
        </>)
    }

    render(): React.ReactNode {
        return (<>
            <div style={{ zIndex: 1, marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }} className="container">
                <Panel style={{ background: "white", color: "black", width: "100vw", textAlign: "center", borderRadius: 8 }}>
                    🌐 <b>INTEGRAÇÕES</b>
                </Panel>
                <div style={{ width: "100%", marginTop: 8 }}>
                    {this.renderNavbar()}
                    <Panel style={{ background: "white" }}>
                        {this.state.currentTab < 0 &&(<>
                            <b>Bem vindo ao Painel de integração, Selecione uma integração para continuar ⚡</b>
                        </>)}
                        {!this.state.isLoading ?
                            (this.renderComponent())
                            : (
                                <Placeholder.Paragraph active rows={8}></Placeholder.Paragraph>
                            )}
                    </Panel>
                </div>
            </div>
        </>)
    }
}