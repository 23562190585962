import { DownloadImage } from "../../../../chatClient/DownloadImage";

interface ChatImageProps {
  url: string;
  alt: string
  clickImage: () => void
}

const ChatImage = ({ url, alt, clickImage }: ChatImageProps) => {
  return (
    <div
      style={{
        position: "relative",
        borderRadius: "4px",
        overflow: "hidden"
      }}
    >
      <img
        alt={alt}
        src={url}
        style={{ width: 250, height: 300, objectFit: "cover" }}
        onClick={clickImage}
      />
      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
        }}
      >
        <DownloadImage
          urlImage={url}
        />
      </div>
    </div>
  );
};

export default ChatImage;
