import { useRef, useState } from "react";
import { Dropdown, Icon, IconButton, Tag, Tooltip, Whisper } from "rsuite";

interface InputWithTagsProps {
	variableList: Array<string>
	defaultValue: string
	label: string
	value: string | undefined
	onChange: (value: string) => void
}

const InputWithTags = ({variableList, value, defaultValue, label, onChange}: InputWithTagsProps) => {
	const [content, setContent] = useState(value ?? defaultValue)
	const elementRef = useRef<HTMLDivElement | null>(null);
	let selection: any;

  	const renderInputMessage = (msg: string): string => {
		let newMessage = msg

		let output: string = ''
		for (let index = 0; index < msg.length; index++) {
			if (newMessage.indexOf("[") == 0) {
				let nextOpen = newMessage.indexOf("[", 1)
				let nextClose = newMessage.indexOf("]", 1)
				if ((nextClose < nextOpen || nextOpen == -1) && variableList.includes(newMessage.slice(1, nextClose).toUpperCase()) ) {
					output += `<span contenteditable="false"><span class="rs-tag" style="userSelect: none; background-color: #00b3ab50;">${newMessage.slice(0, nextClose+1).toUpperCase()}</span></span>`
					newMessage = newMessage.slice(nextClose+1)
				} else {
					output += `<span>${newMessage.slice(0, nextOpen)}</span>`
					newMessage = newMessage.slice(nextOpen)
				}
			} else {
				let nextOpen = newMessage.indexOf("[")
				if (nextOpen == -1) {
					output += `<span>${newMessage}</span>`
					newMessage = ''
				} else {
					output += `<span>${newMessage.slice(0, nextOpen)}</span>`
					newMessage = newMessage.slice(nextOpen)
				}
			}
			if (newMessage.length <= 0) break;
		}
		return output
	}

  	const addVariable = (eventKey: any, event: any) => {
		const parentSpan = document.createElement("span")
		parentSpan.contentEditable = "false"

		const childrenSpan = document.createElement("span")
		childrenSpan.innerText = `[${variableList[eventKey]}]`
		childrenSpan.className = "rs-tag"
		childrenSpan.style.userSelect = 'none'
		childrenSpan.style.backgroundColor = '#00b3ab50'

		parentSpan.appendChild(childrenSpan)
		if (selection) {
			selection.insertNode(parentSpan)
		} else if (elementRef.current) {
			elementRef.current.appendChild(parentSpan)
			onChange(elementRef.current.innerText)
		}
	}

	const onSelectInput = () => {
		const select = window.getSelection();
		
		if (select?.rangeCount === 0) {
			selection = undefined;
			return;
		}
		const range = select?.getRangeAt(0);
		selection = range;
	}

	const onBlurInput = () => {
		if (elementRef.current) {
			setContent(elementRef.current.innerText)
			onChange(elementRef.current.innerText)
		}
	}

	const resetDefault = () => {
		setContent(defaultValue)
		onChange(defaultValue)
	}

	return (
		<div style={{marginBottom: "1em"}}>
			<p>{label}</p>
			<div style={{position: "relative", width: "100%", display: "flex", alignItems: "center", gap: "1rem"}}>
				<div ref={elementRef} contentEditable className="rs-input" onSelect={onSelectInput} onBlur={onBlurInput} dangerouslySetInnerHTML={{__html: renderInputMessage(content)}}/>
				<Dropdown placement="bottomEnd" title="Variáveis" noCaret renderTitle={(children) => (
					<Whisper placement="top" trigger="hover" speaker={<Tooltip>Adicionar Variável</Tooltip>}>
						<IconButton appearance="ghost" icon={<Icon icon="plus" />} circle size="md" />
					</Whisper>
				)}
				onSelect={addVariable}
				>
				{variableList.map((item: string, index: number) => (
					<Dropdown.Item eventKey={index}>{item}</Dropdown.Item>
				))}
				</Dropdown>
				<Whisper placement="top" trigger="hover" speaker={<Tooltip>Restaurar Padrão</Tooltip>}>
					<IconButton appearance="ghost" icon={<Icon icon="refresh" />} circle size="md" onClick={resetDefault}/>
				</Whisper>
			</div>
		</div>
  	);
};

export { InputWithTags };
