import dayjs from "dayjs";
import axios from "../../../utilities/axios";
import { Event } from "../../Event";

export class ProjectProductAppointmentEvents extends Event {
    public onLoad = (value: any, setField: any, setState: any) => {
        this.setState('loadings', [
            'project_product_appointment_fk_user_id',
            'project_product_appointment_fk_project_product_id',
            'project_product_appointment_fk_step_id',
            'project_product_appointment_user_responsible_fk_user_id',
            'project_product_appointment_status',
            'project_product_appointment_init',
            'project_product_appointment_finish',
        ]);

        this.setField('project_product_appointment_fk_project_product_id', {
            api: {
                method: "GET",
                url: `/api/v1/project_products/select?project_id=[${this.globalProps.parentValues.project_id}]`,
                uses: [
                    {
                        type: "prop",
                    }
                ],
            }
        });
        
        this.setField('project_product_appointment_user_responsible_fk_user_id', {
            readonly: true
        });

        this.setFieldValue('project_product_appointment_release_type', "AJUSTE");
        // const values = this.globalProps.values;
        // if (this.globalProps.isEdit >= 0) {
        //     const values = this.globalProps.values;
        //     // console.log({ values });
        //     if (values.project_product_appointment_init) {
        //         this.setField('project_product_appointment_init', {
        //             hidden: false,
        //             required: true
        //         });
        //         this.setField('project_product_appointment_finish', {
        //             hidden: true,
        //             required: false
        //         });
        //         this.setField('project_product_appointment_total_appointment_time', {
        //             hidden: true
        //         });
        //     }
        //     if (values.project_product_appointment_finish) {
        //         this.setField('project_product_appointment_finish', {
        //             hidden: false,
        //             required: true
        //         });
        //         this.setField('project_product_appointment_init', {
        //             hidden: true
        //         });
        //     }
        // }

        // this.setField('project_product_appointment_finish', {
        //     hidden: false,
        //     required: true
        // });
          
        this.setField('project_product_appointment_fk_user_id', {
            readonly: true,
        })
        if (this.globalProps.isEdit === null && !this.globalProps.readOnly) {
            this.setFieldValue('project_product_appointment_init', dayjs().format("YYYY-MM-DDTHH:mm:ssZ"));
            this.setField('project_product_appointment_fk_user_id', {
                readonly: false,
            })
            axios.get("/api/v1/me").then((res) => {
                this.setState('loadings', ['project_product_appointment_user_responsible_fk_user_id'])
                this.setFieldValue('project_product_appointment_user_responsible_fk_user_id', res.data.user.user_id);
                this.setState('loadings', [])
            })
        }

        const userAppointment = this.globalProps.values.project_product_appointment_fk_user_id
        const projectProductAppointment = this.globalProps.values.project_product_appointment_fk_project_product_id
        this.setField('project_product_appointment_fk_step_id', {
            api: {
                method: "GET",
                url: `/api/v1/steps/select?${projectProductAppointment ? `project_product_id=[${projectProductAppointment}]` : ''}${userAppointment ? `&user_id=${userAppointment}` : ''}`,
                uses: [
                    {
                        type: "prop",
                    }
                ],
            }
        });

        setTimeout(() => {
            this.setState('loadings', []);
        }, 1000);
    }
}