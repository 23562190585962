import React from "react";
import { Button, Icon, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { ConfirmModal } from "../../../components/confirmModal/ConfirmModal";

class ProductComponent extends React.Component<any, {}> {
    state = {
        loading: false,
        showDialogModal: false
    }

    cloneProducts = () => {
        const { parentState } = this.props

        for (const i in parentState.checked) {
            this.setState({ loading: true });
            axios.get(`/api/v1/clone/product/${parentState.checked[i]}`)
                .then((res) => {
                    const response = res.data;
                    if (response.status === 200) {
                        Notification.success({
                            title: "Sucesso",
                            description: "Itens duplicados com êxito!"
                        });

                        setTimeout(() => {
                            if (parentState.checked.length === 1) {
                                const { product_id } = response.result[0];
                                window.open(`/dashboard/registrations/products/${product_id}`, "_blank");
                            }
                            window.location.reload();
                        }, 3000);
                    } else {
                        throw new Error(response.message);
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response?.data?.message || error.message || "Houve um erro ao duplicar o item.";
                    Notification.error({
                        title: "Erro",
                        description: errorMessage,
                        duration: 8000
                    });
                })
                .finally(() => {
                    this.setState({ loading: false, showDialogModal: false });
                });
        }
    }

    openCloneModal = () => {
        const { parentState } = this.props;
        if (parentState.checked.length < 1) {
            Notification.info({
                title: "Aviso",
                description: "Não é possível duplicar sem nenhum item selecionado!"
            });
            return;
        }
        this.setState({ showDialogModal: true });
    }

    render(): React.ReactNode {
        return (
            <div className="col-md-12 mt-4 d-flex">
                <Button
                    className="ml-2"
                    color="blue"
                    onClick={this.openCloneModal}
                    style={
                        {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5
                        }
                    }
                    loading={this.state.loading}
                >
                    <i className="fas fa-clone"></i>
                    Duplicar Item
                </Button>

                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            showDialogModal: false,
                        })
                    }}
                    funcaoclose={() => {
                        this.setState({
                            showDialogModal: false,
                        })
                    }}
                    body="Ao duplicar este registro o mesmo já será gravado. Deseja continuar?"
                    content=""
                    showConfirm={true}
                    confirmColor="green"
                    cancelText={"Cancelar"}
                    onConfirm={() => this.cloneProducts()}
                    onCloseButton={true}
                    title={<>
                        <Icon
                            icon="remind"
                            style={{
                                color: '#ffb300',
                                fontSize: 18,
                                marginTop: 6
                            }}
                        />
                        &nbsp;Atenção!
                    </>}
                    show={this.state.showDialogModal}
                />
            </div>
        )
    }
}

export class ProductBrowseEvents {
    onRenderFooterBrowserlize = (props: any, state: any) => {
        return <ProductComponent parentProps={props} parentState={state} />;
    }
}