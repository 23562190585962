import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'rsuite';
import ChatAudioPlayer from './ChatAudioPlayer';

const AudioRecorder: React.FC<any> = (props: any) => {
    const [recording, setRecording] = useState(false);
    const [audioURL, setAudioURL] = useState<string | null>(null);
    const [seconds, setSeconds] = useState(0);
    const mediaRecorder = useRef<MediaRecorder | null>(null);
    const audioChunks = useRef<Blob[]>([]);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    useEffect(() => {
        if (props.action === "start") {
            setAudioURL(null);
            startRecording();
        } else if (props.action === "stop") {
            stopRecording();
        }
    }, [props.action]);

    useEffect(() => {
        stopRecording();
        setAudioURL(null);
    }, [props.reset])

    useEffect(() => {

        props.setAudio(audioURL);

    }, [audioURL]);

    const startRecording = async () => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            alert('Seu navegador não suporta gravação de áudio');
            return;
        }

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorder.current = new MediaRecorder(stream);
        mediaRecorder.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
                audioChunks.current.push(event.data);
            }
        };
        mediaRecorder.current.onstop = () => {
            const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
            const audioUrl = URL.createObjectURL(audioBlob);
            setAudioURL(audioUrl);
            audioChunks.current = [];
        };

        mediaRecorder.current.start();
        setRecording(true);
        setSeconds(0);
        intervalRef.current = setInterval(() => {
            setSeconds((prevSeconds) => prevSeconds + 1);
        }, 1000);
    };

    const stopRecording = () => {
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
            setRecording(false);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
                intervalRef.current = null;
            }
        }
    };

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);

    return (
        <div style={{ display: (!audioURL && !recording) ? "none" : "flex", flexDirection: "row", gap: 6, margin: "auto",width:"100%" }}>
            {recording && <span>Gravando Audio... {seconds} segundos</span>}
            {audioURL && (
                <>
                    {/* <audio style={{width:"70%"}} src={audioURL} controls /> */}
                    <ChatAudioPlayer url={audioURL}/>
                    <Button style={{ marginLeft: 10 }} onClick={() => setAudioURL(null)} appearance='primary' color='red'>
                        <i className="fas fa-times"></i> Cancelar
                    </Button>
                </>
            )}
        </div>
    );
};

export default AudioRecorder;
