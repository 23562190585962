import { Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { Event } from "../../Event";

export class ProjectProductAppointmentFkStepIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'project_product_appointment_fk_step_id',
            'project_product_appointment_fk_user_id'
        ]);

        const values = this.globalProps.values;
        const stepAppointment = values.project_product_appointment_fk_step_id;
        const projectProductAppointment = values.project_product_appointment_fk_project_product_id
        this.setField('project_product_appointment_fk_user_id', {
            api: {
                method: "GET",
                url: `/api/v1/users/select?${stepAppointment ? `step_id=${stepAppointment}` : ''}${projectProductAppointment ? `&project_product_id=${projectProductAppointment}` : ''}`,
                uses: [
                    {
                        type: "prop",
                    }
                ],
            }          
        });

        if (value) {
            axios
                .get("/api/v1/appointment/previous-dependent-appointment", {
                    params: {
                        project_product_id: this.globalProps.values.project_product_appointment_fk_project_product_id,
                        step_id: Number(value)
                    }
                })
                .then(async (res) => {
                    if (res && res.data) {
                        // console.log("debugss", res.data)
                        const { dfa, dependentStep } = res.data;
                        if (dfa) {
                            this.setFieldValue('project_product_appointment_fk_step_id', );
                            Notification.info({ title: "Aviso", description: `Você precisa concluir a fase anterior (${dependentStep}) antes de prosseguir com o apontamento da fase selecionada.`, duration: 10000 });
                        }
                    }
                })
                .catch((e) => {
                    Notification.error(e);
                })
                .finally(() => {
                    this.setState('loadings', []);
                });
        } else {
            this.setField('project_product_appointment_fk_user_id', {
                api: {
                    method: "GET",
                    url: `/api/v1/users/select`,
                    uses: [
                        {
                            type: "prop",
                        }
                    ],
                }
            });
            setTimeout(() => {
                this.setState('loadings', []);
            }, 1000);
        }
    }
}