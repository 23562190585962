import React from "react";
import { Panel } from "rsuite";
import { UserContext } from "../../components/authProvider/main";
import KanbanPage from "./KanbanPage";
import { Helmet } from "react-helmet";

export class KanbanIndex extends React.Component {
    static contextType = UserContext;

    static canUseKanban(key: string, user: { role: { role_permissions: { role_permission_value: any; }[]; role_id: number; }; }) {
        if (user?.role?.role_permissions) {
            if (user.role.role_id === 2) {
                return true;
            }
            const perms = user.role.role_permissions.map((item: { role_permission_value: any; }) => item.role_permission_value);
            return perms.includes(key);
        }
        return false;
    }

    render() {
        const user = this.context.data;
        // const isBrowse = KanbanIndex.canUseKanban("kanban", user);
        const isRead = KanbanIndex.canUseKanban("kanban", user) || KanbanIndex.canUseKanban("kanban", user);
        const isEdit = KanbanIndex.canUseKanban("kanban", user);

        const marginLeft = localStorage.getItem("hiddenSidebar") === "true" ? "0px" : "203px";

        // if (isBrowse) {
        //     return (
        //         <div className="container" style={{ background: "white", marginLeft }}>
        //             <Panel style={{ width: "100%" }}>
        //                 <KanbanPage />
        //             </Panel>
        //         </div>
        //     );
        // }

        if (isRead || isEdit) {
            return (
                <>
                    <Helmet>
                        <title>FastFlow - Kanban por Status</title>
                    </Helmet>
                    <div className="container" style={{ background: "white", marginLeft }}>
                        <Panel style={{ width: "100%" }}>
                            <KanbanPage companyId={user.user_fk_company_id} />
                        </Panel>
                    </div>
                </>
            );
        }

        return (
            <></>
        );
    }
}