import dayjs from "dayjs";
import { Event } from "../../Event";
import axios from "../../../utilities/axios";

export class ProjectProductAppointmentFinishEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        const formatDate = (dateStr: string) => {
            const day = dateStr.slice(0, 2);
            const month = dateStr.slice(2, 4);
            const year = dateStr.slice(4, 8);
            const hour = dateStr.slice(8, 10);
            const minute = dateStr.slice(10, 12);
            const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;

            return formattedDate;
        }

        if (value) {
            this.setState('loadings', [
                'project_product_appointment_total_appointment_time',
                'project_product_appointment_fk_user_id',
                'project_product_appointment_status',
                'project_product_appointment_init'
            ]);

            // console.log("diff data", this.globalProps.values)
            const values = this.globalProps.values;

            if (values.project_product_appointment_init && values.project_product_appointment_finish) {
                const initDate = dayjs(values.project_product_appointment_init);
                const finishDate = dayjs(values.project_product_appointment_finish);

                const durationInSeconds = finishDate.diff(initDate, 'second');

                const hours = Math.floor(durationInSeconds / 3600);
                const minutes = Math.floor((durationInSeconds % 3600) / 60);
                const seconds = durationInSeconds % 60;

                const padZero = (num: number) => String(num).padStart(2, '0');

                const durationString = `${padZero(hours)}h${padZero(minutes)}m${padZero(seconds)}s`;

                this.setFieldValue('project_product_appointment_total_appointment_time', durationString);
                this.setFieldValue('project_product_appointment_status', "FINALIZADO");
            }

            // console.log("globalProps", this.globalProps.isEdit)
            // if (typeof this.globalProps.isEdit === "number") {
            //     axios
            //         .get("/api/v1/me")
            //         .then((res) => {
            //             this.setFieldValue('project_product_appointment_fk_user_id', res.data.user.user_id);
            //         });
            // }

            setTimeout(() => {
                this.setState('loadings', []);
            }, 1000);
        }
    }
}