import { Event } from "../../Event";

export class ProjectProductAppointmentFkUserIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'project_product_appointment_fk_step_id',
        ]);

        const values = this.globalProps.values;
        const userAppointment = values.project_product_appointment_fk_user_id
        const projectProductAppointment = values.project_product_appointment_fk_project_product_id
        const stepAppointment = values.project_product_appointment_fk_step_id
        this.setValue('project_product_appointment_fk_step_id', stepAppointment);
        this.setField('project_product_appointment_fk_step_id', {
            api: {
                method: "GET",
                url: `/api/v1/steps/select?${projectProductAppointment ? `project_product_id=[${projectProductAppointment}]` : ''}${userAppointment ? `&user_id=${userAppointment}` : ''}`,
                uses: [
                    {
                        type: "prop",
                    }
                ],
            }
        });
        setTimeout(() => {
            this.setState('loadings', []);
        }, 1000);
    }
}