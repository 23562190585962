import React from "react";
import { Avatar, Button, Container, Content, Modal, Notification, Panel, PanelGroup, Placeholder } from "rsuite";
import axios from "../../../../../utilities/axios";
import { HeaderComponent } from "../components/HeaderComponent";
import { ModalInfoChatStatus } from "../components/ModalInfoChatStatus";
import { AxiosError, AxiosResponse } from "axios";
import { ChatClientRender } from "../components/ChatClientRender";
import { ChatList } from "./ChatList";


interface ReadListItemsProps {
    nextRouter: ({ route }: { route: string, paramsRoute: Record<string, any> }) => void;
    previousRoute: ({ route, dataParams }: { route: string, dataParams: Record<string, any> }) => void;
    paramsRoute: Record<string, any>;
}

interface ReadListItemsState {
    data: [],
    showModal: boolean,
    modalInfoData: [],
    loading: boolean,
    chatClients: any[]
}

export class ReadListItems extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: [
            ],
            showModal: false,
            modalInfoData: [],
            loading: false,
            chatClients: [],
            expandedPanel: this.props.match?.params?.projectProductId ? this.props.match?.params?.projectProductId : this.props.projectProductId || null
        }
        this.selectChatClient = this.selectChatClient.bind(this);
        this.handlePanelToggle = this.handlePanelToggle.bind(this);
    }

    componentDidMount(): void {
        let projectId = this.props?.match?.params?.projectId;
        projectId = projectId ? projectId : this.props.projectId;

        this.setState({ loading: true });
        axios.get(`/api/v1/projects/${projectId}`)
            .then(async (response) => {
                if (response.data?.redirect) {
                    this.props.history.push("/dashboard/project/chats");
                    Notification.warning({
                        title: "Usuário sem permissão de acesso!"
                    });
                }
                this.handleProjectItem(response.data.item);
            })
            .catch((e) => {
                // Notification.error(
                //     {
                //         title: 'Ops, parece que algo deu errado!',
                //         description: e.response.data.message
                //     }
                // )
            })
            .finally(() => {
                this.setState({ loading: false })
            });

        axios
            .get(`/api/v1/project_products/${projectId}/get`)
            .then((response) => {
                this.setState({ data: response.data.item })
            });
    }

    handleOpenInfo = ({ item }: { item: Record<string, any> }) => {
        this.setState({ loading: true })

        axios.get(`/api/v1/steps/${item.project_product_id}/${item.project_product_fk_product_id}/get`)
            .then((response) => {
                this.setState({ modalInfoData: response.data.item })
            })
            .finally(() => {
                this.setState({ loading: false, showModal: true })
            })
    }

    handlePanelToggle = (data: any) => {
        let projectId = this.props?.match?.params?.projectId;
        projectId = projectId ? projectId : this.props.projectId;

        if (this.state.expandedPanel == data.project_product_id) {
            this.setState({ expandedPanel: null });
            if (this.props.history) {
                this.props.history.push(`/dashboard/project/chats/${projectId}`);
            }

            if (this.props.paramsRoute) {
                const filteredRoutes = this.props.paramsRoute?.pathUrl?.filter((route: any) => route?.labelCrud !== "Itens");
                this.props.paramsRoute.pathUrl = filteredRoutes;
            }

            if (this.props.nextRouter) {
                this.props.nextRouter(
                    {
                        route: 'chatList',
                        paramsRoute: {
                            project_product_id: data.project_product_id,
                            project_product_fk_product_id: data.project_product_fk_product_id,
                            project_id: this.props.paramsRoute.project_id,
                            product_id: data.product_id,
                            pathUrl: [
                                ...this.props?.paramsRoute?.pathUrl,
                                // {
                                //     labelCrud: 'Itens',
                                //     labelValue: data.product_name,
                                //     link: `/dashboard/project/chats/${projectId}/${data.project_product_id}`,
                                //     value: 1
                                // }
                            ],
                            data
                        }
                    }
                )
            }
        } else {
            this.setState({ expandedPanel: data.project_product_id });
            if (this.props.history) {
                this.props.history.push(`/dashboard/project/chats/${projectId}/${data.project_product_id}`);
            }

            if (this.props.paramsRoute) {
                const filteredRoutes = this.props.paramsRoute?.pathUrl?.filter((route: any) => route?.labelCrud !== "Itens");
                this.props.paramsRoute.pathUrl = filteredRoutes;
            }

            if (this.props.nextRouter) {
                this.props.nextRouter(
                    {
                        route: 'chatList',
                        paramsRoute: {
                            project_product_id: data.project_product_id,
                            project_product_fk_product_id: data.project_product_fk_product_id,
                            project_id: this.props.paramsRoute.project_id,
                            product_id: data.product_id,
                            pathUrl: [
                                ...this.props?.paramsRoute?.pathUrl,
                                {
                                    labelCrud: 'Itens',
                                    labelValue: data.product_name,
                                    link: `/dashboard/project/chats/${projectId}/${data.project_product_id}`,
                                    value: 1
                                }
                            ],
                            data
                        }
                    }
                )
            }
        }
    };

    handleProjectItem = (data: any) => {
        const baseURL = "/dashboard/project/chats";

        this.props.nextRouter({
            route: 'products',
            paramsRoute: {
                project_id: data.project_id,
                pathUrl: [{
                    labelCrud: 'Projeto',
                    labelValue: data.project_code + " - " + data.project_description + " - " + data.people.person_name,
                    link: `${baseURL}/${data.project_id}`,
                    value: 0
                }],
                data
            }
        });
    }

    selectChatClient = ({ data }: { data: Record<string, any> }) => {
        this.props.history.push(this.props.location.pathname + "/chat-client");
        this.props.nextRouter(
            {
                route: 'chatPage',
                paramsRoute: {
                    step_item: {
                        ...data.steps[0]
                    },
                    step_id: data.steps[0].step_id,
                    project_id: this.props.paramsRoute.project_id,
                    pathUrl: [
                        ...this.props?.paramsRoute?.pathUrl,
                        {
                            labelCrud: 'Chats',
                            labelValue: data.steps[0].step_name,
                            value: 2
                        }
                    ]
                }
            }
        )
    }

    renderProjects = () => {
        return this.state.data.map((value: any, index: number) => {
            const isExpanded = this.state.expandedPanel == value.project_product_id;
            return (
                <>
                    <Panel
                        defaultExpanded={isExpanded}
                        eventKey={index}
                        key={index}
                        expanded={isExpanded}
                        collapsible
                        // onExit={() => this.deselectItem({ data: value })}
                        header={
                            <>
                                <div
                                    style={
                                        {
                                            width: '100%',
                                            minHeight: '4rem',
                                            // borderRadius: 10,
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            backgroundColor: '#131047',
                                            cursor: 'pointer'
                                        }
                                    }
                                    onClick={() => this.handlePanelToggle(value)}
                                >
                                    <div
                                        style={
                                            {
                                                width: '8%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }
                                        }
                                    >
                                        <i className="far fa-box fa-2x " style={{ color: '#fff' }}></i>
                                        <p
                                            style={{ color: "#fff" }}
                                        >
                                            Chats
                                        </p>
                                    </div>
                                    <div
                                        style={
                                            {
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: "center",
                                                flexWrap: 'wrap',
                                                background: '#f5f5f5',
                                                paddingLeft: 10,
                                                // borderRadius: 10,
                                                // alignItems: "center",
                                            }
                                        }
                                    >
                                        <div style={{ display: "flex", flexDirection: "row" }}>

                                            <Avatar
                                                src={`http://${window.location.host.split('25565').join('25567')}${value.product_file[0]?.file_path}`}
                                                circle
                                            />

                                            <div style={{ display: "flex", flexDirection: "column", marginLeft: 12 }}>

                                                <label>Item</label>

                                                <h6>
                                                    {value.product_name}
                                                </h6>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </>
                        }>
                        <ChatList
                            {...this.props}
                            nextRouter={this.props.nextRouter}
                            previousRoute="chats"
                            disableHeader
                            projectId={value.project_product_fk_project_id}
                            projectProductId={value.project_product_id}
                            paramsRoute={this.props.paramsRoute}
                        />
                    </Panel>
                </>
            )
        })
    }

    render(): React.ReactNode {
        return (
            <>
                {!this.state.loading ? (
                    <>
                        {!this.props.disableHeader ? (
                            <HeaderComponent
                                {...this.props}
                                previousRoute="chats"
                                dataParams={this.props.paramsRoute}
                            />
                        ) : (
                            <></>
                        )}
                    </>
                ) : (
                    <Placeholder.Paragraph rows={2} active />
                )}
                <Content
                    style={
                        {
                            paddingTop: 10,
                            justifyContent: 'center',
                            alignContent: 'center'
                        }
                    }
                >
                    <div
                        style={
                            {

                                margin: 'auto',
                            }
                        }
                    >
                        <div
                            style={
                                {
                                    display: 'flex',
                                    gap: 15,
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    justifyContent: 'start',
                                }
                            }
                        >
                            {this.state.data.length > 0 ? (
                                <>
                                    <PanelGroup accordion bordered style={
                                        {
                                            width: '100%',
                                            minHeight: '4rem',
                                            borderRadius: 0,
                                        }
                                    }>
                                        {this.renderProjects()}
                                        {!this.props.disableChatClient ? (
                                            <Panel defaultExpanded>
                                                <ChatClientRender
                                                    {...this.props}
                                                    paramsRoute={this.props.paramsRoute}
                                                    typeCall="webUser"
                                                // selectItem={this.selectChatClient}
                                                />
                                            </Panel>
                                        ) : (
                                            <></>
                                        )}
                                    </PanelGroup>
                                </>
                            ) : (
                                <>
                                    <Placeholder.Paragraph rows={12} active />
                                </>
                            )}
                        </div>
                    </div>
                </Content>
            </>
        )
    }
}