import dayjs from "dayjs";
import { Event } from "../../Event";
import axios from "../../../utilities/axios";

export class ProjectProductAppointmentInitEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            this.setState('loadings', [
                'project_product_appointment_total_appointment_time',
                'project_product_appointment_status',
                'project_product_appointment_finish'
            ]);

            const values = this.globalProps.values;
            if (values.project_product_appointment_init && values.project_product_appointment_finish) {
                const initDate = dayjs(values.project_product_appointment_init);
                const finishDate = dayjs(values.project_product_appointment_finish);

                const durationInSeconds = finishDate.diff(initDate, 'second');
                if (durationInSeconds < 0) {
                    this.setFieldValue('project_product_appointment_finish', null);
                    this.setFieldValue('project_product_appointment_total_appointment_time', "");
                } else {
                    const hours = Math.floor(durationInSeconds / 3600);
                    const minutes = Math.floor((durationInSeconds % 3600) / 60);
                    const seconds = durationInSeconds % 60;
    
                    const padZero = (num: number) => String(num).padStart(2, '0');
    
                    const durationString = `${padZero(hours)}h${padZero(minutes)}m${padZero(seconds)}s`;
    
                    this.setFieldValue('project_product_appointment_total_appointment_time', durationString);
                    this.setFieldValue('project_product_appointment_status', "FINALIZADO");
                }
            }

            setTimeout(() => {
                this.setState('loadings', []);
            }, 1000);
        }
    }
}