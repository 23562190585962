import React from "react";
import { Placeholder, Container, Content } from "rsuite";
import { HeaderComponente } from "../../components/chatClient/Header";
import { ChatComponentList } from "../../components/formfields/custom/chatUsers/components/ChatComponentList";
import { ChatProviderContext } from "../../components/chatClient/chatProvider/main";
export class ChatList extends React.Component<any> {
    static contextType = ChatProviderContext;

    state = {
        data: [],
        loading: true
    }


    TwentyTimesParagraphs = () => {
        // Cria um array com 20 elementos vazios
        const array = Array.from({ length: 20 });

        return (
            <>
                {array.map((_, index) => (
                    <Placeholder.Paragraph key={index} graph='square' active />
                ))}
            </>
        );
    };

    selectItem = ({ data }: { data: Record<string, any> }) => {
        const { setPathUrl } = this.context;
        setPathUrl(
            {
                path: 'chat',
                labelValue: data.steps[0].step_name
            }
        )
        const currentRouteSpliced = this.props.history.location.pathname;
        localStorage.setItem('step_item', JSON.stringify(
            {
                step_color: data.steps[0].step_color,
                step_created_at: data.steps[0].step_created_at,
                step_created_by: data.steps[0].step_created_by,
                step_fk_company_id: data.steps[0].step_fk_company_id,
                step_id: data.steps[0].step_id,
                chat_message_fk_ppgstf_id: data.steps[0].chat_message_fk_ppgstf_id,
                step_name: data.steps[0].step_name,
                step_updated_at: data.steps[0].step_updated_at,
            }
        ));
        if (data.steps[0].chat_message_fk_ppgstf_id) {
            this.props.history.push(`${currentRouteSpliced}/${data.steps[0].chat_message_fk_ppgstf_id}`);
        } else {
            this.props.history.push(`${currentRouteSpliced}`);
        }
    }

    render(): React.ReactNode {
        return (
            <>
                <Container
                    style={
                        {
                            gap: 10
                        }
                    }
                >
                    <HeaderComponente
                        title="Chats"
                        useBack={true}
                        useInfo={true}
                        onBack={() => {
                            const { setPathUrl } = this.context;
                            setPathUrl(
                                {
                                    path: '',
                                    labelValue: '',
                                    direction: 'back'
                                }
                            )
                            this.props.history.go(-1)
                        }}
                    />
                    <Content
                        style={
                            {
                                display: 'flex',
                                margin: 'auto',
                                minWidth: '50vw',
                                paddingTop: 10,
                            }
                        }
                    >
                        <ChatComponentList
                            params={
                                {
                                    project_product_fk_product_id: this.props.match.params.projectProductFkProductId,
                                    project_product_id: this.props.match.params.projectProductId
                                }
                            }
                            selectItem={this.selectItem}
                            typeCall="webPerson"
                        />
                    </Content>
                </Container>
            </>
        )
    }
}