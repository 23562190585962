import axios from "../../utilities/axios";
import { Event } from "../Event";

export class UserEvents extends Event {
  public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
    if (this.globalProps.match.url.includes('/add')) {
      this.setFieldValue('user_name', '‎');

      this.setFieldValue('user_password', '');

      this.setState("loadings", ["user_fk_role_id"])
      axios.get("/api/v1/roles/select?role_active=true").then((res) => {
        console.log({res});
        this.setField('user_fk_role_id', {
          api: null,
          options: res.data.items
        })
        this.setState("loadings", [])
      })
    }
  }
}