import { motion } from "framer-motion";
import React from "react";
import { Badge, Breadcrumb, Button, Col, Divider, Icon, IconButton, Modal, Nav, Navbar, Panel, Popover, Tooltip, Whisper } from "rsuite";
import axios from "../../utilities/axios";
import { ModuleContext } from "../authProvider/main";
import { ChatComponentList } from "../formfields/custom/chatUsers/components/ChatComponentList";
import { fadeInLeftSidebar } from "../motion/motionSettings";
import { HeaderComponent } from "../formfields/custom/chatUsers/components/HeaderComponent";
import { Link } from "react-router-dom";
import { ReadListItems } from "../formfields/custom/chatUsers/pages/ReadListItems";
import _ from "lodash";

export class FloatingAppointment extends React.Component<any, {}> {

    static contextType = ModuleContext;

    private intervalId: NodeJS.Timeout | null = null;

    public state: any = {
        showModal: false,
        isLoadingAppointments: false,
        showFloatingButton: false,
        appointments: {},
        activeTab: 0,
        activeAppointment: null
    };

    componentDidMount(): void {
        this.loadAppointments();
    }

    // componentWillUnmount(): void {
    //     if (this.intervalId) {
    //         clearInterval(this.intervalId);
    //     }
    // }

    // startInterval = () => {
    //     this.intervalId = setInterval(() => {
    //         this.updateElapsedTimes();
    //     }, 1000);
    // };

    // updateElapsedTimes = () => {
    //     const init = this.state.appointment.project_product_appointment_init;
    //     const finish = this.state.appointment?.project_product_appointment_finish;

    //     let newElapsedTime;

    //     if (init) {
    //         newElapsedTime = this.getElapsedTime(init);
    //     }
    //     if (finish) {
    //         newElapsedTime = this.getElapsedTime(finish);
    //     }

    //     this.setState({ elapsedTime: newElapsedTime });
    // };

    // componentDidUpdate(): void {
    //     this.loadAppointments();
    // }

    loadAppointments = () => {
        this.setState({ isLoadingAppointments: true });
        axios
            .get("/api/v1/appointment/current")
            .then((res) => {
                if (res && res.data) {
                    const { currentAppointments } = res.data;
                    if (currentAppointments === null) {
                        this.setState({ showFloatingButton: false });
                        return
                    }
                    const appointments = _.uniqBy(currentAppointments, "project_product_appointment_fk_project_id");
                    // console.log({ appointments });
                    let activeTabOnGoing = _.findIndex(appointments, (appointment: any) => appointment.project_product_appointment_finish === null);
                    activeTabOnGoing = activeTabOnGoing >= 0 ? activeTabOnGoing : 0;
                    // console.log({ activeTabOnGoing });
                    this.setState({
                        appointments,
                        activeTab: activeTabOnGoing,
                        showFloatingButton: true,
                        isLoadingAppointments: false,
                        activeAppointment: appointments[activeTabOnGoing]
                    });
                }
            });
    };

    handleTabSelect = (index: number) => {
        this.setState({
            activeTab: index,
            activeAppointment: this.state.appointments[index]
        }, () => {
            this.forceUpdate();
        });
    };

    render() {
        const { appointments, showFloatingButton, isLoadingAppointments, activeAppointment, activeTab } = this.state;
        // console.log({ appointment });
        // const NavLink = (props: any) => <Breadcrumb.Item target="_blank" componentClass={Link} {...props} />;

        // const init = appointments?.project_product_appointment_init;
        // const finish = appointments?.project_product_appointment_finish;
        // const currentStatusText = appointments?.project_product_appointment_status;

        // console.log({ activeAppointment });

        if (showFloatingButton) {
            return (
                <motion.div initial="hidden" animate="visible" variants={fadeInLeftSidebar}>
                    <div className="floating-appointment">
                        <Badge>
                            <IconButton loading={isLoadingAppointments} onClick={() => this.setState({ showModal: true })} appearance="primary" icon={<Icon icon="arrow-right" />} placement="left">
                                Apontamentos
                            </IconButton>
                        </Badge>
                    </div>

                    <Modal full show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
                        {/* <Modal.Header>
                            <Modal.Title>Apontamentos</Modal.Title>
                        </Modal.Header> */}
                        <Navbar className="col-md-12 pill-tabs modal-tabs">
                            <Navbar.Body>
                                <Nav activeKey={this.state.activeTab}>
                                    {appointments.map((appointment: any, index: number) => {
                                        return (
                                            <Nav.Item
                                                onSelect={() => this.handleTabSelect(index)}
                                                key={index}
                                                eventKey={index}
                                            >
                                                {appointment.project_code} - {appointment.project_description}
                                            </Nav.Item>
                                        )
                                    })}
                                </Nav>
                            </Navbar.Body>
                        </Navbar>
                        <Modal.Body>
                            {/* <HeaderComponent
                                    previousRoute={this.props.previousRoute}
                                    dataParams={this.props.paramsRoute}
                                    routePrevious="products"
                                    showInfoButton
                                /> */}
                            {/* {appointments.map((appointment: any, index: number) => { */}
                            {/* return ( */}
                            <>
                                {/* <ChatComponentList
                                            params={{
                                                project_product_fk_product_id: appointment.project_product_fk_product_id,
                                                project_product_id: appointment.project_product_appointment_fk_project_product_id
                                            }}
                                            typeCall="webUser"
                                        />
                                        {appointments.length > 0 && (
                                            <Divider />
                                        )} */}
                                {activeAppointment && (
                                    <ReadListItems
                                        key={`appointment-${activeTab}`}
                                        disableHeader
                                        disableChatClient
                                        projectProductFkProductId={activeAppointment.project_product_fk_product_id}
                                        projectId={activeAppointment.project_id}
                                        projectProductId={activeAppointment.project_product_appointment_fk_project_product_id}
                                    />
                                )}
                            </>
                            {/* ) */}
                            {/* })} */}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={() => this.setState({ showModal: false })} appearance="subtle">
                                Fechar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </motion.div>
            );
        } else {
            return null;
        }
    }
}
