import classNames from "classnames";
import React from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router";
import { Panel, Notification, Button, Modal, Whisper, Tooltip } from "rsuite";
import { ModuleContext, UserContext } from "../../components/authProvider/main";
import { ErrorPopover } from "../../components/errorPopover/main";
import Formalize from "../../components/Formalize";
import axios from "../../utilities/axios";
// import ace from "brace";
// import "brace/mode/json";
//@ts-ignore
// import { JsonEditor as Editor } from "jsoneditor-react";
// import "jsoneditor-react/es/editor.min.css";
import PlaceholderParagraph from "rsuite/lib/Placeholder/PlaceholderParagraph";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
// import _ from "lodash";

class ChatUserPage extends React.Component<any, {}> {
    static contextType = ModuleContext;

    public state: any = {
        form: {},
        errors: {},
        values: {},
        isModalOpen: false,
        isDebugModalOpen: false,
        modalMessage: "",
        isLoading: true,
        canBlock: true,
        isFormDirty: false,
        cache: {},
        isConfirmModalOpen: false,
        mountedValues: {},
        id: this.props.match.params.id !== undefined ? this.props.match.params.id : null,
    };

    public _isMounted = false;
    public crud = 'chats';
    public isEdit = this.props.view === true ? false : this.props.match.params.id ? true : false;
    public id = this.props.match.params.id !== undefined ? this.props.match.params.id : null;
    // public mountedValues = {};
    public listener: any = null;
    public location: any = null;

    public saveCallbacks: { error: Function; success: Function; type: "callback" }[] = [];

    public addCallback = (callbackMethod: Function, errorCallbackMethod: Function = () => {}) => {
        this.saveCallbacks.push({
            success: callbackMethod,
            error: errorCallbackMethod,
            type: "callback",
        });
        return this.saveCallbacks.length - 1;
    };
    public executeSaveCallbacks = (values: any) => {
        this.saveCallbacks.forEach((callback) => {
            console.log("Executing callback", callback);
            if (typeof callback === "object" && callback.type == "callback" && typeof callback.success == "function") {
                callback.success(values);
            }
        });
        this.saveCallbacks = [];
    };
    public executeErrorSaveCallbacks = (values: any) => {
        this.saveCallbacks.forEach((callback) => {
            console.log("Executing callback", callback);
            if (typeof callback === "object" && callback.type == "callback" && typeof callback.error == "function") {
                callback.error(values);
            }
        });
        this.saveCallbacks = [];
    };

    componentDidMount() {
        this._isMounted = true;
        window.onbeforeunload = (event) => {
            if (this.state.isFormDirty === true) {
                event.preventDefault();
            }
        };
        this.listener = this.props.history.block((location: any) => {
            if (this.state.canBlock === true && this.state.isFormDirty === true) {
                // if(location.pathname)
                // console.log("TARGET",location);

                if (location.pathname !== this.props.history.location.pathname) {
                    if (location.search.includes("force=true")) {
                        return true;
                    }
                    if (this.state.isConfirmModalOpen === false) {
                        this.setState({ isConfirmModalOpen: true });
                        if (location.pathname) {
                            this.location = location.pathname;
                        }
                        // //console.log(location);
                        return false;
                    }
                }
            }
            return true;
        });

        this.loadfields();
    }

    componentWillUnmount() {
        this.listener();
        this._isMounted = false;
        window.onbeforeunload = (event) => {};
    }

    componentDidUpdate(prevProps: any) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.currentValues = {};
            this.crud = this.props.match.params.crud;
            this.isEdit = this.props.view === true ? false : this.props.match.params.id ? true : false;
            this.id = this.props.match.params.id !== undefined ? this.props.match.params.id : null;
            this.setState(
                {
                    form: {},
                    errors: {},
                    values: {},
                    isModalOpen: false,
                    isDebugModalOpen: false,
                    modalMessage: "",
                    isLoading: true,
                    canBlock: true,
                    isFormDirty: false,
                    cache: {},
                    isConfirmModalOpen: false,
                    mountedValues: {},
                    id: this.props.match.params.id !== undefined ? this.props.match.params.id : null,
                },
                () => {
                    this.loadfields();
                },
            );
        }
    }
    loadfields = () => {
        axios
            .get("/api/v1/" + this.crud + "/fields")
            .then((res) => {
                if (res.data && res.data.fields) {
                    if (this._isMounted) {
                        // console.log(res.data);
                        var stateObj = { form: res.data.fields, isLoading: true };

                        if (this.isEdit || this.props.view === true) {
                            this.loadModelData(res.data.fields);
                        } else {
                            stateObj.isLoading = false;
                        }
                        // console.log("aaaa",stateObj);
                        this.setState(stateObj);
                    }
                }
            })
            .finally(() => {
                this._isMounted = true;
            });
        
    };

    loadModelData = (fields: any) => {
        axios.get("/api/v1/" + this.crud + "/" + this.id).then((res) => {
            if (res.data && res.data.item) {
                this.setState({ id: this.id, mountedValues: res.data.item, values: res.data.item, isLoading: false });
            }
        });
    };

    public currentValues = {};
    public guid: string | null = null;
    onSubmit = (values: any, guid: string | null = null, cache: any = null, shouldContinue: boolean = false) => {
        var url = "/api/v1/" + this.crud + "/" + (this.isEdit ? this.id : "add");
        const primaryKey = this.state.form.$GLOBALS.prefix + "_id";

        if (guid) {
            this.guid = guid;
        }
        if (shouldContinue && guid === null) {
            guid = this.guid;
        }
        this.currentValues = values;
        this.setState({ isLoading: true, values, cache });
        for (var fieldName in values) {
            var value = values[fieldName];
            if (value) {
                if (fieldName === "guid") {
                } else {
                    if (this.state.form[fieldName] && this.state.form[fieldName].type === "select") {
                        if (typeof value == "object") {
                            if (value["label"] && value["value"]) {
                                values[fieldName] = value["value"];
                            }
                        }
                    }
                }
            }
        }
        var output = { ...values, guid };
        for (var i in output) {
            if (i == "psm_review_logs") {
                delete output[i];
            }
            if (i == "psm_change_logs") {
                delete output[i];
            }
        }
        var loadedValues = this.state.mountedValues;
        for (var i in loadedValues) {
            if (i == "psm_review_logs") {
                delete loadedValues[i];
            }
            if (i == "psm_change_logs") {
                delete loadedValues[i];
            }
        }
        axios
            .post(url, {
                data: output,
                loadedValues,
                continue: shouldContinue === true ? true : undefined,
            })
            .then((res) => {
                this.setState({ canBlock: false });
                Notification.success({
                    title: "Sucesso!",
                    description: "Registro " + (this.isEdit ? "atualizado" : "cadastrado") + " com sucesso!",
                    placement: "bottomEnd",
                });
                if (res.data.result && res.data.result[0] && res.data.result[0][primaryKey]) {
                    if (this.saveCallbacks?.length > 0) {
                        this.executeSaveCallbacks(res.data.result[0]);
                    }
                    this.props.history.replace("/dashboard/" + this.context.name + "/" + this.crud + "/" + res.data.result[0][primaryKey]);
                    this.isEdit = true;
                    this.id = res.data.result[0][primaryKey];
                    this.loadfields();
                } else {
                    if (this.saveCallbacks?.length > 0) {
                        this.executeSaveCallbacks(res.data.result[0]);
                    }
                    this.props.history.push("/dashboard/" + this.context.name + "/" + this.crud);
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            })
            .catch((e) => {
                // console.log("ERRORS", e.response);
                this.executeErrorSaveCallbacks({ ...values, guid });
                if (e?.response?.status) {
                    if (e.response.status === 406) {
                        if (e.response.data.errors) {
                            this.setState({ errors: e.response.data.errors });
                        }
                        if (e.response.data.messages) {
                            for (var i in e.response.data.messages) {
                                Notification.error({
                                    title: "Erro!",
                                    description: e.response.data.messages[i],
                                    placement: "bottomEnd",
                                    duration: 9000,
                                });
                            }
                        }
                    }
                    if (e.response.status === 403) {
                        for (var errorIndex in e.response.data.errors) {
                            Notification.error({
                                title: "Erro!",
                                duration: 10000,
                                description: e.response.data.errors[errorIndex],
                                placement: "bottomEnd",
                            });
                        }
                    }
                    if (e.response.status === 409) {
                        this.setState({ isModalOpen: true, modalMessage: "Houve uma alteração nesse registro enquanto você alterava, deseja mesmo continuar com a alteração ?" });
                    }
                } else {
                    throw e;
                }
            });
    };

    onConfirm = () => {
        this.listener();
        this.props.history.push(this.location);
    };

    onCancel = () => {
        // this.listener();
        this.setState({ isConfirmModalOpen: false });
    };

    onClickDebug = () => {
        this.setState({ isDebugModalOpen: true });
        // alert("CLICKED");
    };

    onCloseDebug = () => {
        this.setState({ isLoading: true }, () => {
            this.setState({ isDebugModalOpen: false, isLoading: false });
        });
    };

    renderTitleName = () => {
        var GLOBALS = this.state.form["$GLOBALS"] ? this.state.form["$GLOBALS"] : { icon: null, singular_name: null };
        var numberExibtion: any = this.state.id;
            if(this.state.values.psm_os_number){
                numberExibtion = this.state.values.psm_os_number
            }
        if (this.isEdit) {
            //console.log("Olha o estate ::: ", this.state);
            return (
                <>
                    Editando {GLOBALS.singular_name ? GLOBALS.singular_name : <ErrorPopover title="Missing singular/plural name">{this.crud}</ErrorPopover>}{/* #{numberExibtion} */}
                </>
            );
        } else {
            if (this.props.view) {
                return (
                    <>
                        Visualizando {GLOBALS.singular_name ? GLOBALS.singular_name : <ErrorPopover title="Missing singular/plural name">{this.crud}</ErrorPopover>}{/* #{numberExibtion} */}
                    </>
                );
            } else {
                return <>{GLOBALS.plural_name ? GLOBALS.plural_name : <ErrorPopover title="Missing singular/plural name">{this.crud}</ErrorPopover>}</>;
            }
        }
    };

    render() {
        var GLOBALS = this.state.form["$GLOBALS"] ? this.state.form["$GLOBALS"] : { icon: null, singular_name: null };
        return (
            <>
                <div style={{ zIndex: 1 ,marginLeft: localStorage.getItem("hiddenSidebar") == "true" ? "0px" : "203px" }} className="container">
                    <Helmet>
                        <title>FastFlow - Chats</title>
                    </Helmet>

                    <Modal show={this.state.isModalOpen} size={"sm"} className="centered">
                        <Modal.Header>
                            <Modal.Title>
                                <i className="fas fa-fw mr-2 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                                Atenção!
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>{this.state.modalMessage}</Modal.Body>
                        <Modal.Footer>
                            <Button appearance="primary" onClick={() => this.onSubmit(this.currentValues, null, null, true)}>
                                Confirmar
                            </Button>
                            <Button color="red" onClick={() => this.setState({ isModalOpen: false })}>
                                Cancelar
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Panel bordered className="rs-no-body mb-4 w-100">
                        <div className="d-flex justify-contents-between align-items-center">
                            <div></div>
                            <div>
                                <h5 className="d-flex align-items-center">
                                    <i className={classNames("fas", "fa-fw mr-4", "fa-" + GLOBALS.icon)}></i>
                                    {this.renderTitleName()}
                                </h5>
                            </div>
                            <div>
                                {/* <UserContext.Consumer>
                                    {(user: any) => {
                                        if (user.data?.user_fk_role_id === 2) {
                                            return (
                                                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">DEBUG</Tooltip>}>
                                                    <Button onClick={this.onClickDebug} className="mr-2" color={"violet"}>
                                                        <i className="fas fa-fw fa-bug"></i>
                                                    </Button>
                                                </Whisper>
                                            );
                                        }
                                        return null;
                                    }}
                                </UserContext.Consumer>
                                <Whisper trigger="hover" placement="bottom" speaker={<Tooltip placement="bottom">Voltar</Tooltip>}>
                                    <Button className="ml-2" color={"blue"} onClick={() => this.props.history.push("/dashboard/" + this.context.name + "/" + this.crud)}>
                                        <i className="fas fa-fw fa-angle-left"></i>
                                    </Button>
                                </Whisper> */}
                            </div>
                        </div>
                    </Panel>

                    {this.state.isLoading === false && (
                        <Formalize
                            addCallback={this.addCallback}
                            onDirty={(name: string) => {
                                this.setState({ isFormDirty: true });
                            }}
                            cache={this.state.cache}
                            history={this.props.history}
                            match={this.props.match}
                            readOnly={this.props.view === true}
                            isEdit={this.isEdit}
                            values={this.state.values}
                            errors={this.state.errors}
                            onSubmit={this.onSubmit}
                            form={this.state.form}
                        />
                    )}
                    {/* {JSON.stringify(this.state.errors)} */}
                    {this.state.isLoading === true && (
                        <Panel bordered className="rs-no-body mb-4 w-100">
                            <PlaceholderParagraph rows={15} active />
                        </Panel>
                    )}

                    <Modal size={"lg"} overflow={false} show={this.state.isDebugModalOpen}>
                        <Modal.Header onHide={() => this.setState({ isDebugModalOpen: false })}>Editor DEBUG</Modal.Header>
                        <Modal.Body>
                            {/* <Editor htmlElementProps={{ style: { height: 400 } }} ace={ace} mode={"code"} value={this.state.form} onChange={(form: any) => this.setState({ form })} /> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button color="green" onClick={this.onCloseDebug}>
                                <i className="fas fa-fw fa-check"></i> Confirmar
                            </Button>
                            <Button color="red" onClick={() => this.setState({ isDebugModalOpen: false })}>
                                <i className="fas fa-fw fa-times"></i> Cancelar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* <ConfirmModal onCancel={this.onCancel} onConfirm={this.onConfirm} show={this.state.isConfirmModalOpen} /> */}
                </div>
            </>
        );
    }
}

 export default withRouter(ChatUserPage);