import _ from "lodash";
import React from "react";
import { InputWithTags } from "./notification/InputWithTags";
export class DefinitionNotificationCustom extends React.Component<any, {}> {
    onChange = (values: string) => {
        if (this.props.onChange) {
            this.props.onChange(values)
        }
    }

    render(): React.ReactNode {
        switch (this.props.name) {
            case "definition_project_status_change":
                return (
                    <InputWithTags
                        variableList={["CLIENTE", "CÓD. PROJETO", "DESCR_PROJETO", "STATUS"]}
                        label='Alteração de status do projeto'
                        value={this.props.value}
                        defaultValue={"Olá [CLIENTE], seu projeto: [CÓD. PROJETO] - [DESCR_PROJETO] foi [STATUS]"}
                        onChange={this.onChange}
                    />
                )
                break;
            case "definition_phase_status_change":
                return (
                    <InputWithTags
                        variableList={["CLIENTE", "CÓD. PROJETO", "DESCR_PROJETO", "STATUS", "STATUS_FASE_ANTERIOR", "STATUS_FASE_ATUAL", "FASE"]}
                        label='Alteração de status da fase'
                        value={this.props.value}
                        defaultValue={"Olá [CLIENTE], seu projeto: [CÓD. PROJETO] - [DESCR_PROJETO] foi de: [STATUS_FASE_ANTERIOR] para: [STATUS_FASE_ATUAL] na fase [FASE]"}
                        onChange={this.onChange}
                    />
                )
                break;
            case "definition_new_message":
                return (
                    <InputWithTags
                        variableList={["CLIENTE", "CÓD. PROJETO", "DESCR_PROJETO", "STATUS"]}
                        label='Nova mensagem'
                        value={this.props.value}
                        defaultValue={"Olá [CLIENTE], seu projeto: [CÓD. PROJETO] - [DESCR_PROJETO] possui nova(s) mensagen(s), para responder clique no link abaixo:"}
                        onChange={this.onChange}
                    />
                )
                break;
        }
    }
}
