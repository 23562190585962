import _ from "lodash";
import React from "react";
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from "react-dnd-html5-backend";
import { Button, Checkbox, Loader, Modal, Notification, Panel, Table, CheckTreePicker as RSuiteCheckTreePicker, Whisper, Tooltip } from "rsuite";
import axios from "../../../utilities/axios";
import Formalize from "../../Formalize";
import { SchemaToParsedCell } from "../../customTable/SchemaToParsedCell";
import { NumberInput } from "../NumberInput";
import { Link } from "react-router-dom";
import { DatePickerComponent } from "../DatePicker";
import dayjs from "dayjs";
import CurrencyInput from "react-currency-input-field";

const { Column, HeaderCell, Cell } = Table;
//config
const custom_name = 'project_product_group_step_table_flows';

function DraggableCell({ children, onDrag, id, rowData, columnIdName, ...rest }: any) {
    const ref = React.useRef(null);
    const ItemTypes = {
        COLUMN: 'column',
        ROW: 'row'
    };



    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ItemTypes.ROW,
        collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        drop(item: any, monitor: any) {
            onDrag && onDrag(item[`${columnIdName}`], rowData[`${columnIdName}`]);
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ROW,
        item: { [`${columnIdName}`]: rowData[`${columnIdName}`] },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging()
        })
    });

    const opacity = isDragging ? 0 : 1;
    const isActive = canDrop && isOver;

    drag(drop(ref));

    const styles: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        border: '1px dashed gray',
        padding: '0.5rem 1rem',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
        background: isActive ? '#ddd' : '',
        justifyContent: 'center',
    };

    return (
        <Cell {...rest} style={{ padding: 0 }}>
            <div ref={ref} style={styles}>
                {children}

            </div>
        </Cell>
    );
}

function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}



export class ProjectProductGroupStepTableFlowsCustom extends React.PureComponent<any, {}> {

    public state: any = {
        isLoading: true,
        isRead: false,
        isModalOpen: false,
        isSettingsModalOpen: false,
        isEdit: null,
        form: {},
        totalWidth: 0,
        hiddenRows: [],
        selectedValues: {},
        columnOrder: "[]",
        items: [],
        isConfirmDeleteModal: false,
        searchs: {},
        forceReloadTable: -1,
        weekDays: []
    };

    private eventHandler: any | null = null;
    public elementRef: any = null;
    public cachedApiItems: any = {};

    onStartLoading = () => {
        axios.get('/api/v1/custom/checktreepicker/initial/select', {
            params: {
                crud: 'user_restricts',
                relationTable: 'users',
                relationTableId: this.props.values.user_id,
                paramsWhere: [
                    {
                        user_fk_project_id: this.props.values.user_fk_project_id
                    }
                ]
            }
        })
            .then((res: any) => {
                var arr: any = [...res.data.items];
                // if (value) {
                //     arr = this.find(value, arr);
                //     //(arr);
                // }
                this.setState({ data: arr, loading: false });
            })
    }

    onLoadingOptions = () => {
        this.setState({ isLoading: true });
        // console.log("i'm herereroiew jroijr ::", this.props)
        axios.get('/api/v1/users/restrict/select', {
            params: {
                project_users: this.props.parentValues.project_users,
                user_id: this.props.values?.user_id ? this.props.values.user_id : null
            }
        })
            .then((response) => {
                this.setState({ data: response.data.items })
            })
            .catch((err) => {
                Notification.info(
                    {
                        title: 'Info',
                        description: err.response.data.message
                    }
                )
                this.setState({ data: [] })
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }


    onConfirmDelete = async (rowData: any, rowIndex: any, currentTable: any) => {
        // console.log("CLICOU", rowData, rowIndex, currentTable)
        axios.post("/api/v1/perm-delete", { isCustom: true, customName: custom_name, id: rowData['ihm_service_performed_id'], currentTable: currentTable }).then((res) => {
            Notification.error({
                title: "Delete",
                description: "Item Deletado",
            });
        });

        var items = this.state.items;
        var merge = { ihm_fault_phrases: this.state.items, ihm_service_performeds: this.state.items }
        items.splice(rowIndex, 1);
        this.setState({ items, isLoading: true }, () => {
            if (this.props.onChange) {
                this.props.onChange(merge);
            }
            if (this.props.onDelete) {
                this.props.onDelete(merge);
            }
            this.setState({ isLoading: false, isConfirmDeleteModal: false });
            this.setState({ tempDeleteItemID: null, tempDeleteItem: null, tempDeleteTable: null });
        });
    };

    onClickDelete = async (rowIndex: any, rowData: any) => {
        this.setState({ isConfirmDeleteModal: true, tempDeleteItemID: rowIndex, tempDeleteItem: rowData, tempDeleteTable: custom_name });
    };

    renderActions = (rowData: any, rowIndex: any) => {
        const extras = this.eventHandler?.getActions ? this.eventHandler?.getActions(rowData) : null;

        return (
            <div style={{ display: "flex" }}>
                {this.props.readOnly !== true && this.props.canDelete !== false && (
                    <i onClick={() => this.onClickDelete(rowIndex, rowData)} className="fas text-danger fa-fw mr-2 clickable fa-trash"></i>
                )}
                {extras}
                <Modal show={this.state.isConfirmDeleteModal}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>
                            <i className="fas fa-fw mr-2 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                            Atenção!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {this.state.isLoading && <Loader size="md" />}
                        {!this.state.isLoading && <>Deseja mesmo excluir esse registro ? o registro não poderá ser restaurado!</>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={this.state.isLoading} onClick={() => this.onConfirmDelete(this.state.tempDeleteItem, this.state.tempDeleteItemID, this.state.tempDeleteTable)} appearance="primary">
                            <i className="fas fa-check fa-fw mr-2"></i>Confirmar
                        </Button>
                        <Button disabled={this.state.isLoading} color="red" onClick={() => this.setState({ isConfirmDeleteModal: false })}>
                            <i className="fas fa-times fa-fw mr-2"></i>Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
                {/* {JSON.stringify(this.eventHandler)} */}
            </div>
        );
    };

    openAdd = () => {
        this.setState({ isRead: false, isModalOpen: true, isEdit: null, selectedValues: {} });
    };

    componentWillUnmount(): void {
        this.props.onChange();
    }

    componentDidMount() {
        const productId = this.props.values.project_product_fk_product_id
        const projectProductId = this.props.values.project_product_id
        // console.log(this.props.parentValues?.project_products);
        // console.log(this.props.values);
        
        let ppgstfItems = (Array.isArray(this.props.parentValues?.project_products) && this.props.parentValues?.project_products.length > 0) 
          ? this.props.parentValues.project_products[0].project_product_group_step_table_flows 
          : null;
        ppgstfItems = ppgstfItems ?? this.props.values?.project_product_group_step_table_flows;
        
        if (typeof ppgstfItems == "string") {
            try {
                let converted = JSON.parse((`[${ppgstfItems}]`)
                    .replaceAll('\\', '')
                    .replaceAll('"{', '{').replaceAll('}"', '}')
                    .replace("[{{", "[{").replace("}}]", "}]")
                )
                if (converted) {
                    ppgstfItems = converted
                }
            } catch (err) {
                ppgstfItems = null
            }
        }
        // console.log({ppgstfItems});

        if (Array.isArray(ppgstfItems) && ppgstfItems.length > 0) {
            this.setState({ items: ppgstfItems });
        } else {
            ppgstfItems = null
        }

        const flow_id = productId;
        if (!ppgstfItems && projectProductId) {
            axios
                .get(`/api/v1/custom/clone-flow-phases/${projectProductId}`)
                .then((res) => {
                    if (res && res.data) {
                        const items = res.data?.items;
                        // console.log("fases para clonar", res.data)

                        if (items.length > 0) {
                            this.setState({ items: items });
                        }
                    }
                });
        }

        if (!ppgstfItems && productId) {
            this.setState({ isLoading: true });
            axios
                .get(`/api/v1/products/${flow_id}`)
                .then(async (res) => {
                    if (res && res.data) {
                        const group_id = res.data?.item.product_fk_group_id;
                        // console.log({ group_id })

                        if (group_id) {
                            axios
                                .get(`/api/v1/groups/${group_id}`)
                                .then((res) => {
                                    if (res && res.data) {
                                        const item = res.data?.item;
                                        // console.log("fases para clonar", res.data)

                                        if (item.group_step_tables && item.group_step_tables.length > 0) {
                                            const newGroupStepTableFlows = item.group_step_tables.map((table: any) => ({
                                                project_product_group_step_table_flow_fk_step_id: table.group_step_table_fk_step_id,
                                                project_product_group_step_table_flow_fk_product_id: flow_id,
                                                project_product_group_step_table_flow_fk_group_id: group_id,
                                                guid: uuidv4(),
                                                steps: table.steps,
                                                project_product_group_step_table_flow_order_by: table.group_step_table_order_by
                                            }));

                                            this.setState({ items: newGroupStepTableFlows });
                                            this.props.values.project_product_group_step_table_flows = newGroupStepTableFlows;
                                            if (Array.isArray(this.props.parentValues?.project_products) && this.props.parentValues.project_products.length > 0) {
                                                this.props.parentValues.project_products[0] = {...this.props.parentValues.project_products[0], project_product_group_step_table_flows: newGroupStepTableFlows}
                                            } else {
                                                this.props.parentValues.project_products = [{project_product_group_step_table_flows: newGroupStepTableFlows}]
                                            }
                                            
                                            // console.log("fases clonadas", this.globalProps.values.project_product_group_step_table_flows);
                                        }
                                    }
                                }).finally(() => {
                                    this.setState({ isLoading: false });
                                });
                        }
                    }
                })
        }

        axios.get("/api/v1/definitions/list").then((res) => {
            const definition = res.data.items[0] ?? null
            // console.log("definitions ::>", definition);
            if (definition) {
                    axios.get(`/api/v1/definition_week_days/select?definition_id=${definition.definition_id}`)
                        .then((week) => {
                            let weekDays: any[] = week.data.items
                            weekDays = weekDays.length > 0 ? weekDays.map((day: any) => Number(day.label)-1) : [0, 1, 2, 3, 4, 5, 6]
                            this.setState({ weekDays: weekDays });
                            // console.log("definition_week_days ::>", weekDays);
                        })
                        .catch((err) => {
                            console.error("Erro ao carregar informações de dias úteis ::> ", err)
                            this.setState({ weekDays: [0, 1, 2, 3, 4, 5, 6] })
                        })
                        .finally(() => {
                            const isAllExpectedInitialNull = this.state.items?.every((item: any) => item.project_product_group_step_table_flow_expected_initial_date === null)
                            if (isAllExpectedInitialNull) {
                                this.calculateDatesAndDays();
                            }
                        })
                }
            })

        this.elementRef = React.createRef();
        var api = "project_product_group_step_table_flows";
        axios
            .get("/api/v1/" + api + "/fields")
            .then((res) => {
                if (res && res.data && res.data.fields) {
                    var fields = res.data.fields;
                    if (this.props.formOverrides) {
                        for (var i in this.props.formOverrides) {
                            fields[i] = { ...fields[i], ...this.props.formOverrides[i] };
                        }
                    }
                    this.setState({ form: fields });
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });

        this.onLoadingOptions();
    }

    sortedFlows(items: any) {
        return items?.sort((a: any, b: any) => {
            return a?.project_product_group_step_table_flow_order_by - b?.project_product_group_step_table_flow_order_by;
        }).map((item: any, index: any) => ({
            ...item,
            project_product_group_step_table_flow_order_by: index + 1
        }));
    };

    componentDidUpdate(prevProps: any) {
        const sortedItems = this.sortedFlows(this.state.items);
        this.props.values.project_product_group_step_table_flows = sortedItems;
        if (Array.isArray(this.props.parentValues?.project_products) && this.props.parentValues.project_products.length > 0) {
            this.props.parentValues.project_products[0] = {...this.props.parentValues.project_products[0], project_product_group_step_table_flows: sortedItems}
        } else {
            this.props.parentValues.project_products = [{project_product_group_step_table_flows: sortedItems}]
        }
    }

    reOpenModal = () => {
        setTimeout(this.openAdd, 1000);
    }
    onSubmit = async (values: any) => {
        const { items, isEdit } = this.state;
        let updatedItems = [...items || []];

        if (isEdit !== null) {
            updatedItems[isEdit] = values;
        } else {
            updatedItems.push(values);
        }

        const lastIndex = updatedItems.length - 1;
        const lastItem = updatedItems[lastIndex];

        try {
            const response = await axios.get(`/api/v1/steps/${lastItem?.project_product_group_step_table_flow_fk_step_id}`);
            if (response && response.data) {
                const step = response.data.item;
                updatedItems[lastIndex] = {
                    ...lastItem,
                    project_product_group_step_table_flow_order_by: updatedItems.length,
                    project_product_group_step_table_flow_fk_project_product_id: updatedItems[0].project_product_group_step_table_flow_fk_project_product_id,
                    project_product_group_step_table_flow_fk_product_id: updatedItems[0].project_product_group_step_table_flow_fk_product_id,
                    project_product_group_step_table_flow_fk_group_id: updatedItems[0].project_product_group_step_table_flow_fk_group_id,
                    steps: step,
                };

                this.setState(
                    {
                        items: updatedItems,
                        isModalOpen: false
                    },
                    () => {
                        const mergeDados = {
                            ihm_fault_phrases: updatedItems,
                            ihm_service_performeds: updatedItems
                        };

                        if (this.props.onChange) {
                            this.props.onChange(mergeDados);
                        }

                        if (isEdit !== null) {
                            if (this.props.onEdit) {
                                this.props.onEdit(values, updatedItems.length);
                            }
                        } else {
                            if (this.props.onAdd) {
                                this.props.onAdd(values, updatedItems.length);
                            }
                        }
                        this.calculateDatesAndDays();
                    }
                );
            }
        } catch (error) {
            console.error("Error fetching step:", error);
        }
    };

    renderSubmit = (onSubmitEvent: any) => {
        // return null;
        return (
            <div className="col-md-12" style={{ marginBottom: 10 }}>
                <Button
                    onClick={() => {
                        sessionStorage.removeItem("currentParent");
                        sessionStorage.removeItem("currentParentIndex");
                        this.setState({ isModalOpen: false });
                    }}>
                    <i className="fas fa-fw fa-times mr-2"></i>
                    Cancelar
                </Button>
                {this.state.isEdit !== undefined && this.state.isRead === false && (
                    <Button
                        color="green"
                        onClick={() => {
                            sessionStorage.removeItem("currentParent");
                            sessionStorage.removeItem("currentParentIndex");
                            if (onSubmitEvent) {
                                onSubmitEvent();
                            }
                        }}>
                        <i className="fas fa-fw fa-save mr-2"></i>
                        Gravar
                    </Button>
                )}
            </div>
        );
    };

    getDefaultDate = () => {
        return this.state.items[0]?.project_product_group_step_table_flow_expected_initial_date
        ? dayjs(this.state.items[0].project_product_group_step_table_flow_expected_initial_date)
        : this.props.parentValues.project_started_date
            ? dayjs(this.props.parentValues.project_started_date)
            : this.props.parentValues.project_created_at
                ? dayjs(this.props.parentValues.project_created_at)
                : dayjs();
    }

    countOfNonWeekDays = (initialDate: dayjs.Dayjs, endDate: dayjs.Dayjs) => {
        const weekDays = this.state.weekDays.sort((a: any, b: any) => a - b);
        let daysCounted = 0;
        
        let dateClone = initialDate.clone();
        for (let i = 0; i <= endDate.diff(initialDate, 'days') - 1; i++) {
            const nextDate = dateClone.add(1, 'day');
            if (!weekDays.includes(nextDate.day())) {
                daysCounted++;
            }
            if (i > 365) {
                break
            }
            dateClone = nextDate;
        }
        // console.log(daysCounted);
        
        return daysCounted;
    }

    getNextWeekDay = (date: dayjs.Dayjs): dayjs.Dayjs => {
        const weekDays = this.state.weekDays.sort((a: any, b: any) => a - b);
        const currentDay = date.day();
        const nextWeekDay = weekDays.find((day: any) => day >= currentDay);
        // console.log(weekDays, currentDay, nextWeekDay);
      
        if (nextWeekDay !== undefined) {
            return date.set('day', nextWeekDay);
        } else {
            const newDate = date.add(1, 'week').set('day', weekDays[0]) 
            // console.log(newDate.diff(date, 'days'));
            
            return newDate
        }
    }

    calculateExpectedFinalDate = (date: dayjs.Dayjs, measureDays: number) => {
        const weekDays = this.state.weekDays.sort((a: any, b: any) => a - b);
        let currentDate = date.clone();
        let daysCounted = 0;

        if (measureDays < 1) return currentDate;
    
        for (let i = 0; daysCounted < measureDays; i++) {
            const nextDate = currentDate.add(1, 'day');
            if (weekDays.includes(nextDate.day())) {
                daysCounted++;
            }
            if (i > 90) {
                break
            }
            currentDate = nextDate;
        }
    
        return currentDate;
    }

    calculateDatesAndDays = (ofIndex: number = 0) => {
        ofIndex = ofIndex <= 0 ? 0 : ofIndex;
        const updatedItems = this.state.items ? [...this.state.items] : [];
        const defaultDate = dayjs(updatedItems[ofIndex]?.project_product_group_step_table_flow_expected_initial_date).isValid() 
            ? dayjs(updatedItems[ofIndex]?.project_product_group_step_table_flow_expected_initial_date) 
            : this.getDefaultDate()
        let previousExpectedInitialDate = defaultDate;
        let previousAdvanceOrDelay = updatedItems[ofIndex]?.project_product_group_step_table_flow_advance_or_delay || 0;
        let previousMeasureDays = updatedItems[ofIndex]?.project_product_group_step_table_flow_measure_days || 0;
        let previousExpectedFinalDate = this.calculateExpectedFinalDate(defaultDate, previousMeasureDays - 1)
        
        // ofIndex = ofIndex == 0 ? 0 : ofIndex + 1;
        for (let index = ofIndex; index < updatedItems.length; index++) {
            // console.group(`index: ${index}`)
            // console.log({
            //     previousExpectedInitialDate,
            //     previousExpectedFinalDate,
            //     previousMeasureDays
            // });
            const previousItem = updatedItems[index-1];
            const isDFA = updatedItems[index].project_product_group_step_table_flow_is_last_phase_dependencie;
            const measureDays = updatedItems[index].project_product_group_step_table_flow_measure_days || 0;
            
            if (previousItem && index > ofIndex) {
                // let inPast = dayjs(item.project_product_group_step_table_flow_expected_initial_date).diff(previousItem.project_product_group_step_table_flow_expected_final_date, 'day')
                // console.log(inPast <= 0 || !isDFA || ofIndex == 0);
                // if (inPast <= 0 || !isDFA || ofIndex == 0) { //Atualiza somente se a data inicial for antes da data final da fase anterior
                    const expectedInitialDate = this.calculateExpectedInitialDate(
                        isDFA,
                        previousExpectedFinalDate,
                        measureDays,
                        previousExpectedInitialDate,
                        previousAdvanceOrDelay,
                        previousMeasureDays
                    );

                    updatedItems[index].project_product_group_step_table_flow_expected_initial_date = this.getNextWeekDay(expectedInitialDate).format('YYYY-MM-DD HH:mm:ss.SSS')
                    // console.log({expectedInitialDate});
                // }
            } else {
                updatedItems[index].project_product_group_step_table_flow_expected_initial_date = previousExpectedInitialDate
            }
                
            // console.log({defaultDate});
            // console.log({currentDate: dayjs(updatedItems[index].project_product_group_step_table_flow_expected_initial_date)});
            const expectedFinalDate = this.calculateExpectedFinalDate(dayjs(updatedItems[index].project_product_group_step_table_flow_expected_initial_date), measureDays - 1)
            // console.log({expectedFinalDate});
            updatedItems[index].project_product_group_step_table_flow_expected_final_date = expectedFinalDate.format('YYYY-MM-DD HH:mm:ss.SSS');

            this.calculateAdvanceOrDelay(updatedItems[index]);

            previousExpectedInitialDate = dayjs(updatedItems[index].project_product_group_step_table_flow_expected_initial_date);
            previousExpectedFinalDate = expectedFinalDate;
            previousAdvanceOrDelay = updatedItems[index].project_product_group_step_table_flow_advance_or_delay || 0;
            previousMeasureDays = measureDays;
            // console.groupEnd()
        }

        /*updatedItems.forEach((item, index) => {
            const isDFA = item.project_product_group_step_table_flow_is_last_phase_dependencie;
            const measureDays = item.project_product_group_step_table_flow_measure_days || 0;
            
            if (updatedItems[index+1]) {
                let inPast = dayjs(updatedItems[index+1].project_product_group_step_table_flow_expected_initial_date).diff(updatedItems[index].project_product_group_step_table_flow_expected_final_date, 'day')
                console.log(inPast, index);
                if (inPast <= 0) {
                    // console.log("updatedItems[index+1].project_product_group_step_table_flow_expected_final_date ::>", updatedItems[index+1].project_product_group_step_table_flow_expected_final_date);
                    // console.log("updatedItems[index+1].project_product_group_step_table_flow_measure_days ::>", updatedItems[index+1].project_product_group_step_table_flow_measure_days);
                    // console.log("updatedItems[index+1].project_product_group_step_table_flow_expected_initial_date ::>", updatedItems[index+1].project_product_group_step_table_flow_expected_initial_date);
                    console.log({
                        isDFA,
                        previousExpectedFinalDate,
                        measureDays,
                        previousExpectedInitialDate,
                        previousAdvanceOrDelay,
                        previousMeasureDays
                    });
                    
                    const expectedInitialDate = this.calculateExpectedInitialDate(
                        isDFA,
                        previousExpectedFinalDate,
                        measureDays,
                        previousExpectedInitialDate,
                        previousAdvanceOrDelay,
                        previousMeasureDays
                    );
                    console.log(expectedInitialDate.toString());
                    

                    updatedItems[index].project_product_group_step_table_flow_expected_initial_date = expectedInitialDate.format('YYYY-MM-DD HH:mm:ss.SSS');
                }
            }
            // if (index === 0) {
            //     item.project_product_group_step_table_flow_expected_initial_date = defaultDate.format('YYYY-MM-DD HH:mm:ss.SSS');
            // } else {
               
                // const expectedInitialDate = this.calculateExpectedInitialDate(
                //     isDFA,
                //     previousExpectedFinalDate,
                //     measureDays,
                //     previousExpectedInitialDate,
                //     previousAdvanceOrDelay,
                //     previousMeasureDays
                // );

                // item.project_product_group_step_table_flow_expected_initial_date = expectedInitialDate.format('YYYY-MM-DD HH:mm:ss.SSS');
            // }

            const expectedFinalDate = dayjs(updatedItems[index].project_product_group_step_table_flow_expected_initial_date)
                .add(measureDays - 1, 'day');

            updatedItems[index].project_product_group_step_table_flow_expected_final_date = expectedFinalDate.format('YYYY-MM-DD HH:mm:ss.SSS');

            this.calculateAdvanceOrDelay(updatedItems[index]);

            previousExpectedInitialDate = dayjs(updatedItems[index].project_product_group_step_table_flow_expected_initial_date);
            previousExpectedFinalDate = expectedFinalDate;
            previousAdvanceOrDelay = updatedItems[index].project_product_group_step_table_flow_advance_or_delay || 0;
            previousMeasureDays = measureDays;
        }); */
        this.setState({ items: updatedItems }, () => {
            if (this.props.onChange) {
                this.props.onChange(updatedItems);
            }
            this.setState({ forceUpdateTable: Math.random()})
        });
    }

    calculateExpectedInitialDate = (isDFA: boolean, previousExpectedFinalDate: dayjs.Dayjs, measureDays: number, previousExpectedInitialDate: dayjs.Dayjs, previousAdvanceOrDelay: number, previousMeasureDays: number) => {
        let baseDate;

        if (isDFA) {
            baseDate = previousExpectedFinalDate.add(1, 'day');

            if (previousAdvanceOrDelay != 0) {
                baseDate = baseDate.add(previousAdvanceOrDelay, 'day');
            }

            // baseDate = baseDate.add(measureDays - 1, 'day');
        } else {
            baseDate = dayjs(previousExpectedInitialDate);
        }

        return baseDate;
    }

    calculateAdvanceOrDelay = (item: any) => {
        if (item.project_product_group_step_table_flow_finalized_at) {
            const expectedFinalDate = dayjs(item.project_product_group_step_table_flow_expected_final_date).endOf('day');
            const actualFinalDate = dayjs(item.project_product_group_step_table_flow_finalized_at).endOf('day');
            const nonWeekDays = this.countOfNonWeekDays(actualFinalDate, expectedFinalDate);
            
            let advanceOrDelay = actualFinalDate.diff(expectedFinalDate, 'day')
            // console.log('advanceOrDelay', advanceOrDelay);
            advanceOrDelay = advanceOrDelay < 0
                ? advanceOrDelay + nonWeekDays
                : advanceOrDelay > 0
                    ? advanceOrDelay - nonWeekDays
                    : 0
            // console.log('advanceOrDelay', advanceOrDelay);
            item.project_product_group_step_table_flow_advance_or_delay = advanceOrDelay;
        } else {
            item.project_product_group_step_table_flow_advance_or_delay = null;
        }
    }

    handleMeasureDaysChange = (value: number, rowIndex: number, items: any[]) => {
        const updatedItems = [...items];
        updatedItems[rowIndex].project_product_group_step_table_flow_measure_days = value || 0;
        updatedItems[rowIndex].project_product_group_step_table_flow_expected_final_date = this.calculateExpectedFinalDate(dayjs(updatedItems[rowIndex].project_product_group_step_table_flow_expected_initial_date), value - 1)
        this.setState({ items: updatedItems }, () => {
            this.calculateDatesAndDays(rowIndex);
        })
    }

    handleExpectedFinalDateChange = (value: string, rowIndex: number, items: any[]) => {
        const updatedItems = [...items];
        const currentItem = updatedItems[rowIndex];
        const date = value ? dayjs(value) : dayjs();

        const expectedInitialDate = dayjs(currentItem.project_product_group_step_table_flow_expected_initial_date);
        const countNonDays = this.countOfNonWeekDays(expectedInitialDate, date) || 0
        
        currentItem.project_product_group_step_table_flow_measure_days = date.diff(expectedInitialDate, 'days') + 1 - countNonDays
        currentItem.project_product_group_step_table_flow_expected_final_date = date.format('YYYY-MM-DD HH:mm:ss.SSS');

        this.setState({ items: updatedItems }, () => {
            this.calculateDatesAndDays(rowIndex);
        });
    }

    handleInputChange = (value: any, rowIndex: any, dataKey: any) => {
        const items = [...this.state.items];

        if (dataKey === "project_product_group_step_table_flow_measure_days") {
            this.handleMeasureDaysChange(value, rowIndex, items);
        } else if (dataKey === "project_product_group_step_table_flow_expected_final_date") {
            this.handleExpectedFinalDateChange(value, rowIndex, items);
        } else {
            items[rowIndex][dataKey] = value;
            this.setState({ items }, () => {
                if (this.props.onChange) {
                    this.props.onChange(items);
                }
            });
        }
    }

    formatTextObject = (text: any) => {
        // console.log("formatTextObject", text, typeof text);
        if (typeof text === 'string' && text.trim() !== '') {
            return text.replace(/[{}"]/g, '').split(',').map(Number);
        }
        return text;
    }

    handleDragRow = (sourceId: number, targetId: number) => {
        const sourceFilter = this.filterData(this.state.searchs);
        const newArrayDraggle = this.sort(sourceFilter, sourceId, targetId);

        this.setState({ items: newArrayDraggle }, () => {
            this.calculateDatesAndDays();
            if (this.props.onChange) {
                this.props.onChange(newArrayDraggle);
            }
        });
    };

    filterData = (searchs: any) => {
        var output = [];

        var data = this.state.items;
        for (var i in data) {
            var row = data[i];

            if (this.state.hiddenRows.includes(i)) {
                continue;
            }
            var includes = true;
            for (var fieldName in this.state.form) {
                var field = this.state.form[fieldName];
                if (field !== undefined) {
                    var parsedValue = SchemaToParsedCell.parseFieldToValue(field, row);
                    if (searchs[fieldName]) {
                        if (!parsedValue.includes(searchs[fieldName])) {
                            includes = false;
                            break;
                        }
                    }
                }
            }
            // //("PARSED",SchemaToParsedCell.parseFieldToValue(this.state.form[i],data[i]))
            if (includes) {
                output.push(data[i]);
            }
        }

        output = _.orderBy(output, [`project_product_group_step_table_flow_order_by`], 'asc');
        return output;
    };

    sort(source: any, sourceId: number, targetId: number) {
        const columnIdName = 'project_product_group_step_table_flow_order_by';
        const nextData = source.slice();
        const dragItem = nextData.find((item: any) => item[columnIdName] === sourceId);
        const targetIndex = nextData.findIndex((item: any) => item[columnIdName] === targetId);

        nextData.splice(nextData.findIndex((item: any) => item[columnIdName] === sourceId), 1);

        nextData.splice(targetIndex, 0, dragItem);

        nextData.forEach((item: any, index: number) => {
            item[columnIdName] = index + 1;
        });

        return nextData;
    }

    render() {
        const { id } = this.props.match.params;

        return (
            <>
                <div style={{ display: "inline-flex" }}>
                    <Panel bordered bodyFill style={{ padding: 8 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 8 }}>
                            {this.props.compact !== true && <h4 dangerouslySetInnerHTML={{ __html: "Fases" }}></h4>}
                            <div>
                                <Whisper placement="top" trigger="hover" speaker={
                                    <Tooltip>
                                        Recalcula as datas e os dias.
                                    </Tooltip>
                                }>
                                    <Button color="blue" onClick={() => this.calculateDatesAndDays()} style={{marginRight: 4}} loading={this.state.isLoading}>
                                        <i className="fas fa-calculator mr-2"></i>
                                        Calcular
                                    </Button>
                                </Whisper>
                                <Button color="green" onClick={this.openAdd}>
                                    <i className="fas fa-fw fa-plus mr-2"></i>
                                    Adicionar
                                </Button>
                            </div>
                        </div>
                        <Modal overflow={false} size={"lg"} className="rs-modal-min" show={this.state.isModalOpen}>
                            {Object.keys(this.state.form).length > 0 && (
                                <Formalize
                                    isModal={true}
                                    readOnly={false}
                                    parentValues={this.props.parentValues}
                                    parent={this.props.parent}
                                    values={_.cloneDeep(this.state.selectedValues)}
                                    onSubmit={this.onSubmit}
                                    submitButton={this.renderSubmit}
                                    form={this.state.form}
                                    history={this.props.history}
                                    name={"this.props.field.api"}
                                />
                            )}
                        </Modal>
                        {/* {JSON.stringify(this.state.items)} */}
                        <DndProvider backend={HTML5Backend}>
                            {/* {JSON.stringify(this.state.items)} */}
                            <Table
                                headerHeight={50}
                                rowHeight={50}
                                // className={this.props.compact ? "compact" : ""}
                                wordWrap
                                renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                                loading={this.state.isLoading}
                                height={400}
                                data={this.state.items}
                                key={this.state.forceReloadTable}
                            >

                                <Column fixed={"left"} width={this.eventHandler?.width ?? 100} >
                                    <HeaderCell>Ordem</HeaderCell>
                                    <DraggableCell onDrag={this.handleDragRow} columnIdName={`project_product_group_step_table_flow_order_by`}>
                                        <i className="fas fa-arrows-alt"></i>
                                    </DraggableCell>
                                </Column>

                                <Column resizable width={400}>
                                    <HeaderCell>Fase</HeaderCell>
                                    {/* <Cell dataKey="project_product_group_step_table_flow_fk_step_id" className="link-group"></Cell> */}
                                    <Cell>
                                        {(rowData: any) => {
                                            if (this.props?.parentValues?.project_id) {
                                                return (
                                                    <Link
                                                        target={"_blank"}
                                                        to={`/dashboard/project/chats/${id}/${rowData.project_product_group_step_table_flow_fk_project_product_id}/${rowData.project_product_group_step_table_flow_id}`}
                                                    >
                                                        {rowData.steps?.step_name} <i className="fas fa-fw fa-external-link-alt ml-1"></i>
                                                    </Link>
                                                )
                                            }

                                            return rowData.steps?.step_name;
                                        }}
                                    </Cell>
                                </Column>

                                <Column resizable width={100}>
                                    <HeaderCell>Prazo</HeaderCell>
                                    <Cell>
                                        {(rowData: any, rowIndex: number) => (
                                            // <NumberInput
                                            //     punctuation={false}
                                            //     placeholder=""
                                            //     step={0}
                                            //     value={rowData.project_product_group_step_table_flow_measure_days}
                                            //     onChange={(value: number) => this.handleInputChange(value, rowIndex, 'project_product_group_step_table_flow_measure_days')}
                                            // />
                                            <CurrencyInput
                                                placeholder="Prazo em dias"
                                                className="rs-input"
                                                onValueChange={(value, _, values) => {
                                                    const numericValue = value ? Number(value) : null;
                                                    this.handleInputChange(numericValue, rowIndex, 'project_product_group_step_table_flow_measure_days');
                                                }}
                                                decimalSeparator=","
                                                groupSeparator="."
                                                value={rowData.project_product_group_step_table_flow_measure_days ?? ''}
                                                decimalsLimit={0}
                                                allowNegativeValue={false}
                                                disableAbbreviations={true}
                                            />
                                        )}
                                    </Cell>
                                </Column>

                                <Column resizable width={80}>
                                    <HeaderCell>
                                        D.F.A
                                        <Whisper placement="top" trigger="hover" speaker={
                                            <Tooltip>
                                                Marque se essa fase só pode ser iniciada após finalização da anterior
                                            </Tooltip>
                                        }>
                                            <i className="fas fa-info fa-fw ml-2" style={{ cursor: "help" }}></i>
                                        </Whisper>
                                    </HeaderCell>
                                    <Cell className="link-group">
                                        {(rowData: any, rowIndex: number) => {
                                            // console.log({ rowIndex });
                                            if (rowIndex !== 0) {
                                                return (
                                                    <Checkbox
                                                        onChange={(value: boolean, checked: boolean) => {
                                                            this.handleInputChange(checked, rowIndex, "project_product_group_step_table_flow_is_last_phase_dependencie");
                                                            if (checked) {
                                                                this.calculateDatesAndDays(rowIndex - 1)
                                                            }
                                                            // this.handleMeasureDaysChange(rowData.project_product_group_step_table_flow_measure_days, rowIndex, this.state.items);
                                                        }}
                                                        checked={rowData?.project_product_group_step_table_flow_is_last_phase_dependencie || false}
                                                    />
                                                )
                                            }
                                        }}
                                    </Cell>
                                </Column>

                                <Column resizable width={160}>
                                    <HeaderCell>Data Inicial Prevista</HeaderCell>
                                    <Cell>
                                        {(rowData: any, rowIndex: number) => {
                                            const defaultDate = this.getDefaultDate().toDate();

                                            return (
                                                <DatePickerComponent
                                                    // readonly
                                                    format="DD/MM/YYYY"
                                                    value={rowData.project_product_group_step_table_flow_expected_initial_date
                                                        ? dayjs(rowData.project_product_group_step_table_flow_expected_initial_date).toDate()
                                                        : defaultDate}
                                                    onChange={(value) => {
                                                        const updatedItems = [...this.state.items]
                                                        updatedItems[rowIndex].project_product_group_step_table_flow_expected_initial_date = dayjs(value)
                                                        updatedItems[rowIndex].project_product_group_step_table_flow_expected_final_date = this.calculateExpectedFinalDate(dayjs(value), updatedItems[rowIndex].project_product_group_step_table_flow_measure_days - 1)
                                                        this.setState({ items: updatedItems }, () => this.calculateDatesAndDays(rowIndex));
                                                    }}
                                                    disabledDate={(date: Date) => {
                                                        const isWorkDay = this.state.weekDays.includes(dayjs(date).day());
                                                        return !isWorkDay
                                                    }}
                                                />
                                            );
                                        }}
                                    </Cell>
                                </Column>

                                <Column resizable width={160}>
                                    <HeaderCell>Data Final Prevista</HeaderCell>
                                    <Cell>
                                        {(rowData: any, rowIndex: number) => {
                                            return (
                                                <DatePickerComponent
                                                    key={Math.random()}
                                                    format="DD/MM/YYYY"
                                                    value={rowData.project_product_group_step_table_flow_expected_final_date
                                                        ? dayjs(rowData.project_product_group_step_table_flow_expected_final_date).toDate()
                                                        : rowData.project_product_group_step_table_flow_expected_initial_date
                                                            ? this.calculateExpectedFinalDate(dayjs(rowData.project_product_group_step_table_flow_expected_initial_date), (rowData.project_product_group_step_table_flow_measure_days || 1) - 1 ).toDate()
                                                            : null
                                                    }
                                                    onChange={(value: any) => {
                                                        if (!dayjs(value).isValid()) {
                                                            const updatedItems = [...this.state.items]
                                                            updatedItems[rowIndex].project_product_group_step_table_flow_measure_days = null
                                                            updatedItems[rowIndex].project_product_group_step_table_flow_expected_final_date = this.calculateExpectedFinalDate(dayjs(updatedItems[rowIndex].project_product_group_step_table_flow_expected_initial_date), 0)
                                                            this.setState({ items: updatedItems, forceReloadTable: Math.random() })
                                                            // this.setState({ items: updatedItems }, () => this.calculateDatesAndDays(rowIndex - 1));
                                                        } else {
                                                            const formattedDate = value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
                                                            this.handleExpectedFinalDateChange(formattedDate, rowIndex, this.state.items);
                                                        }
                                                    }}
                                                    disabledDate={(date: Date) => {
                                                        const initialDate = dayjs(rowData.project_product_group_step_table_flow_expected_initial_date);
                                                        const isWorkDay = this.state.weekDays.includes(dayjs(date).day());
                                                        return dayjs(date).isBefore(initialDate, 'day') || !isWorkDay
                                                    }}
                                                />
                                            );
                                        }}
                                    </Cell>
                                </Column>

                                <Column resizable width={160}>
                                    <HeaderCell>Data Inicial</HeaderCell>
                                    <Cell>
                                        {(rowData: any, rowIndex: number) => {
                                            const defaultDate = this.getDefaultDate().toDate();

                                            return (
                                                <DatePickerComponent
                                                    readonly
                                                    format="DD/MM/YYYY"
                                                    value={rowData.project_product_group_step_table_flow_initiated_at
                                                        ? dayjs(rowData.project_product_group_step_table_flow_initiated_at).toDate()
                                                        : null}
                                                    onChange={(value: any) => {
                                                        const formattedDate = value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
                                                        this.handleExpectedFinalDateChange(formattedDate, rowIndex, this.state.items);
                                                    }}
                                                />
                                            );
                                        }}
                                    </Cell>
                                </Column>

                                <Column resizable width={160}>
                                    <HeaderCell>Data Final</HeaderCell>
                                    <Cell>
                                        {(rowData: any, rowIndex: number) => {
                                            const defaultDate = this.getDefaultDate().toDate();

                                            return (
                                                <DatePickerComponent
                                                    readonly
                                                    format="DD/MM/YYYY"
                                                    value={rowData.project_product_group_step_table_flow_finalized_at
                                                        ? dayjs(rowData.project_product_group_step_table_flow_finalized_at).toDate()
                                                        : null}
                                                    onChange={(value: any) => {
                                                        const formattedDate = value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss.SSS') : '';
                                                        this.handleExpectedFinalDateChange(formattedDate, rowIndex, this.state.items);
                                                    }}
                                                />
                                            );
                                        }}
                                    </Cell>
                                </Column>

                                <Column resizable width={120}>
                                    <HeaderCell>
                                        Antec./Atraso
                                        <Whisper placement="top" trigger="hover" speaker={
                                            <Tooltip>
                                                Positivo para atraso e negativo para antecedência
                                            </Tooltip>
                                        }>
                                            <i className="fas fa-info fa-fw ml-2" style={{ cursor: "help" }}></i>
                                        </Whisper>
                                    </HeaderCell>
                                    <Cell>
                                        {(rowData: any, rowIndex: number) => (
                                            <CurrencyInput
                                                disabled
                                                className="rs-input"
                                                onValueChange={(value, _, values) => {
                                                    const numericValue = value ? Number(value) : null;
                                                    this.handleInputChange(numericValue, rowIndex, 'project_product_group_step_table_flow_advance_or_delay');
                                                }}
                                                decimalSeparator=","
                                                groupSeparator="."
                                                value={rowData.project_product_group_step_table_flow_advance_or_delay ?? ''}
                                                decimalsLimit={0}
                                                allowNegativeValue
                                                disableAbbreviations
                                            />
                                        )}
                                    </Cell>
                                </Column>

                                <Column resizable width={350}>
                                    <HeaderCell>Usuários Envolvidos</HeaderCell>
                                    <Cell className="link-group">
                                        {(rowData: any, rowIndex: number) => {
                                            return (
                                                <div className="rs-form-control-wrapper">
                                                    <RSuiteCheckTreePicker
                                                        // ref={this.inputRef}
                                                        placeholder="Selecione..."
                                                        data={this.state.data}
                                                        value={this.formatTextObject(rowData?.project_product_group_step_table_flow_users ?? [])}
                                                        defaultValue={this.formatTextObject(rowData?.project_product_group_step_table_flow_users ?? [])}
                                                        onOpen={() => this.onLoadingOptions()}
                                                        onChange={(value: any) => this.handleInputChange(value, rowIndex, "project_product_group_step_table_flow_users")}
                                                        onClean={(value: any) => this.handleInputChange(null, rowIndex, "project_product_group_step_table_flow_users")}
                                                    />
                                                </div>
                                            )
                                        }}
                                    </Cell>
                                </Column>

                                <Column fixed={"right"} width={100}>
                                    <HeaderCell>Ações</HeaderCell>
                                    <Cell className="link-group">
                                        {(rowData: any, rowIndex: any) => {
                                            return this.renderActions(rowData, rowIndex);
                                        }}
                                    </Cell>
                                </Column>
                            </Table>
                        </DndProvider>
                    </Panel>
                </div>
            </>
        );
    }
}
