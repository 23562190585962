import dayjs from "dayjs";

const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'OK',
    today: 'Hoje',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
    yesterday: 'Ontem',
    last7Days: 'Ultimos 7 dias',
    formatDate: (date: any, format: string = 'DD/MM/YYYY HH:mm') => dayjs(date).format(format),
    formattedMonthPattern: 'MM/YYYY',
    formattedDayPattern: 'DD/MM/YYYY'
};

export { locale };