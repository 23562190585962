import React from "react";
import "./style/App.css";
import "./style/kanban.css";
import "./style/kanban-step.css";
import "./style/rsuite.css";
import Topbar from "./components/topbar/main";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import { AuthContext, ChatContext, ModuleContext, UserContext } from "./components/authProvider/main";
import DashboardRoutes from "./pages/routes/DashboardRoutes";
import Sidebar from "./components/sidebar/main";
import axios from "./utilities/axios";
import ProtectedRoute from "./components/protectedRoute/main";
import ChatProtectRouter from "./components/chat/ChaRouter"
import { Button, Modal, Notification } from "rsuite";
import { onError } from "./utilities/errorHandler";
import { PrettyError } from "./components/pretty-error/main";
import { io } from "./utilities/io";
import SocketIoEvents from "./components/socketIoEvents/SocketIoEvents";
import { MainChat } from "./components/chat/MainChat";
import ChatRoutes from "./pages/routes/ChatRoutes";
import axiosClientChat from "./utilities/axiosClientChat";
import SetPasswordPage from "./pages/SetPasswordPage";
import ResetPassword from "./pages/ResetPassword";
import GlobalLoading from "./components/GlobalLoading";
// import { Helmet } from "react-helmet";

export class App extends React.Component<any, {}> {
    public state: any = {
        globalLoading: false,
        setGlobalLoading: (loading: boolean) => {
            this.setState({ globalLoading: loading });
        },
        hasError: false,
        errorMessage: '',
        hiddenSidebar: localStorage.getItem("hiddenSidebar") == "true" ? true : false,
        hiddenTopbar: localStorage.getItem("hiddenTopbar") == "true" ? true : false,
        auth: {
            token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
            isAuthenticated: localStorage.getItem("token") ? true : false,
            setAuth: (token: string | null) => {
                if (token == null) {
                    localStorage.removeItem("token");
                    localStorage.removeItem("currentCompany");
                    localStorage.removeItem('url_logo_company')
                    this.setState((old: any) => {
                        var newModule = { ...old.auth };
                        newModule.token = token;
                        newModule.isAuthenticated = false;
                        return { auth: newModule };
                    }, () => {
                        this.state.user.setUser(null);
                    });
                    // this.setState({ auth: { token, isAuthenticated: false } });
                } else {
                    localStorage.setItem("token", token);
                    this.setState((old: any) => {
                        var newModule = { ...old.auth };
                        newModule.token = token;
                        newModule.isAuthenticated = true;
                        return { auth: newModule };
                    }, () => {
                        axios.get('/api/v1/me').then((res) => {
                            if (res.data && res.data.user) {
                                this.state.user.setUser(res.data.user);
                            }
                        }).catch((e) => {
                            this.state.user.setUser(null);
                            localStorage.removeItem("currentCompany");
                            localStorage.removeItem('url_logo_company')
                        })
                    });
                    // this.setState({ auth: { token, isAuthenticated: true } });
                }
            },
        },
        authChatClient: {
            token: localStorage.getItem('chatToken') ? localStorage.getItem('chatToken') : null,
            isAuthenticated: localStorage.getItem("chatToken") ? true : false,
            setAuth: (token: string | null) => {
                if (token == null) {
                    localStorage.removeItem('chatToken');
                } else {
                    localStorage.setItem('chatToken', token);
                }
            }
        },
        user: {
            data: null,
            setUser: (data: any) => {
                this.setState((old: any) => {
                    var newModule = { ...old.user };
                    newModule.data = data;
                    return { user: newModule };
                });
            },
        },
        module: {
            name: null,
            crud: null,
            page: null,
            color: null,
            setPage: (name: string | null = null) => {
                this.setState((old: any) => {
                    var newModule = { ...old.module };
                    if (name != null) {
                        newModule.page = name;
                    }
                    return { module: newModule };
                });
            },
            setModule: (name: string | null = null, color: string | null = null, crud = null) => {
                this.setState((old: any) => {
                    var newModule = { ...old.module };
                    if (name != null) {
                        newModule.name = name;
                    }
                    if (color) {
                        newModule.color = color;
                    }
                    if (crud) {
                        newModule.crud = crud;
                    }
                    return { module: newModule };
                });
                // this.setState({ module: { name,setModule: this.state.module.setModule } });
            },
        },
    };

    componentDidMount() {
        if (window.location.href.includes('/web-chat')) {
            this.setState({ hiddenSidebar: true, hiddenTopbar: true });
            // this.forceUpdate();
        }

        if ((this.state.authChatClient.token == null && window.location.href.includes('/web-chat')) ||
            ((window.location.href.includes('/web-chat') && this.state.authChatClient.token !== window.location.href.split('/web-chat/').pop()) && !['chats', 'item'].includes(window.location.href))
        ) {
            try {
                const token = window.location.href.split('/web-chat/').pop() || ""
                if(token?.length > 6 && token.includes('-') && !token.includes('chat')){

                    this.state.authChatClient.setAuth(token)
                }else{
                    if(localStorage.getItem('chatToken')){
                        this.state.authChatClient.setAuth(localStorage.getItem('chatToken'))
                    }else{
                        return alert("Link Invalido e/ou Expirado!")
                    }
                }

            } catch (e) {
                console.log({ e })
            }
        }
        if (this.state.auth.token != null) {
            this.state.auth.setAuth(this.state.auth.token);
        }
        onError('teste', (err: any) => {
            // alert(err);
            this.setState({ hasError: true, errorMessage: err.data })
        })


    }

    render() {
        return (
            <div>
                {this.state.globalLoading && <GlobalLoading />}
                <Modal show={this.state.hasError} size={"md"} >
                    <Modal.Body>
                        <PrettyError error={this.state.errorMessage} />
                    </Modal.Body>
                    <Modal.Footer className="mt-4">
                        <Button onClick={() => this.setState({ hasError: false })}>Fechar</Button>
                    </Modal.Footer>
                </Modal>
                <Router>
                    <AuthContext.Provider value={this.state.auth}>
                        <ModuleContext.Provider value={this.state.module}>
                            <UserContext.Provider value={{
                                ...this.state.user,
                                setGlobalLoading: this.state.setGlobalLoading
                            }}>
                                <SocketIoEvents user={this.state.user} />
                                <Sidebar hiddenSidebar={this.state.hiddenSidebar} />
                                {!this.state.hiddenTopbar && (
                                    <Topbar hiddenSidebar={this.state.hiddenSidebar}
                                        changeSidebar={() => {
                                            this.setState({ hiddenSidebar: !this.state.hiddenSidebar });
                                            localStorage.setItem("hiddenSidebar", (!this.state.hiddenSidebar)?.toString())
                                        }}
                                    />
                                )}
                                <Switch>
                                    <Route exact path="/" component={LoginPage}></Route>
                                    <Route exact path="/login" component={LoginPage}></Route>
                                    <Route exact path="/setpassword" component={SetPasswordPage}></Route>
                                    <Route exact path="/resetpassword" component={ResetPassword}></Route>
                                    {/* <ChatProtectRouter path="/web-chat" component={ChatRoutes} /> */}
                                    <ProtectedRoute hiddenSidebar={this.state.hiddenSidebar} path="/dashboard" component={DashboardRoutes}></ProtectedRoute>
                                </Switch>
                            </UserContext.Provider>
                        </ModuleContext.Provider>
                    </AuthContext.Provider>
                </Router>
                {/* <ChatContext.Provider value={this.state.authChatClient}> */}
                <Router>
                    <Switch>
                        <ChatProtectRouter path="/web-chat" component={ChatRoutes} />
                    </Switch>
                </Router>
                {/* </ChatContext.Provider> */}
            </div>
            // <div className="App">
            //         <div className="container">
            //             <div className="row">
            //                 <div className="col-md-12 mb-4">
            //                     <Button color="violet" onClick={this.load}>
            //                         Atualizar
            //                     </Button>
            //                 </div>
            //                 <div className="col-md-12">
            //                     <List>{this.renderList()}</List>
            //                 </div>
            //             </div>
            //         </div>
            //     <Sidebar />
            //     <Topbar />

            //     {Object.keys(this.state.formulario).length > 0 && <Formalize name={this.state.name} values={{}} form={this.state.formulario} />}
            //     {Object.keys(this.state.formulario).length > 0 && <Browserlize name={this.state.name} values={{}} form={this.state.formulario} />}
            // </div>
        );
    }
}

// export default App;
