import React from "react";
import { Button, Icon, Notification } from "rsuite";
import axios from "../../../utilities/axios";
import { ConfirmModal } from "../../../components/confirmModal/ConfirmModal";

class GroupComponent extends React.Component<any, {}> {
    state = {
        loading: false,
        showDialogModal: false
    }

    cloneGroups = () => {
        const { parentState } = this.props

        for (const i in parentState.checked) {
            this.setState({ loading: true });
            axios.get(`/api/v1/clone/group/${parentState.checked[i]}`).then((res) => {
                Notification.success(
                    {
                        title: "Sucesso",
                        description: "Grupos de Fases duplicados com êxito!"
                    }
                )

                setTimeout(() => {
                    if (res.data.status == 200) {
                        if (parentState.checked.length === 1) {
                            const { group_id } = res.data.result[0];

                            window.open(`/dashboard/registrations/groups/${group_id}`, "_blank");
                        }
                        window.location.reload();
                    }
                }, 3000)

            }).catch((e) => {
                throw ("Erro");
            }).finally(() => {
                this.setState({ loading: false });
            });
        }
    }

    openCloneModal = () => {
        const { parentState } = this.props;
        if (parentState.checked.length < 1) {
            Notification.info({
                title: "Aviso",
                description: "Não é possível duplicar sem nenhum grupo de fase selecionado!"
            });
            return;
        }
        this.setState({ showDialogModal: true });
    }

    render(): React.ReactNode {
        return (
            <div className="col-md-12 mt-4 d-flex">
                <Button
                    className="ml-2"
                    color="blue"
                    onClick={this.openCloneModal}
                    style={
                        {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: 5
                        }
                    }
                    loading={this.state.loading}
                >
                    <i className="fas fa-clone"></i>
                    Duplicar Grupo de Fase
                </Button>

                <ConfirmModal
                    loading={this.state.loading}
                    onCancel={() => {
                        this.setState({
                            showDialogModal: false,
                        })
                    }}
                    funcaoclose={() => {
                        this.setState({
                            showDialogModal: false,
                        })
                    }}
                    body="Ao duplicar este registro o mesmo já será gravado. Deseja continuar?"
                    content=""
                    showConfirm={true}
                    confirmColor="green"
                    cancelText={"Cancelar"}
                    onConfirm={() => this.cloneGroups()}
                    onCloseButton={true}
                    title={<>
                        <Icon
                            icon="remind"
                            style={{
                                color: '#ffb300',
                                fontSize: 18,
                                marginTop: 6
                            }}
                        />
                        &nbsp;Atenção!
                    </>}
                    show={this.state.showDialogModal}
                />
            </div>
        )
    }
}

export class GroupBrowseEvents {
    onRenderFooterBrowserlize = (props: any, state: any) => {
        return <GroupComponent parentProps={props} parentState={state} />;
    }
}