import React from "react"
import { Link, withRouter } from "react-router-dom"
import { Button, CheckPicker, Container, Content, Divider, FlexboxGrid, Header, Icon, Input, InputGroup, Loader, Modal, Notification, Tag } from "rsuite"
import { Placeholder } from 'rsuite';
import axios from "../../../../../utilities/axios";
import { HeaderComponent } from "../components/HeaderComponent";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { item, zoomIn } from "../../../../motion/motionSettings";
import { getSituationChatColor, getSituationIcon } from "../../../../../utilities/situations/situationsFunctions";
import _ from "lodash";

const { Paragraph } = Placeholder;

interface IBrowserPage {
    nextRouter: ({ route }: { route: string, paramsRoute: Record<string, any> }) => void;
    // previousRoute: () => void;
}

export class BrowserPage extends React.Component<any, {}> {

    state: any = {
        data: [
        ],
        filteredData: [],
        selectedFilters: [2, 4],
        searchQuery: "",
        offset: 0,
        loading: true,
        loadingVirtualized: false,
        showModalInfo: false
    }

    public searchRef: any = null;
    public projectsContainerRef = React.createRef<HTMLDivElement>();

    componentDidMount(): void {
        this.loadProjects();
    }

    handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight }: any = this.projectsContainerRef.current;

        const atBottom = scrollTop + clientHeight >= scrollHeight - 1;

        console.log("handleScroll", scrollTop + clientHeight, scrollHeight);

        if (atBottom && this.state.hasMore) {
            this.setState((prevState: any) => ({ offset: prevState.offset + 1 }), () => this.loadProjects('virtualized'));
        }
    };

    loadProjects = (loadingType: 'normal' | 'virtualized' = 'normal', searchQuery = '') => {
        const offset = searchQuery ? 0 : this.state.offset;

        this.setState({ [loadingType === "normal" ? 'loading' : 'loadingVirtualized']: true });

        axios.get(`/api/v1/project/list`, {
            params: {
                status: this.state.selectedFilters,
                offset: offset,
                search: searchQuery
            }
        })
            .then(async (response) => {
                let replaceData = searchQuery
                    ? response.data.items
                    : _.uniqBy([...this.state.data, ...response.data.items], "project_id");

                const filteredData = replaceData.filter((item: any) => {
                    const matchesStatus = this.state.selectedFilters.includes(item.project_fk_situation_id);
                    return matchesStatus;
                });

                this.setState((prevState: any) => ({
                    data: _.orderBy(replaceData, "project_id", "desc"),
                    filteredData: _.orderBy(filteredData, "project_id", "desc"),
                    hasMore: response.data.items.length > 0
                }));
            })
            .catch((e) => {
            })
            .finally(() => {
                this.setState({ [loadingType === "normal" ? 'loading' : 'loadingVirtualized']: false });
            });
    }

    selectItem = ({ data }: { data: any }) => {
        this.props.history.push(this.props.location.pathname + "/" + data.project_id);

        this.props.nextRouter(
            {
                route: 'products',
                paramsRoute: {
                    project_id: data.project_id,
                    pathUrl: [{
                        labelCrud: 'Projeto',
                        labelValue: data.project_code + " - " + data.project_description + " - " + data.people.person_name,
                        value: 0
                    }],
                    data
                }
            }
        )
    }

    renderModalInfo = () => {
        return (
            <Modal
                show={this.state.showModalInfo}
                onHide={() => this.setState({ showModalInfo: false })}
            >
                <Modal.Header>
                    <h4>Informativo</h4>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: "column",
                            gap: 15,
                            padding: 2
                        }}
                    >
                        {["INICIADO", "URGENTE", "FINALIZADO",
                            // "Arquivado/Cancelado"
                        ].map((value: any, index: number) => {
                            const getBackgroundColor = (status: string) => {
                                switch (status) {
                                    case "INICIADO":
                                        return "#F18517";
                                    case "URGENTE":
                                        return "#C93337";
                                    case "FINALIZADO":
                                        return "#32CD32";
                                    // case "Arquivado/Cancelado":
                                    //     return "#9fa3a4";
                                    default:
                                        return "#FFF";
                                }
                            };

                            return (
                                <div
                                    key={index}
                                    style={{
                                        width: '100%',
                                        height: '4rem',
                                        // borderRadius: 10,
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: 'calc(100% - 4rem)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            flexWrap: 'wrap',
                                            background: '#f5f5f5',
                                            paddingLeft: 10,
                                            // borderRadius: 10,
                                        }}
                                    >
                                        <h6>STATUS {value}</h6>
                                    </div>
                                    <div
                                        style={{
                                            color: "#FFF",
                                            width: '4rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            background: getBackgroundColor(value),
                                            // borderTopRightRadius: 10,
                                            // borderBottomRightRadius: 10,
                                        }}
                                    >
                                        <i className={getSituationIcon(value)}></i>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        )
    }

    handleSearch = () => {
        const { searchQuery } = this.state;

        this.loadProjects('normal', searchQuery);
    };

    handleInputChange = (value: string) => {
        this.setState({ searchQuery: value });
    };

    renderProjects = () => {
        const getBackgroundColor = (status: string) => {
            console.log({ status })
            switch (status) {
                case "INICIADO":
                    return "#F18517";
                case "URGENTE":
                    return "#C93337";
                case "FINALIZADO":
                    return "#32CD32";
                // case "ARQUIVADO/CANCELADO":
                //     return "#9fa3a4";
                default:
                    return "#FFF";
            }
        };

        if (this.state.filteredData.length === 0) {
            return (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '200px',
                        width: '100%',
                        backgroundColor: '#f5f5f5',
                        borderRadius: '8px',
                        marginTop: '20px'
                    }}
                >
                    <h6>
                        Nenhum Projeto Encontrado!
                    </h6>
                </div>
            );
        }

        return (
            <div ref={this.projectsContainerRef} onScroll={this.handleScroll} style={{ maxHeight: '480px', overflowY: 'auto', padding: '10px', border: '1px solid #ccc', borderRadius: '8px', marginTop: 5 }}>
                {this.state.filteredData.map((value: any, index: number) => {
                    return (
                        <motion.div
                            key={index}
                            initial="visible"
                            animate="visible"
                            whileHover={{ scale: 1.010 }}
                            variants={zoomIn}
                            style={{
                                width: '100%',
                                display: "flex",
                                flexDirection: "column",
                                background: "#fafafa",
                                border: '1px solid rgba(0, 0, 0, 0.1)',
                                gap: 5,
                            }}
                        >
                            <div>
                                <div
                                    style={{
                                        width: '100%',
                                        minHeight: '4rem',
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        backgroundColor: getBackgroundColor(value.situations.situation_name),
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => this.selectItem({ data: value })}
                                >
                                    <div style={{
                                        width: '8%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}>
                                        <i className={getSituationIcon(value.situations.situation_name)} style={{ color: '#FFF' }}></i>
                                        <p style={{ color: "#FFF", fontSize: 12, marginTop: 5 }}>
                                            {value.situations.situation_name}
                                        </p>
                                    </div>
                                    <div style={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: "center",
                                        flexWrap: 'wrap',
                                        background: '#f5f5f5',
                                        paddingLeft: 10,
                                    }}>
                                        <FlexboxGrid>
                                            <FlexboxGrid.Item order={1} colspan={8}>
                                                <div style={{ width: "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    <label>Projeto</label>
                                                    <Link
                                                        to={`/dashboard/project/projects/${value.project_id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        onClick={(event) => event.stopPropagation()}
                                                    >
                                                        <br />
                                                        {value.project_code} - {value?.project_description}
                                                    </Link>
                                                </div>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item order={2} colspan={8}>
                                                <div style={{ width: "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    <label>Cliente</label>
                                                    <Link
                                                        to={`/dashboard/registrations/people/${value?.people?.person_id}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        onClick={(event) => event.stopPropagation()}
                                                    >
                                                        <br />
                                                        {value?.people?.person_name}
                                                    </Link>
                                                </div>
                                            </FlexboxGrid.Item>
                                            <FlexboxGrid.Item order={3} colspan={8}>
                                                <div style={{ width: "auto", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                                    <label>Endereço</label>
                                                    <br />
                                                    {value?.project_street}, {value?.project_number} {value?.project_district} - {value?.cities?.city_name}-{value?.states?.state_name}
                                                </div>
                                            </FlexboxGrid.Item>
                                        </FlexboxGrid>
                                    </div>
                                </div>
                                <div style={{
                                    width: "100%",
                                    minHeight: "20px",
                                    paddingLeft: 10,
                                    color: "#000",
                                }}>
                                    {(value.remainingProjectTime >= 1 && value.situations.situation_name !== "FINALIZADO") && (
                                        <p style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                                            Prazo restante do Projeto: {value.remainingProjectTime} dias
                                        </p>
                                    )}
                                </div>
                            </div>
                        </motion.div>
                    );
                })}
                {this.state.loadingVirtualized && (
                    <Loader content="Carregando..." size="sm" style={{ marginTop: 10 }} />
                )}
            </div>
        );
    }

    render(): React.ReactNode {
        return (
            <>
                {/* <HeaderComponent
                    // nextRouter={this.props.nextRouter}
                    previousRoute={this.props.previousRoute}
                /> */}
                <Content
                    style={
                        {
                            // display: 'flex',
                            margin: 'auto',
                            justifyContent: 'center',
                            alignContent: 'center'
                        }
                    }
                >
                    <div
                        style={
                            {

                                margin: 'auto'
                            }
                        }
                    >
                        <div
                            style={
                                {
                                    // display: 'flex',
                                    gap: 5,
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center'
                                }
                            }
                        >
                            <div
                                style={
                                    {
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: "center",
                                        flexWrap: 'wrap',
                                        background: '#FFF',
                                        paddingLeft: 10,
                                        // borderRadius: 10,
                                    }
                                }
                            >
                            </div>
                            <div
                                style={{
                                    color: "#575757",
                                    width: '100%',
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    gap: 5
                                }}
                            >
                                <CheckPicker
                                    searchable={false}
                                    defaultValue={this.state.selectedFilters}
                                    onChange={(value) => {
                                        this.setState({ selectedFilters: value, offset: 0 }, () => {
                                            this.handleSearch();
                                        });
                                    }}
                                    data={[
                                        {
                                            "label": "Iniciado",
                                            "value": 2,
                                        },
                                        {
                                            "label": "Urgente",
                                            "value": 4,
                                        },
                                        {
                                            "label": "Finalizado",
                                            "value": 5,
                                        },
                                    ]}
                                    placeholder="Selecione..."
                                    block
                                    renderMenuItem={(label: any, item) => {
                                        return (
                                            <div>
                                                <i className="fa fa-dot-circle" style={{ color: getSituationChatColor(label) }} /> {label}
                                            </div>
                                        );
                                    }}
                                    renderValue={(value: any, items: any) => {
                                        const selectedLabels = value.map((val: any) => {
                                            const item = items.find((dataItem: any) => dataItem.value === val);
                                            return item ? item.label : val;
                                        });
                                        console.log("selectedLabels", selectedLabels)
                                        return (
                                            <span>
                                                <span style={{ color: "#575757" }}>
                                                    Status:
                                                </span>{' '}
                                                {selectedLabels.join(', ')}
                                            </span>
                                        );
                                    }}
                                />
                                <InputGroup style={{ width: "100%" }}>
                                    <Input
                                        value={this.state.searchQuery}
                                        onChange={(value) => this.handleInputChange(value)}
                                        placeholder={"Pesquisar por..."}
                                        inputRef={ref => (this.searchRef = ref)}
                                        onKeyDown={(e: any) => {
                                            if (e.key === "Enter") {
                                                this.handleSearch();
                                            }
                                        }}
                                    />
                                    <InputGroup.Button onClick={this.handleSearch}>
                                        <i className="fas fa-search fa-fw mr-2"></i>
                                        Pesquisar
                                    </InputGroup.Button>
                                </InputGroup>
                                <Button size="md" appearance="primary" style={{ width: 50 }} onClick={() => this.setState({ showModalInfo: true })}>
                                    <i className="far fa-info-circle"></i>
                                </Button>
                            </div>
                            {this.renderModalInfo()}
                            {!this.state.loading ? (
                                <>
                                    {this.state.data.length == 0 ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '200px',
                                                width: '100%',
                                                backgroundColor: '#f5f5f5',
                                                borderRadius: '8px',
                                                marginTop: '20px'
                                            }}
                                        >
                                            <h6>
                                                Nenhum Projeto Encontrado!
                                            </h6>
                                        </div>
                                    ) : (
                                        <>
                                            {this.state.loading && (
                                                <Loader center content="Carregando..." size="lg" />
                                            )}
                                            {this.renderProjects()}
                                        </>
                                    )}
                                </>
                            ) : (
                                <Loader center content="Carregando..." size="lg" />
                            )}
                        </div>

                    </div>
                </Content>
            </>
        )
    }
}

export default withRouter(BrowserPage);