import { AxiosError, AxiosResponse } from "axios";
import React from "react";
import axios from "../../../../../utilities/axios";
import axiosClientChat from "../../../../../utilities/axiosClientChat";
import { Avatar, Placeholder } from "rsuite";

interface ChatClientRenderProps {
    typeCall: 'webUser' | 'webPerson';
    paramsRoute: Record<string, any>;
    style?: React.CSSProperties
    selectItem: ({ data }: { data: Record<string, any> }) => void
}


type State = {
    chatClients: any[];
    loadings: boolean;
}

export class ChatClientRender extends React.Component<any, any> {
    state = {
        chatClients: [],
        loadings: false,
    }

    componentDidMount(): void {
        const projectId = this.props?.match?.params?.projectId ? this.props?.match?.params?.projectId : this.props.projectId;

        if (this.props.typeCall === 'webUser') {
            axios
                .get(`/api/v1/chats/get/client`, {
                    params: {
                        project_id: projectId ? projectId : this.props.paramsRoute.projectId
                    }
                })
                .then((response: AxiosResponse) => {
                    this.setState({ chatClients: response.data.items })
                })
                .catch((e: AxiosError) => {

                })
                .finally(() => {
                    this.setState({ loadings: false })
                })
        }
        if (this.props.typeCall === "webPerson") {
            // console.log("paramsRoute", this.props.paramsRoute)
            axiosClientChat
                .get(`/chat-client/get/client`, {
                    params: {
                        project_id: projectId ? projectId : this.props.paramsRoute.projectId
                    }
                })
                .then((response: AxiosResponse) => {
                    this.setState({ chatClients: response.data.items })
                    console.log("chatClients", response.data.items)
                })
                .catch((e: AxiosError) => {

                })
                .finally(() => {
                    this.setState({ loadings: false })
                })
        }
    }

    selectChatClient = ({ data }: { data: Record<string, any> }) => {
        const projectId = this.props?.match?.params?.projectId ? this.props?.match?.params?.projectId : this.props.projectId;
        this.props.history.push(`/dashboard/project/chats/${projectId}/chat-client`);
        // this.props.nextRouter(
        //     {
        //         route: 'chatPage',
        //         paramsRoute: {
        //             step_item: {
        //                 ...data.steps[0]
        //             },
        //             step_id: data.steps[0].step_id,
        //             project_id: this.props.paramsRoute.projectId,
        //             pathUrl: [
        //                 ...this.props.paramsRoute.pathUrl,
        //                 {
        //                     labelCrud: 'Chats',
        //                     labelValue: data.steps[0].step_name,
        //                     value: 2
        //                 }
        //             ]
        //         }
        //     }
        // )
    }

    render(): React.ReactNode {
        return (
            <div
                style={this.props.style}
            >
                {!this.state.loadings && this.state.chatClients.length > 0 ? (
                    <>
                        {this.state.chatClients.map((value: Record<string, any>) => {
                            return (

                                <div
                                    style={
                                        {
                                            width: '100%',
                                            minHeight: '4rem',
                                            // borderRadius: 10,
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            backgroundColor: value?.steps[0].situation_chat_color ? value?.steps[0].situation_chat_color : '#131047',
                                            cursor: 'pointer'
                                        }
                                    }
                                    onClick={() => this.props.typeCall === "webUser" ? this.selectChatClient({ data: value }) : this.props.selectItem({ data: value })}
                                >
                                    <div
                                        style={
                                            {
                                                width: '8%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }
                                        }
                                    >
                                        <i className="far fa-comments fa-2x " style={{ color: '#fff' }}></i>
                                        <p
                                            style={{ color: "#fff" }}
                                        >
                                            {value?.steps[0].messages_not_viewed?.count ? value?.steps[0].messages_not_viewed?.count : 0}
                                        </p>
                                    </div>
                                    <div
                                        style={
                                            {
                                                width: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: "center",
                                                flexWrap: 'wrap',
                                                background: '#f5f5f5',
                                                paddingLeft: 10,
                                                // borderRadius: 10,
                                                // alignItems: "center",
                                            }
                                        }
                                    >
                                        <h6>
                                            <Avatar circle style={{ marginRight: 12 }}>
                                                <i className="far fa-handshake" style={{ color: '#575757' }}></i>
                                            </Avatar>
                                            {value.steps[0].step_name}
                                        </h6>
                                        <p
                                            style={{
                                                fontSize: 13,
                                                color: value.steps[0]?.delay_date?.color ? value.steps[0]?.delay_date?.color : '#131047'
                                                // color: 'yellow'
                                            }}
                                        >
                                            {value.steps[0].delay_date?.message}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                    </>
                ) : (
                    <Placeholder.Paragraph rows={2} active />
                )}
            </div>
        )
    }
}