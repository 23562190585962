import React from "react";
import { Modal } from "rsuite";


interface IModalInfoProps {

}


interface IModalInfoState {
    show: boolean;
}



export class ModalInfo extends React.Component<IModalInfoProps, IModalInfoState> {
    state = {
        show: false
    }

    render(): React.ReactNode {
        return (
            <>
                <Modal
                    show={this.state.show}
                    onHide={() => {
                        this.setState(
                            {
                                show: false,
                            }
                        )
                    }}
                >
                    <Modal.Header>
                        <h4>Informativo</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: "column",
                                gap: 15,
                                padding: 2
                            }}
                        >
                            {["INICIADO", "URGENTE", "FINALIZADO",
                                // "Arquivado/Cancelado"
                            ].map((value: any, index: number) => {
                                const getBackgroundColor = (status: string) => {
                                    switch (status) {
                                        case "INICIADO":
                                            return "#F18517";
                                        case "URGENTE":
                                            return "#C93337";
                                        case "FINALIZADO":
                                            return "#32CD32";
                                        // case "Arquivado/Cancelado":
                                        //     return "#9fa3a4";
                                        default:
                                            return "#FFF";
                                    }
                                };

                                return (
                                    <div
                                        key={index}
                                        style={{
                                            width: '100%',
                                            height: '4rem',
                                            // borderRadius: 10,
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.4)',
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: 'calc(100% - 4rem)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                flexWrap: 'wrap',
                                                background: '#f5f5f5',
                                                paddingLeft: 10,
                                                // borderRadius: 10,
                                            }}
                                        >
                                            <h6>{value}</h6>
                                        </div>
                                        <div
                                            style={{
                                                color: "#FFF",
                                                width: '4rem',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                background: getBackgroundColor(value),
                                                // borderTopRightRadius: 10,
                                                // borderBottomRightRadius: 10,
                                            }}
                                        >
                                            <i className="fa fa-circle fa-2x"></i>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </Modal.Body>
                </Modal>
                <div
                    onClick={() => {
                        this.setState({ show: true })
                    }}
                    style={
                        {
                            cursor: 'pointer'
                        }
                    }
                >
                    <i
                        className="fas fa-info-circle fa-2x"
                    ></i>
                </div>
            </>
        )
    }
}