import axios from "../../utilities/axios";

export const requiredColumns = async (idSituation: string | number, functionThis: any, prefix: string) => {
    axios
        .get(`/api/v1/situation/${idSituation}/${prefix}`)
        .then((res) => {
            let columns = res.data?.columns;
            for (let i in res.data?.columns) {
                if (res.data?.columns[i]?.readonly) {
                    functionThis.setField(i, {
                        readonly: columns[i].readonly
                    })
                }
                if (res.data?.columns[i]?.disabled) {
                    functionThis.setField(i, {
                        disabled: columns[i].disabled
                    })
                }
            }
        })
}

export const getSituationIcon = (situation: string) => {
    let situationIcon;

    switch (situation.toUpperCase()) {
        case "AGUARDANDO INICIO":
            situationIcon = "far fa-clock fa-2x";
            break;
        case "INICIADO":
            situationIcon = "fas fa-play-circle fa-2x";
            break;
        case "URGENTE":
            situationIcon = "far fa-exclamation-circle fa-2x";
            break;
        case "ARQUIVADO/CANCELADO":
            situationIcon = "far fa-archive fa-2x";
            break;
        case "FINALIZADO":
            situationIcon = "far fa-check fa-2x";
            break;
        default:
            situationIcon = "far fa-clock fa-2x";
            break;
    }

    return situationIcon;
}

export const getSituationChatIcon = (situationChat: string) => {
    let situationChatIcon;

    switch (situationChat.toUpperCase()) {
        case "NÃO INICIADO":
            situationChatIcon = "far fa-circle fa-2x";
            break;
        case "INICIADO":
            situationChatIcon = "fas fa-play-circle fa-2x";
            break;
        case "URGENTE":
            situationChatIcon = "far fa-exclamation-circle fa-2x";
            break;
        case "FINALIZADO":
            situationChatIcon = "far fa-check fa-2x";
            break;
        default:
            situationChatIcon = "far fa-clock fa-2x";
            break;
    }

    return situationChatIcon;
}

export const getSituationChatColor = (situationChat: string) => {
    let situationChatColor;

    switch (situationChat.toUpperCase()) {
        case "INICIADO":
            situationChatColor = "#F18517";
            break;
        case "URGENTE":
            situationChatColor = "#C93337";
            break;
        case "FINALIZADO":
            situationChatColor = "#32CD32";
            break;
        default:
            situationChatColor = "#575757";
            break;
    }

    return situationChatColor;
}