import { useEffect, useState } from "react";
import { IconButton, Loader } from "rsuite";
import config from "../../../../../globals";
import axios, { type AxiosInstance } from "axios";
import axiosClientChat from "../../../../../utilities/axiosClientChat";

interface ChatFileProps {
  url: string
  message: any
}

const ChatFile = ({message, url}: ChatFileProps) => {
  const [name, setName] = useState("")
  const [extension, setExtension] = useState("")

  const getIconOfExtension = () => {
    if (["txt", "md", "csv", "xml"].includes(extension))
      return ("fas fa-file-alt")
    if (["doc", "docx", "dot", "dotx", "docm", "dotm", "odt", "ott"].includes(extension))
      return ("fas fa-file-word")
    if (["ppt", "pptx", "pps", "ppsx", "pptm", "ppsm", "pot", "potx", "potm", "odp", "otp"].includes(extension))
      return ("fas fa-file-powerpoint")
    if (["xls", "xlsx", "xlsm", "xlt", "xltx", "xltm", "xlsb", "ods", "ots"].includes(extension))
      return ("fas fa-file-excel")
    if (["zip", "rar", "7z", "tar", "gz", "bz2", "xz", "z", "iso", "dmg"].includes(extension))
      return ("fas fa-file-archive")
    if (["pdf"].includes(extension))
      return ("fas fa-file-pdf")

    return ("fas fa-file")
  }

  useEffect(() => {
    let extension: any = url.split(".").pop()
    if (extension.length < 5) {
      extension = extension.toLowerCase()
    } else {
      extension = ''
    }

    let name: any = url.split("/").pop()
    if (name.indexOf("_") != -1) {
      name = name.substring(name.indexOf("_")+1)
    }
    setName(name)
    setExtension(extension)
  }, [])

  const downloadFile = () => {
    let definedAxios: AxiosInstance = axios;
    if (localStorage.getItem('chatToken')) {
        definedAxios = axiosClientChat;
    }

    let extractedPath = url.substring(url.indexOf("storage"));
    // let baseUrl = config.url;
    // let extractedPath = url;
    // if (config.url.includes("localhost")) {
    //   baseUrl = config.url.split('25565').join('25567');
    //   extractedPath = url.substring(url.indexOf(baseUrl) + baseUrl.length);
    // }

    definedAxios.get(extractedPath, {
        responseType: 'blob'
    }).then((response: any) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
        link.remove();
    });
}

  return (
    <div 
      style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "10px",
          borderRadius: "7px",
          backgroundColor: "rgb(132, 162, 108, .3)",
          padding: "6px",
          marginInline: "4px"
      }}>
        <i className={`${getIconOfExtension()}`} style={{fontSize: "28px", paddingLeft: "8px"}}></i>
        <div 
          style={{
            display: "flex", 
            flexDirection: "column", 
            // gap: "4px"
          }}
        >
          <span style={{fontSize: extension.length ? "12px" : "16px"}}>{name}</span>
          {extension.length > 1 && (
            <span style={{fontWeight: "bolder", fontSize: "12px"}}>.{extension.toUpperCase()}</span>
          )}
        </div>
        <IconButton
          onClick={downloadFile}
          circle
          disabled={message.temp_id}
          appearance="link"
          style={{padding: 0}}
          icon={<i className="fas fa-download" style={{fontSize: "16px"}}></i>}
        />
      </div>
  );
};

export default ChatFile;
