import React from "react";
import { Button, Input, Nav, Panel, Placeholder, Table } from "rsuite";
import { OmieProducts } from "./omie/OmieProducts";
import { OmieServices } from "./omie/OmieServices";
import { OmieClients } from "./omie/OmieClients";
import { OmieServicesOS } from "./omie/OmieServicesOS";


const { Column, HeaderCell, Cell, Pagination } = Table;

export class IntegrationOmiePage extends React.Component<any, {}> {
    public state = {
        currentTab: 0,
        values: {},
        tabs: [           
            {
                key: "os",
                component: <OmieServicesOS />,
                name: "Ordens de serviços (Projetos)"
            }, {
                key: "clients",
                component: <OmieClients />,
                name: "Clientes. / Fornec. (Pessoas)"
            },
            {
                key: "services",
                component: <OmieServices />,
                name: "Cad. Serviços (Itens)"
            },
            {
                key: "products",
                component: <OmieProducts />,
                name: "Produtos (Itens)"
            }
        ],
        isLoading: false
    }

    onBlurField = (value: any) => {
        this.setState({
            values: {
                ...this.state.values, ...value
            }
        })
        this.props.onChange({ OMIE: { ...this.state.values, ...value } })
    }

    renderNavbar = () => {
        return (<>
            <Nav appearance="subtle" onSelect={(eventKey) => this.setState({ currentTab: eventKey })} style={{ background: "white" }} activeKey={this.state.currentTab}>
                {this.state.tabs?.map((tab, index) => {
                    return (
                        <Nav.Item eventKey={index}>
                            {tab?.name}
                        </Nav.Item>)
                })}
            </Nav>

        </>)
    }

    renderComponent = () => {
        return (<>
            {this.state.tabs[this.state.currentTab] && (
                <>
                    {React.cloneElement(this.state.tabs[this.state.currentTab].component, { ...this.props, ...this.state })}
                </>
            )}
        </>)
    }

    render(): React.ReactNode {
        return (<>
            <div style={{ width: "100%", marginTop: 8 }}>
                {this.renderNavbar()}
                <Panel style={{ background: "white" }}>
                    {!this.state.isLoading ?
                        (this.renderComponent())
                        : (
                            <Placeholder.Paragraph active rows={8}></Placeholder.Paragraph>
                        )}
                </Panel>
            </div>
            {/* <Table
                height={400}
                data={[{}]}
                onRowClick={rowData => {
                    console.log(rowData);
                }}
            >
                <Column width={70} align="center" fixed>
                    <HeaderCell>Id</HeaderCell>
                    <Cell dataKey="id" />
                </Column>
                <Column width={200}>
                    <HeaderCell>...</HeaderCell>
                    <Cell style={{ padding: '6px' }}>
                        {(rowData: { id: any; }) => (
                            <Button appearance="link" onClick={() => alert(`id:${rowData.id}`)}>
                                Edit
                            </Button>
                        )}
                    </Cell>
                </Column>
            </Table> */}

        </>)
    }
}