// alert(window.location.hostname)
var arr = window.location.href.split("/");
var url = arr[0] + "//" + arr[2]
const config: any = {
    url: `${url}`,
    module_colors: {
        registrations: "#00b3ab",
        configurations: "#00b3ab",
        relatorios: "#60e2be",
        "project": "##00b3ab"
    },
    main: [

    ],
    modules: [
        { label: "Projetos", url: "project", color: "#5d5d5d", icon: "fas fa-toolbox", fullName: "projetos" },
        { label: "Cadastros", url: "registrations", color: "#5d5d5d", icon: "fas fa-tasks", fullName: "Cadastros" },
        { label: "Configurações", url: "configurations", color: "#5d5d5d", icon: "fas fa-cog", fullName: "Configurações" },
        { label: "Home", url: "dashboard", color: "#6a6a6a", icon: "fas fa-home", fullName: "Home" },
    ]
};

export var getModule = function (moduleName: string) {
    for (var i in config.modules) {
        if (config.modules[i].url === moduleName) {
            return config.modules[i];
        }
    }
    for (var i in config.main) {
        if (config.main[i].url === moduleName) {
            return config.main[i];
        }
    }
};
export default config;
