import { useCallback, useEffect, useRef, useState } from "react";
import { Slider } from "rsuite";
import { useAudioPlayer } from "./ChatAudioContext";

interface ChatAudioProps {
  url: string;
}

const ChatAudioPlayer = ({url}: ChatAudioProps) => {
  const [playing, setPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [current, setCurrent] = useState(0)

  const audioRef = useRef<HTMLAudioElement>(new Audio(url));
  const {playAudio, stopAudio} = useAudioPlayer()

  function togglePlay() {
    setPlaying(() => {
      playing ? stopAudio() : playAudio(audioRef.current)
      return !playing
    })
  }

  const getDuration = useCallback((event: any) => {
    event.target.removeEventListener('timeupdate', getDuration)
    event.target.currentTime = 0
    setDuration(event.target.duration)
  }, [])

  useEffect(() => {
	audioRef.current.loop = false
	audioRef.current.volume = 0.75
  audioRef.current.addEventListener("ended", () => setPlaying(false))
  audioRef.current.addEventListener("pause", () => setPlaying(false))
  audioRef.current.addEventListener("play", () => setPlaying(true))
  audioRef.current.addEventListener("timeupdate", () => setCurrent(audioRef.current.currentTime))
    if (audioRef.current.duration === Infinity || isNaN(Number(audioRef.current.duration))) {
      audioRef.current.currentTime = 1e101
      audioRef.current.addEventListener('timeupdate', getDuration)
    }
  }, [getDuration])

  const changeProgress = (newValue: number) => {
    audioRef.current.currentTime = (newValue/100)*duration
    stopAudio()
  }

  const formatTime = (time: number) => {
    const minutes = String(Math.floor(Math.round(time) / 60)).padStart(2, "0")
    const seconds = String(Math.round(time) % 60).padStart(2, "0");
    return `${minutes}:${seconds}`
  }
  
  return (
	<div style={{width: "100%"}}>
		<div style={{ display: "flex", width: "100%", gap: "8px", alignItems: "center"}}>
			<button onClick={togglePlay} style={{backgroundColor: "transparent"}}>
			{playing
        ? (<i className="fas fa-solid fa-pause" style={{color: "#075e54", userSelect: "none"}}></i>)
        : (<i className="fas fa-solid fa-play" style={{color: "#075e54", userSelect: "none"}}></i>)}
			</button>
			<div style={{width: "100%", display: "flex", flexDirection: "column", gap: "3px", userSelect: "none"}}>
				<Slider style={{width: "100%", color: "#00b3ab", paddingInline: "5px"}} min={0} max={100} value={(current/duration)*100} progress tooltip={false} onChange={changeProgress}/>
				<div style={{display: "flex", justifyContent: "space-between"}}>
					<span style={{fontWeight: "bold", opacity: 0.75, color: "#323233", fontSize: "12px"}}>{formatTime(current)}</span>
					<span style={{fontWeight: "bold", opacity: 0.75, color: "#323233", fontSize: "12px"}}>{formatTime(duration)}</span>
				</div>
			</div>
		</div>
	</div>
  )
}

export default ChatAudioPlayer