import React from "react";
import { UserContext } from "./authProvider/main";

export class PermissionTab extends React.Component<any, {}> {
    static contextType = UserContext;

    static canUse(key: string, user: any) {
        if (user?.role?.role_permissions) {
            if (user?.role?.role_id === 2 || user?.role?.role_id === 1) {
                return true;
            }
            let perms: string[] = user?.role?.role_permissions.map((item: any) => {
                return item.role_permission_value;
            })

            for (var index in perms) {
                const perm = perms[index];

                if (perm === 'projects') {
                    return true
                }

                perms = [...perms, perm + '-browse', perm + '-read', perm + '-edit', perm + '-add', perm + '-delete']
            }

            return perms.includes(key);
        }
        return false;
    }

    render() {
        if (this.props.name) {
            if (PermissionTab.canUse(this.props.name ?? '', this.context.data)) {
                return this.props.children;
            }
            return null;
        }
        return this.props.children;
    }
}