import React from "react";
import { Button, Input, Nav, Panel } from "rsuite";

export class IntegrationOmieConfigPage extends React.Component<any, {}> {
    public state = {
        values: { APP_KEY: null, APP_SECRET: null, ...this.props.values?.OMIE },
    }



    onSubmitButton = () => {
        this.setState({
            isLoading: true
        })
    }

    onBlurField = (value: any) => {
        this.setState({
            values: {
                ...this.state.values, ...value
            }
        })
        this.props.onChange({ OMIE: { ...this.state.values, ...value } })
    }

    render(): React.ReactNode {
        const { OMIE } = this.props.values
        return (<>
            <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <b>
                        <label>OMIE APP_KEY <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={OMIE?.APP_KEY} onChange={(value) => this.onBlurField({ APP_KEY: value })}></Input>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <b>
                        <label>OMIE APP_SECRET <a style={{ color: "red" }}>*</a> </label>
                    </b>
                    <Input defaultValue={OMIE?.APP_SECRET} onChange={(value) => this.onBlurField({ APP_SECRET: value })}></Input>
                </div>
            </div>
        </>)
    }
}