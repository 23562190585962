import React from "react";
import { Button, Input, Nav, Panel, Placeholder, Table, Pagination, Checkbox, List, Modal, DatePicker, Notification, Tag, SelectPicker, CheckPicker, Drawer } from "rsuite";
import axios from "../../../../utilities/axios";
import _ from "lodash"
import dayjs from "dayjs";


const { Column, HeaderCell, Cell } = Table;

const locale = {
    sunday: "DON",
    monday: "SEG",
    tuesday: "TER",
    wednesday: "QUA",
    thursday: "QUI",
    friday: "SEX",
    saturday: "SAB",
    ok: "Ok",
    today: "Hoje",
    yesterday: "Ontem",
    hours: "Horas",
    minutes: "Minutos",
    seconds: "Segundos",
};

export class OmieClients extends React.Component<any, {}> {
    public state = {
        values: [],
        isLoading: false,
        currentPage: 1,
        totalPages: 0,
        totalRegisters: 0,
        registerPerPage: 10,
        selectedValues: [],
        selectedValuesData: [],
        filter: {},
        showModal: false,
        successMessage: ""
    }

    getData = (page = this.state.currentPage) => {
        this.setState({ isLoading: true })
        axios.get("/api/v1/integrations/omie/customers", {
            params: {
                pagina: this.state.currentPage,
                registros_por_pagina: this.state.registerPerPage,
                ...this.state.filter

            }
        }).then((res) => {
            this.setState({
                values: res.data.data.clientes_cadastro,
                currentPage: res.data.data.pagina,
                totalPages: res.data.data.total_de_paginas,
                totalRegisters: res.data.data.total_de_registros
            })
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    changePage = (page: any) => {
        this.setState({ currentPage: page }, () => {
            this.getData()
        })

    }

    toggleItem = (item: any) => {
        let array = this.state.selectedValues
        let arrayData: any = this.state.selectedValuesData
        let newData: any = []
        let codigo = item?.codigo_cliente_omie
        // Verifica se o item já existe no array
        if (_.includes(array, codigo)) {
            // Remove o item se ele já existir
            newData = _.without(array, codigo);
            arrayData = arrayData.filter((i: any) => i.codigo_cliente_omie != codigo)
        } else {
            // Adiciona o item se ele não existir
            newData = [...array, codigo];
            arrayData.push(item)
        }
        // Remove duplicatas usando Set
        // newData = Array.from(new Set(newData));

        return this.setState({
            selectedValues: newData,
            selectedValuesData: arrayData

        })
    };

    componentDidMount(): void {
        this.getData()
    }

    changeFilter = (field: any, item: any) => {
        let newFilter: any = {
            ...this.state.filter,
        }

        if (field.includes("codigo")) {
            if (item?.length >= 3) {
                newFilter = {
                    ...newFilter,
                    clientesPorCodigo: [
                        {
                            [field]: item
                        }
                    ]
                }
            } else {
                delete newFilter['clientesPorCodigo']
            }

        } else if (field.includes("clientesFiltro")) {
            let fieldName = field.split('-')[1] || ""

            if (item?.length >= 3) {
                newFilter = {
                    ...newFilter,
                    clientesFiltro: [
                        {
                            ...newFilter?.clientesFiltro,
                            [fieldName]: item
                        }
                    ]
                }
            } else {
                delete newFilter['clientesFiltro']
            }
        }
        else {
            newFilter = {
                ...newFilter,
                [field]: item.includes("/") ? item : `%${item}%`
            }
        }


        if (item?.length < 3) {
            delete newFilter[field]
        }
        this.setState({
            filter: { ...newFilter },
            currentPage: 1
        })
    }

    submitImportData = () => {
        this.setState({ isLoading: true, successMessage: "" })
        axios.post("/api/v1/integrations/omie/import/customers", {
            data: this.state.selectedValuesData
        }).then((res) => {
            if (res.status == 200) {
                this.setState({
                    successMessage: `Inserido: ${res?.data?.totalInserted} \n | Atualizado ${res?.data?.totalUpdated}`
                })

                Notification.success({
                    title: "Sucesso",
                    description: `Inserido: ${res?.data?.totalInserted} \n | Atualizado ${res?.data?.totalUpdated}`
                })
            }
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }


    modalAction = (action = "close") => {
        return this.setState({ showModal: action == "close" ? false : true })
    }

    renderModalImport = () => {
        return (<>
            <Drawer
                full
                show={this.state.showModal}
                onHide={() => this.modalAction()}
            >
                <Drawer.Header>
                    <Drawer.Title> Importação Omie | Cliente/Fornec</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <div>
                        <Table
                            height={400}
                            data={this.state.selectedValuesData || []}
                            loading={this.state.isLoading}
                        >
                            <Column flexGrow={2} width={160} align="left" fixed>
                                <HeaderCell>Código</HeaderCell>
                                <Cell dataKey="codigo_cliente_omie" />
                            </Column>
                            <Column flexGrow={2} width={180} align="left" fixed>
                                <HeaderCell>CPF/CNPJ</HeaderCell>
                                <Cell dataKey="cnpj_cpf" />
                            </Column>
                            <Column flexGrow={4} width={400} align="left" fixed>
                                <HeaderCell>Razão</HeaderCell>
                                <Cell dataKey="razao_social" />
                            </Column>
                            <Column flexGrow={2} width={300} align="left" fixed>
                                <HeaderCell>Nome Fantasia</HeaderCell>
                                <Cell dataKey="nome_fantasia" />
                            </Column>
                            <Column flexGrow={3} width={300} align="left" fixed>
                                <HeaderCell>Tags</HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        return (<>
                                            {rowData?.tags.map((item: any, index: any) => {
                                                return (<>
                                                    <Tag>
                                                        {item?.tag}
                                                    </Tag>
                                                </>)
                                            })}

                                        </>)
                                    }}
                                </Cell>
                            </Column>
                            <Column width={70} align="left" fixed>
                                <HeaderCell>Ação</HeaderCell>
                                <Cell>
                                    {(rowData: any) => {
                                        return (<>
                                            <Button style={{ marginTop: -8 }} appearance="primary" color="red" onClick={() => this.toggleItem(rowData)}><i className="fas fa-fw fa-trash"></i></Button>

                                        </>)
                                    }}
                                </Cell>
                            </Column>
                        </Table>
                    </div>
                </Drawer.Body>
                <Drawer.Footer>
                    <Button onClick={() => this.submitImportData()} loading={this.state.isLoading} appearance="primary" color="green"><i className="fas fa-fw fa-sync"></i> Importar Dados</Button>
                    <Button onClick={() => this.modalAction()} appearance="subtle">Fechar</Button>
                </Drawer.Footer>
            </Drawer >          
        </>)
    }

    render(): React.ReactNode {
        return (<>
            <div style={{ width: "100%" }}>
                <Panel bordered>
                    <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: 5 }}>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <label><b>De</b></label>
                                <DatePicker locale={locale} format="DD/MM/YYYY" onChange={(value) => { this.changeFilter("filtrar_por_data_de", dayjs(value).format("DD/MM/YYYY")) }} />
                            </div>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <label><b>Até</b></label>
                                <DatePicker locale={locale} format="DD/MM/YYYY" onChange={(value) => { this.changeFilter("filtrar_por_data_ate", dayjs(value).format("DD/MM/YYYY")) }} />
                            </div>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <label><b>Código Omie</b></label>
                                <Input onChange={(value) => { this.changeFilter("codigo_cliente_omie", value) }} />
                            </div>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <label><b>CPF/CNPJ</b></label>
                                <Input onChange={(value) => { this.changeFilter("clientesFiltro-cnpj_cpf", value) }} />
                            </div>

                            <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                                <label><b>Razão Social</b></label>
                                <Input onChange={(value) => { this.changeFilter("clientesFiltro-razao_social", value) }} />
                            </div>
                        </div>
                        <div style={{ width: "100%", display: "flex", flexDirection: "row", gap: 5 }}>
                            <Button style={{ height: 40, marginTop: 16 }} appearance="primary" color="blue" onClick={() => { this.getData(1) }}><i className="fas fa-fw fa-search"></i> Buscar</Button>
                            <Button style={{ height: 40, marginTop: 16 }} appearance="primary" color="violet" onClick={() => { this.setState({ filter: {} }, () => this.getData()) }}><i className="fas fa-fw fa-filter"></i> Limpar Filtro</Button>
                            <Button style={{ height: 40, marginTop: 16 }} appearance="primary" color="green" onClick={() => { this.modalAction("open") }}><i className="fas fa-fw fa-sync"></i> Importar</Button>
                        </div>
                    </div>
                </Panel>
                {this.state.showModal && this.renderModalImport()}
                <Table
                    height={400}
                    data={this.state.values || []}
                    loading={this.state.isLoading}
                >
                    <Column width={50} align="left" fixed>
                        <HeaderCell>#</HeaderCell>
                        <Cell>
                            {(rowData: any) => {
                                return (<>
                                    {/* codigo_cliente_omie */}
                                    <Checkbox checked={_.includes(this.state.selectedValues, rowData?.codigo_cliente_omie)} onChange={(value, check) => {
                                        this.toggleItem(rowData)
                                    }} />
                                </>)
                            }}
                        </Cell>
                    </Column>
                    <Column flexGrow={2} width={160} align="left" fixed>
                        <HeaderCell>Código</HeaderCell>
                        <Cell dataKey="codigo_cliente_omie" />
                    </Column>
                    <Column flexGrow={2} width={180} align="left" fixed>
                        <HeaderCell>CPF/CNPJ</HeaderCell>
                        <Cell dataKey="cnpj_cpf" />
                    </Column>
                    <Column flexGrow={4} width={400} align="left" fixed>
                        <HeaderCell>Razão</HeaderCell>
                        <Cell dataKey="razao_social" />
                    </Column>
                    <Column flexGrow={2} width={300} align="left" fixed>
                        <HeaderCell>Nome Fantasia</HeaderCell>
                        <Cell dataKey="nome_fantasia" />
                    </Column>
                    <Column flexGrow={3} width={300} align="left" fixed>
                        <HeaderCell>Tags</HeaderCell>
                        <Cell>
                            {(rowData: any) => {
                                return (<>
                                    {rowData?.tags.map((item: any, index: any) => {
                                        return (<>
                                            <Tag>
                                                {item?.tag}
                                            </Tag>
                                        </>)
                                    })}

                                </>)
                            }}
                        </Cell>
                    </Column>
                </Table>

                <div style={{ padding: 20 }}>
                    Total: {this.state.totalRegisters}
                    <Pagination
                        style={{ float: "right" }}
                        activePage={this.state.currentPage}
                        pages={this.state.totalPages}
                        maxButtons={5}
                        prev={true}
                        first={true}
                        next={true}
                        last={true}
                        onSelect={(page: number) => this.changePage(page)}
                    />
                </div>

            </div>
        </>)
    }
}