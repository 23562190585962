import type React from "react";
import type { ReactElement, StyleHTMLAttributes } from "react";

interface ChatReplyProps {
  text: string,
  user_name: string
  children?: ReactElement
  style?: any
}

const ChatReply = ({text, user_name, children, style}: ChatReplyProps) => {
  return (
    <div 
      style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          borderRadius: "7px",
          backgroundColor: "rgb(132, 162, 108, .4)",
          paddingInline: "6px",
          paddingBlock: "4px",
          borderLeft: "rgba(7, 94, 84) solid 3px",
          ...style
      }}>
        <div style={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            flex: 1
        }}>
            <span style={{fontWeight: "bold", fontStyle: 'italic', color: "rgba(7, 94, 84)", flex: 1, fontSize: 12, textAlign: 'left'}}>{user_name}</span>
            <span style={{color: "rgba(7, 94, 84, .6)", fontSize: 11, width: 0}}>{text}</span>
        </div>
        {children}
      </div>
  );
};

export default ChatReply;
