import React, { RefObject, createRef } from "react";

import { Container, Content, Dropdown, Icon, IconButton, Modal, Notification, Placeholder, Popover, Whisper } from "rsuite";

import { Button, Input } from "rsuite";
import dayjs from "dayjs";
import { io } from "../../../../../utilities/io";
import config from "../../../../../globals";
import _ from "lodash";
import { DownloadImage } from "../../../../chatClient/DownloadImage";
import { CameraComponent } from "../../../../chatClient/CameraComponent";
import axios from "../../../../../utilities/axios";
import { HeaderComponent } from "../components/HeaderComponent";
import { ChatUpdateStatus, typeSendDate } from "../components/ChatUpdateStatus";
import { ChatForward } from "../components/ChatForward";
import AudioRecorder from "../components/ChatAudioRecorder";
import { ChatForwardHolder } from "../components/forward/ChatForwardHolder";
import ChatFileUploader from "../components/ChatFileUploader";
import ChatAudioPlayer from "../components/ChatAudioPlayer";
import { AudioProvider } from "../components/ChatAudioContext";
import ChatReply from "../components/ChatReply";
import ChatFile from "../components/ChatFile";
import ChatImage from "../components/ChatImage";

interface ChatPageProps {
    nextRouter: ({ route }: { route: string, paramsRoute: Record<string, any> }) => void;
    previousRoute: ({ route, dataParams }: { route: string, dataParams: Record<string, any> }) => void;
    paramsRoute: Record<string, any>;
}

interface MessageType {
    _id: number;
    text: string;
    createdAt: string;
    user: {
        name: string;
        _id: number;
    };
    forward?: boolean;
    reply?: {
        user_name: string
        user_id: number
        text: string 
        message_id: number
    }
    temp_id?: number
    read: boolean
    ref: any
}

interface ChatState {
    messages: MessageType[];
    newMessage: string;
    lastMessageRef: RefObject<HTMLDivElement> | null;
    data: any;
    title: any;
    isOpenChat: boolean;
    history: any;
    match: any;
    idRoom: any;
    oldState?: any;
    onStarted: boolean;
    userData: {
        user_name: string,
        user_id: number | null
    };
    modalImage: boolean;
    modalUrlImage: string;
    offSet: number;
    cameraIsOn: boolean;
    loading: boolean;
    chatsForward: any[],
    voiceRecording: boolean,
    audioUrl?: String | null,
    senderAudio: any,
    uploadFileOn: boolean,
    forwardingMessage: number | null,
    replyingMessage: MessageType | null,
    replyingMessageActive: boolean
}

export class ChatPage extends React.Component<any, any> {
    constructor(props: ChatPageProps) {
        super(props);
        this.state = {
            messages: [],
            newMessage: "",
            lastMessageRef: createRef<HTMLDivElement>(),
            idRoom: null,
            oldState: this.props?.paramsRoute || {},
            onStarted: false,
            userData: {
                user_name: '',
                user_id: null
            },
            modalImage: false,
            modalUrlImage: '',
            offSet: 1,
            cameraIsOn: false,
            data: null,
            title: null,
            isOpenChat: false,
            history: null,
            match: null,
            loading: true,
            chatsForward: [],
            voiceRecording: false,
            audioUrl: null,
            senderAudio: 1,
            uploadFileOn: false,
            forwardingMessage: null,
            replyingMessage: null,
            replyingMessageActive: false,
            paramsRoute: null
        };
        this.sendDateMessage = this.sendDateMessage.bind(this);
    }

    scrolledToBottom: boolean = false

    componentDidMount(): void {
        let { projectId, projectProductId, ppgstfId } = this.props.match.params;
        projectId = projectId ? projectId : this.props.projectId;
        projectProductId = projectProductId ? projectProductId : this.props.projectProductId;
        ppgstfId = ppgstfId ? ppgstfId : this.props.ppgstfId;

        axios
            .get('/api/v1/me')
            .then((response): any => {
                this.setState({ userData: response.data.user })
            });

        this.setState({ loading: true });
        if (projectProductId) {
            axios
                .get(`/api/v1/project_products/${projectProductId}`)
                .then((response) => {

                    axios
                        .get(`/api/mobile/steps/${projectProductId}/${response.data.item.project_product_fk_product_id}/get`)
                        .then((res: any) => {

                            for (const i in res.data.item) {
                                if (res.data.item[i].steps[0].chat_message_fk_ppgstf_id === Number(ppgstfId)) {
                                    this.setState({ paramsRoute: res.data.item[i] }, () => {
                                        this.handleStepItem(response.data.item, res.data.item[i]);
                                        this.startedSockets({ userToken: localStorage.getItem('chatToken') });
                                        this.setState({ loading: false });
                                    });
                                }
                            }

                            let newData = res.data.item
                            if (res.data.item) {
                                newData.push({
                                    ...newData?.[0],
                                    project_product_group_step_table_flow_fk_step_id: 9,
                                    toChatClient: true,
                                    steps: [
                                        {
                                            step_id: 9,
                                            step_name: "CHAT DO CLIENTE",

                                        }
                                    ]
                                })
                                this.setState({
                                    chatsForward: newData
                                })
                            }
                        })
                });
        } else {
            const baseURL = "/dashboard/project/chats";

            axios.get(`/api/v1/projects/${projectId}`)
                .then(async (response) => {
                    if (response.data?.redirect) {
                        this.props.history.push(baseURL);
                        Notification.warning({
                            title: "Usuário sem permissão de acesso!"
                        });
                    }

                    const projectData = response.data.item;
                    axios
                        .get(`/api/v1/forward-chat/client/get`, {
                            params: {
                                project_id: projectId
                            }
                        })
                        .then((res: any) => {
                            this.setState({
                                paramsRoute: {
                                    step_item: {
                                        step_id: 9,
                                        step_name: "CHAT DO CLIENTE",
                                    },
                                    steps: [
                                        {
                                            step_id: 9,
                                            step_name: "CHAT DO CLIENTE",
                                        }
                                    ],
                                    step_id: 9,
                                    project_id: projectId,
                                    pathUrl: [
                                        {
                                            labelCrud: "Projeto",
                                            labelValue: projectData.project_code + " - " + projectData.project_description + " - " + projectData.people.person_name,
                                            link: `${baseURL}/${projectId}`,
                                            value: 0
                                        },
                                        {
                                            labelCrud: "Chats",
                                            labelValue: "CHAT DO CLIENTE",
                                            value: 1
                                        }
                                    ]
                                }
                            });
                            this.setState({ chatsForward: res.data.items }, () => {
                                this.startedSockets({ userToken: localStorage.getItem('chatToken') })
                                this.setState({ loading: false });
                                // console.log("debugsparamsRoute", this.state.paramsRoute);
                            });
                        });
                });
        }
        this.startedSockets({ userToken: localStorage.getItem('chatToken') })
    }

    componentWillUnmount(): void {
        io.removeAllListeners("receiveMessagesChat");
        io.removeAllListeners("readMessagesChat");

        // Remoção de caminho Chats na exibição de Chats do ChatList
        // const paramsRoute = this.props?.paramsRoute
        // if (paramsRoute?.pathUrl) {
        //     for (const i in paramsRoute.pathUrl || []) {
        //         if ( paramsRoute.pathUrl[i].labelCrud == "Chats") {
        //             paramsRoute.pathUrl[i] = null
        //         }
        //     }

        //     paramsRoute.pathUrl = paramsRoute.pathUrl.filter(Boolean)
        //     this.props.nextRouter({
        //         route: 'chatList',
        //         paramsRoute
        //     })
        // }
    }

    handleStepItem = (data: any, stepsData: any) => {
        const baseURL = "/dashboard/project/chats";
        // console.log("handleProjectProductItem", data, stepsData);

        // console.log("stepitemtemt", data)

        const filteredRoutes = this.props.paramsRoute.pathUrl.filter((route: any) => route.labelCrud !== "Chat");
        this.props.paramsRoute.pathUrl = filteredRoutes;

        this.props.nextRouter(
            {
                route: 'chatList',
                paramsRoute: {
                    project_product_id: data.project_product_id,
                    project_product_fk_product_id: data.project_product_fk_product_id,
                    project_id: data.projects.project_id,
                    product_id: data.product_id,
                    pathUrl: [
                        {
                            labelCrud: 'Projeto',
                            labelValue: data.projects.project_code + " - " + data.projects.project_description + " - " + data.projects.person_name,
                            link: `${baseURL}/${data.projects.project_id}`,
                            value: 0
                        },
                        {
                            labelCrud: 'Itens',
                            labelValue: data.products.product_name,
                            link: `${baseURL}/${data.projects.project_id}/${data.project_product_id}`,
                            value: 1
                        },
                        // Caso for remover esse comentário, remova o comentário do componentWillUnmount, CARD #590
                        // {
                        //     labelCrud: 'Chats',
                        //     labelValue: stepsData.steps[0].step_name,
                        //     link: `${baseURL}/${data.projects.project_id}/${data.project_product_id}/${stepsData.steps[0].chat_message_fk_ppgstf_id}`,
                        //     value: 2
                        // }
                    ],
                    data
                }
            }
        )
    }

    formatMessages = ({ messagesArray }: { messagesArray: Array<Record<string, any>> }) => {
        const newArray: any = [];
        for (const message of messagesArray) {
            let messageFormat: any = {
                _id: message.chat_message_content_id,
                text: message.chat_message_content_text,
                createdAt: message.chat_message_content_created_at,
                user: {
                    _id: message.chat_message_content_fk_person_id ? message.chat_message_content_fk_person_id : message.chat_message_content_fk_user_id,
                    name: message.person_name ? message.person_name : message.user_name,
                    type: message.person_name ? "person" : "user"
                },
                read: message.read ?? false,
                ref: React.createRef()
            }

            // if (message.chat_message_files) {
            //     messageFormat.image = `${config.api_url}${message.chat_message_files.chat_message_file_path}`
            // }
            if (message.chat_message_replys) {
                messageFormat.reply = {
                    user_name: message.chat_message_replys.user_name ?? message.chat_message_replys.person_name,
                    user_id: message.chat_message_replys.chat_message_content_fk_user_id ?? message.chat_message_replys.chat_message_content_fk_person_id,
                    text: message.chat_message_replys.chat_message_content_text,
                    message_id: message.chat_message_replys.chat_message_content_id,
                }
            }

            // console.log("message ::>", message);
            
            if (message.chat_message_files) {
                let extension = message.chat_message_files.chat_message_file_path.split(".").pop()
                if (["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp", "svg", "ico", "heic", "heif"].includes(extension)) {
                    messageFormat.image = `${config.api_url}${message.chat_message_files.chat_message_file_path}`
                } else if (["mp4", "avi", "mov", "mkv", "webm", "flv", "wmv", "3gp"].includes(extension)) {
                    messageFormat.video = `${config.api_url}${message.chat_message_files.chat_message_file_path}`
                } else {
                    messageFormat[message.chat_message_files.chat_message_file_unique_key.split("-").pop()] = `${config.api_url}${message.chat_message_files.chat_message_file_path}`
                }
            }

            if (message.chat_message_content_forward) {
                messageFormat.forward = true;
            }
            newArray.push(messageFormat)
        }
        return newArray
    }

    startedSockets = async ({ ...props }: { userToken: any }) => {
        let { projectId, projectProductId } = this.props.match.params;
        projectId = projectId ? projectId : this.props.projectId;
        projectProductId = projectProductId ? projectProductId : this.props.projectProductId;

        const paramsRoute: any = this.state.paramsRoute?.steps[0];

        // console.log({ paramsRoute });
        if (this.state.paramsRoute) {
            io.emit("ChoseRoomChat", {
                params: {
                    product_id: this.state.paramsRoute?.project_product_group_step_table_flow_fk_product_id ? this.state.paramsRoute?.project_product_group_step_table_flow_fk_product_id : null,
                    project_id: projectId,
                    project_product_id: projectProductId ? projectProductId : null,
                    step_item: {
                        ...paramsRoute
                    }
                },
                token: localStorage.getItem('token'),
                isWebUser: true
            }, (response: any) => {
                const messagesFormats = this.formatMessages({ messagesArray: response.messages })
                this.setState(
                    {
                        messages: _.orderBy(messagesFormats, ['_id', 'asc']),
                        idRoom: response.idRoom,
                        loading: false
                    }
                )
                setTimeout(() => {
                    this.scrollToBottom();
                }, 250);
            })

            io.on('receiveMessagesChat', (values: any) => {
                const messagesFormats = this.formatMessages({ messagesArray: values })
                this.setState(
                    {
                        messages: [...this.state.messages, ...messagesFormats]
                    }
                )
                if (this.scrolledToBottom) {
                    setTimeout(() => {
                        this.scrollToBottom();
                    }, 250);
                }

                const messagesToRead = messagesFormats.map((message: any) => {return {_id: message._id, user_id: message.user._id}})
                io.emit('readMessagesChat', {
                    params: {
                        product_id: this.props.match.params.projectProductFkProductId ? this.props.match.params.projectProductFkProductId : null,
                        project_id: this.props.match.params.projectId,
                        project_product_id: this.props.match.params.projectProductId ? this.props.match.params.projectProductId : null,
                        step_item: {
                            ...paramsRoute
                        },
                    },
                    token: localStorage.getItem('token'),
                    isWebUser: true,
                    messages: messagesToRead
                })
            })

            io.on('readMessagesChat', (values: Array<number> = []) => {
                if (values.length >= 1) {
                    this.setState({messages: this.state.messages.map((message: any) =>  values.includes(message._id) ? {...message, read: true} : message)})
                }
            })

            this.setState({ onStarted: true })
        }
    }

    scrollToBottom() {
        if (this.state.lastMessageRef && this.state.lastMessageRef.current) {
            this.state.lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    socketSend = ({ messageFormat }: { messageFormat: any }) => {
        const paramsRoute: any = this.state.paramsRoute?.steps[0];
        const params = {
            data: {
                message_body: [messageFormat],
                params: {
                    product_id: this.state.paramsRoute.project_product_group_step_table_flow_fk_product_id ? this.state.paramsRoute.project_product_group_step_table_flow_fk_product_id : null,
                    project_id: this.state.paramsRoute.project_id,
                    project_product_id: this.state.paramsRoute.project_product_group_step_table_flow_fk_project_product_id ? this.state.paramsRoute.project_product_group_step_table_flow_fk_project_product_id : null,
                    step_item: {
                        ...paramsRoute
                    }
                },
            },
            token: localStorage.getItem('chatToken'),
            isWebUser: true
        }
     
        io.emit('receiveMessagesChat', params, (response: any) => {
            const messagesFormats = this.formatMessages({ messagesArray: response })

            this.setState({messages: this.state.messages.map((message: any) => message.temp_id === response[0].temp_id ? messagesFormats[0] : message)});
        })

        if (messageFormat.reply && this.state.replyingMessage) {
            messageFormat.reply = {
                text: this.state.replyingMessage.text, 
                user_id: this.state.replyingMessage.user._id, 
                user_name: this.state.replyingMessage.user.name 
            }
        }
        
        this.handleSendMessage({ message: messageFormat })
    }

    onSendMessage = () => {
        const { newMessage }: any = this.state;
        if ((newMessage.replace(/[^a-zA-Z0-9 ]/g, ""))?.length <= 0) {
            return
        }
        const messageFormat = {
            text: newMessage,
            createdAt: dayjs(),
            user: {
                _id: this.state.userData.user_id,
                name: this.state.userData.user_name,
                type: "user"
            },
            reply: this.state.replyingMessageActive ? this.state.replyingMessage?._id : null,
            temp_id: Math.round(Math.random() * 1000000000)
        }
        this.socketSend({ messageFormat: messageFormat })
        setTimeout(() => {
            this.scrollToBottom();
        }, 250);
    }

    handleSendMessage = ({ message }: { message: Record<string, any> }) => {
        const { newMessage }: any = this.state;
        const messageUpdate = message ? message : newMessage.trim();
        if (messageUpdate) {
            this.setState((prevState: any) => ({
                messages: [
                    ...prevState.messages,  
                    {...message}
                ],
            }));

            this.setState({ newMessage: "" });
            this.unreply();
        }
    };

    handleUploadFiles = (responseUpload: any) => {
        const newMessage: any =
        {
            _id: Math.round(Math.random() * 10000000).toString(),
            createdAt: new Date(),
            user: {
                _id: this.state.userData.user_id,
                name: this.state.userData.user_name,
                type: "user"
            },
            reply: this.state.replyingMessageActive ? this.state.replyingMessage?._id : null,
            customProps: {
                image_unique_id: responseUpload.meta.chat_message_file_unique_key
            },
            temp_id: Math.round(Math.random() * 1000000000)
        }

        let extension = responseUpload.path.split(".").pop()
        if (["jpg", "jpeg", "png", "gif", "bmp", "tiff", "webp", "svg", "ico", "heic", "heif"].includes(extension)) {
            newMessage.image = `${config.api_url}${responseUpload.path}`
            newMessage.text = "image"
        } else if (["mp4", "avi", "mov", "mkv", "webm", "flv", "wmv", "3gp"].includes(extension)) {
            newMessage.video = `${config.api_url}${responseUpload.path}`
            newMessage.text = "video"
        } else {
            newMessage.file = `${config.api_url}${responseUpload.path}`
            newMessage.text = "file"
        }

        this.socketSend({ messageFormat: newMessage })

    };

    handleUploadImage = ({ image }: { image: any }) => {
        const imageSplit = image.split("base64,")
        const imageFile = {
            exif: {

            },
            uri: imageSplit.shift(),
            base64: imageSplit.pop().trim()
        }
        axios
            .post('/chat-client/file/upload', {
                file: imageFile
            }).then((response: any) => {
                const newMessage =
                {
                    _id: Math.round(Math.random() * 10000000).toString(),
                    image: `${config.api_url}${response.data.path}`,
                    text: 'image',
                    createdAt: new Date(),
                    user: {
                        _id: this.state.userData.user_id,
                        name: this.state.userData.user_name,
                        type: "user"
                    },
                    reply: this.state.replyingMessageActive ? this.state.replyingMessage?._id : null,
                    customProps: {
                        image_unique_id: response.data.meta.chat_message_file_unique_key
                    },
                    temp_id: Math.round(Math.random() * 1000000000)
                }

                this.socketSend({ messageFormat: newMessage })
            }).catch((e: any) => {
                Notification.warning(
                    {
                        title: 'Se você esta vendo esse erro, envie para o desenvolvedor',
                        description: `Link do erro: ${e.response.data.file.split('build').pop()}`
                    }
                )
            })
    };

    handleUploadAudio = async (audioBlob: any) => {
        try {
            if (typeof this.state.audioUrl === 'string') {
                let responseBlob = await fetch(this.state.audioUrl)
                audioBlob = await responseBlob.blob();
            } else {
                audioBlob = this.state.audioUrl
            }
            let audioFile = new FormData();
            audioFile.append('audio', audioBlob, 'audio.wav');

            const response = await axios.post('/chat-client/file/upload', audioFile, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            const newMessage = {
                _id: Math.round(Math.random() * 10000000).toString(),
                audio: `${config.api_url}${response.data.path}`,
                text: 'audio',
                createdAt: new Date(),
                user: {
                    _id: this.state.userData.user_id,
                    name: this.state.userData.user_name,
                    type: "user"
                },
                reply: this.state.replyingMessageActive ? this.state.replyingMessage?._id : null,
                customProps: {
                    image_unique_id: response.data.meta.chat_message_file_unique_key
                },
                temp_id: Math.round(Math.random() * 1000000000)
            };

            this.socketSend({ messageFormat: newMessage });

            this.setState({
                audioUrl: null,
                voiceRecording: false,
                senderAudio: parseInt(this.state.senderAudio) + 1
            })
        } catch (e: any) {
            console.log("erro aqui", { e })
            Notification.warning({
                title: 'Se você está vendo esse erro, envie para o desenvolvedor',
                description: `Link do erro: ${e?.response?.data?.file?.split('build')?.pop()}`
            });
        }
        return
    };

    sendDateMessage(messagesToSend: string[]) {
        for (const message of messagesToSend) {
            const messageFormat = {
                text: message,
                createdAt: dayjs(),
                user: {
                    _id: this.state.userData.user_id,
                    name: this.state.userData.user_name,
                    type: "user"
                },
                reply: null,
                temp_id: Math.round(Math.random() * 1000000000)
            }
            this.handleSendMessage({ message: messageFormat })
        }
    }

    handleKeyPress = (e: any) => {
        if (e.key === "Enter") {
            this.onSendMessage()
        }
    };

    handleScroll = (e: any) => {
        const { target } = e;
        
        this.scrolledToBottom = target.scrollHeight === target.scrollTop+target.clientHeight
        const scrolledToTop = target.scrollTop === 0;
        if (scrolledToTop) {
            axios.get(`/api/v1/loader/message/${this.state.idRoom}/${this.state.messages[this.state.messages.length - 1].user['_id']}/${this.state.offSet}`)
                .then((response: any) => {
                    const formatMessages = this.formatMessages({ messagesArray: response.data.items });
                    this.setState({ offSet: response.data.offset })
                    this.setState((prevState: any) => ({
                        messages: [
                            ...formatMessages,
                            ...prevState.messages,
                        ],
                    }));
                }).catch((err: any) => {
                    Notification.warning({
                        title: 'Aviso',
                        description: err.response.data.message
                    })
                })
        }
    };

    onHideCam = () => {
        this.setState({ cameraIsOn: false })
    }

    typeRender = ({ message }: { message: Record<string, any> }) => {
        const output: any = []

        if (message?.image) {
            output.push(
                <div style={{marginBottom: "4px", paddingInline: "10px"}}>
                    <ChatImage
                        url={`http://${window.location.host.split("25565").join("25567")}${message?.image?.split("undefined").pop()}`}
                        alt={message.text}
                        clickImage={() => {
                            this.setState({
                                modalImage: true,
                                modalUrlImage: `http://${window.location.host
                                  .split("25565")
                                  .join("25567")}${message?.image?.split("undefined").pop()}`,
                              });
                        }}
                    />
                </div>
            )
        }
        else if (message?.audio) {
            output.push(
                // <div>
                <div style={{minWidth: 200, marginBlock: "4px", paddingInline: "10px"}}>
                    <ChatAudioPlayer
                        url={`http://${window.location.host.split('25565').join('25567')}${message?.audio?.split('undefined')?.pop()}`}
                    />
                </div>
                //     <div
                //         style={
                //             {
                //                 position: 'relative'
                //             }
                //         }
                //     >
                //         <audio style={{ width: "100%", minWidth: "300px" }} controls preload="metadata">
                //             <source src={`http://${window.location.host.split('25565').join('25567')}${message?.audio?.split('undefined')?.pop()}`} type="audio/wav" />
                //             Seu navegador não suporta o elemento de áudio.
                //         </audio>
                //         <div
                //             style={
                //                 {
                //                     position: 'absolute',
                //                     bottom: "12px",
                //                     right: "5px",
                //                 }
                //             }
                //         >

                //         </div>
                //     </div>
                // </div>
            )
        }
        else if (message?.video) {
            output.push(
                <div>
                    <div
                        style={
                            {
                                position: 'relative'
                            }
                        }
                    >
                        {/* <iframe allowFullScreen frameBorder={0} src={`https://${window.location.host.split('25565').join('25567')}${message?.video?.split('undefined')?.pop()}`} /> */}

                        <video width="320" height="240" controls autoPlay={false} >
                            <source src={`https://${window.location.host.split('25565').join('25567')}${message?.video?.split('undefined')?.pop()}`} type="video/mp4" />
                        </video>

                        <div
                            style={
                                {
                                    position: 'absolute',
                                    bottom: "12px",
                                    right: "5px",
                                }
                            }
                        >

                        </div>
                    </div>
                </div>
            )
        }
        else if (message?.file) {
            output.push(
                <div style={{marginBottom: "4px"}}>
                    <ChatFile 
                        url={`http://${window.location.host.split("25565").join("25567")}${message?.file?.split("undefined").pop()}`}
                        message={message}
                    />
                    {/* <div
                        style={
                            {
                                position: 'relative'
                            }
                        }
                    >
                        <ChatFileUploader showOnly={true} message={message} />
                        <div
                            style={
                                {
                                    position: 'absolute',
                                    bottom: "12px",
                                    right: "5px",
                                }
                            }
                        >

                        </div>
                    </div> */}
                </div>
            )
        }
        else {
            output.push(
                <p style={{ color: "#075e54", paddingBottom: "2px", paddingInline: "6px"}}>
                    {message.text}
                </p>
            )
        }
        return output
    }

    optionsRender = () => {
        return (
            <Popover title="Ação">
              <Button appearance="subtle">Responder</Button>
              <Button appearance="subtle">Encaminhar</Button>
            </Popover>
          );
    }

    onRecording = async () => {
        this.setState({
            voiceRecording: !this.state.voiceRecording
        })
    }

    unreply = () => {
        this.setState({ replyingMessageActive: false });
        setTimeout(() => this.setState({replyingMessage: null}), 500)
    }

    focusReplyMessage = (replyID: any) => {
        const message: MessageType[] = this.state.messages.filter((message: any) => message._id === replyID)
        
        if (message.length <= 0) {
            this.findReplyHistory(replyID, this.state.messages)
            return
        }

        if (message[0].ref && message[0].ref.current) {
            message[0].ref.current.scrollIntoView({ behavior: "smooth" });
            if (message[0].ref.current.classList.contains("reply-focus")) {
                return
            }
            message[0].ref.current.classList.add("reply-focus")
            setTimeout(() => {
                message[0].ref.current.classList.remove("reply-focus")
            }, 2000)
        }
    }

    findReplyHistory = (replyID: number, lastMessages: MessageType[]) => {
        let tempMessages: MessageType[] = lastMessages
        axios.get(`/api/v1/loader/message/${this.state.idRoom}/${this.state.messages[this.state.messages.length - 1].user['_id']}/${this.state.offSet}`)
            .then((response: any) => {  
                const formatMessages = this.formatMessages({ messagesArray: response.data.items });
                const hasReply: MessageType[] = formatMessages.filter((message: any) => message._id === replyID)
                this.setState({ offSet: response.data.offset })
                tempMessages = [
                    ...formatMessages,
                    ...tempMessages,
                ]
                
                if (hasReply.length <= 0) {
                    this.findReplyHistory(replyID, tempMessages)
                    return
                }

                this.setState({messages: tempMessages})
                setTimeout(() => {
                    this.focusReplyMessage(replyID)
                }, 250);
            }).catch((err: any) => {
                this.setState({messages: lastMessages})
                setTimeout(() => {
                    this.scrollToBottom();
                }, 250);
                Notification.warning({
                    title: 'Aviso',
                    description: err.response.data.message
                })
            })
    }

    render(): React.ReactNode {
        let { projectId, projectProductId, ppgstfId } = this.props.match.params;
        projectId = projectId ? projectId : this.props.projectId;
        projectProductId = projectProductId ? projectProductId : this.props.projectProductId;
        ppgstfId = ppgstfId ? ppgstfId : this.props.ppgstfId;

        return (
            <>
                <Modal show={this.state.modalImage} onHide={() => {
                    this.setState({ modalImage: false })
                }}
                    style={{

                    }}
                >
                    <Modal.Body>
                        <img
                            alt="Foto do arquivo"
                            src={this.state.modalUrlImage}
                            style={
                                {
                                    width: 'clamp(12rem, 100%, 100%)',
                                    height: "600px",
                                    objectFit: 'cover'
                                }
                            }
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            color="red"
                            onClick={() => {
                                this.setState({ modalImage: false })
                            }}
                        >
                            Fechar
                        </Button>
                    </Modal.Footer>
                </Modal>
                {this.state.cameraIsOn ? (
                    <CameraComponent
                        onHideCam={this.onHideCam}
                        onSendImage={({ image }) => this.handleUploadImage({ image: image })}
                    />
                ) : (
                    <>
                        {!this.state.loading && (
                            <>
                            {this.props.disableHeader ? (
                                <></>
                            ) : (
                                <HeaderComponent
                                    {...this.props}
                                    dataParams={ppgstfId ? this.props.paramsRoute : this.state.paramsRoute}
                                    previousRoute={projectProductId ? `chats/${projectId}/${projectProductId}` : `chats/${projectId}`}
                                />
                            )}
                            </>
                        )}
                        <div
                            style={
                                {
                                    display: 'flex',
                                    // height: '70vh',
                                    // maxHeight: '70vh',
                                    height: this.props.disableHeader ? '84.6vh' : '70vh',
                                    maxHeight: this.props.disableHeader ? '100vh' : '70vh',
                                }
                            }
                        >
                            {this.state.loading && (<>
                                <Placeholder.Graph active rows={15} height={350} />
                            </>)}
                            {this.state.onStarted && !this.state.loading && (
                                <div
                                    style={{ display: "flex", flexDirection: "column", marginTop: 5, width: '100%', maxHeight: "100%" }}
                                >
                                    <AudioProvider>
                                        <div
                                            style={{
                                                // flex: "1",
                                                overflowY: "auto",
                                                height: '90%',
                                                maxHeight: '90%',
                                                padding: "20px",
                                                // background: "#f2f2f2",
                                                // boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                scrollbarWidth: "thin", // Para navegadores Firefox
                                                scrollbarColor: "transparent transparent", // Para navegadores Chrome

                                            }}
                                            onScroll={this.handleScroll}

                                        >
                                            <>
                                                {this.state.messages.map((message: MessageType, index: number) => {
                                                    return (
                                                        <>
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    flexWrap: "wrap",
                                                                    flex: 1,
                                                                }}
                                                            >
                                                                <div
                                                                    key={index}
                                                                    ref={index === this.state.messages.length - 1 ? this.state.lastMessageRef : message.ref}
                                                                    style={{
                                                                        marginBottom: "10px",
                                                                        maxWidth: "40vw",
                                                                        // minWidth: '10vw',
                                                                        borderRadius: "6px",
                                                                        backgroundColor:
                                                                            message.user.name === this.state.userData.user_name
                                                                                ? "#dcf8c6"
                                                                                : "#fff",
                                                                        alignSelf:
                                                                            message.user.name === this.state.userData.user_name
                                                                                ? "flex-end"
                                                                                : "flex-start",
                                                                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                                                                    }}
                                                                >
                                                                    

                                                                    <div style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'row',
                                                                        justifyContent: 'space-between',
                                                                        gap: "10px",
                                                                        margin: "4px 2px 2px 6px"
                                                                    }}>
                                                                        {message?.forward ? (
                                                                            <span style={{ fontWeight: "100", fontStyle: 'italic', color: "rgba(7, 94, 84, 0.5)", fontSize: 11 }}>
                                                                                <i className="fas fa-share fa-xs"></i> encaminhada....
                                                                            </span>
                                                                        ) : (
                                                                            <span style={{ fontWeight: "bold", color: "#075e54" }}>
                                                                                {message.user.name === this.state.userData.user_name ? "Você" : message.user.name}
                                                                            </span>
                                                                        )}

                                                                        <Dropdown
                                                                            placement={message.user.name === this.state.userData.user_name ? "leftStart" : "rightStart"}
                                                                            disabled={message._id ? false : true}
                                                                            renderTitle={() => {
                                                                                return <button style={{backgroundColor: "transparent", userSelect: "none"}}> <Icon icon={'ellipsis-h'} style={{color: '#075e54'}}/></button>
                                                                            }}
                                                                            style={{display: "grid", placeItems: "center"}}
                                                                        >
                                                                            <Dropdown.Item onSelect={() => this.setState({replyingMessageActive: true, replyingMessage: message})}>
                                                                                <i className="fas fa-reply"></i> 
                                                                                <span style={{marginLeft: 6, fontWeight: "bold"}}>Responder</span>
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item onSelect={() => this.setState({forwardingMessage: message._id})}>
                                                                                <i className="fas fa-share"></i>
                                                                                <span style={{marginLeft: 6, fontWeight: "bold"}}>Encaminhar</span>
                                                                            </Dropdown.Item>
                                                                            <ChatForward
                                                                                closeModal={() => this.setState({forwardingMessage: null})}
                                                                                opened={message._id === this.state.forwardingMessage}
                                                                                params={this.props.paramsRoute}
                                                                                messageForward={message}
                                                                                chatsSteps={this.state.chatsForward}
                                                                            />
                                                                        </Dropdown>

                                                                    </div>
                                                                    {message?.reply && (
                                                                        <div style={{margin: "0px 4px 2px 4px"}}>
                                                                            <Button onClick={() => this.focusReplyMessage(message?.reply?.message_id)} style={{padding: 0, margin: 0, backgroundColor: "transparent", width: "100%"}}>
                                                                                <ChatReply
                                                                                    text={message.reply.text}
                                                                                    user_name={this.state.userData.user_id === message.reply.user_id
                                                                                        ? "Você"
                                                                                        : message.reply.user_name}
                                                                                    style={{borderRadius: "2px"}}
                                                                                />
                                                                            </Button>
                                                                        </div>
                                                                    )}                                   
                                                                    <div
                                                                        style={
                                                                            {
                                                                                display: 'flex',
                                                                                flexDirection: "column",
                                                                                wordBreak: "break-word",
                                                                                // gap: "4px",
                                                                            }
                                                                        }
                                                                    >
                                                                        {this.typeRender(
                                                                            {
                                                                                message: message
                                                                            }
                                                                        )}
                                                                        <span
                                                                            style={{
                                                                                display: "flex",
                                                                                gap: "4px",
                                                                                fontSize: "10px",
                                                                                color: "#075e54",
                                                                                marginLeft: "auto",
                                                                                paddingInline: "8px",
                                                                                alignSelf: "flex-end"
                                                                            }}
                                                                        >
                                                                            {dayjs(message.createdAt).format("HH:mm")}
                                                                            {message.user.name === this.state.userData.user_name && (message.temp_id
                                                                                ? <i className="fas fa-clock"></i>
                                                                                : <i className="fas fa-check" style={{ color: message.read ? "#146fd6" : "inherit"}}></i>)}
                                                                        </span>
                                                                    </div>      
                                                                </div>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                )}
                                            </>

                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                minHeight: "10vh",
                                                display: "flex",
                                                flexDirection: "column",
                                                borderTopLeftRadius: "15px",
                                                borderTopRightRadius: "15px",
                                                boxShadow: "0px -2px 10px rgba(0, 0, 0, 0.1)",
                                                position: 'relative',
                                                backgroundColor: "#dcf8c6",
                                                transition: "ease-in-out 250ms",
                                                paddingBottom: this.state.replyingMessageActive ? "calc(6px + 10vh)" : 0,
                                            }}
                                        >
                                            {this.state.replyingMessage && (
                                                <ChatReply
                                                    text={this.state.replyingMessage.text}
                                                    user_name={this.state.replyingMessage.user.name === this.state.userData.user_name ? "Você" : this.state.replyingMessage.user.name}
                                                    style={{margin: "6px", borderRadius: "9px"}}
                                                >
                                                    <div style={{display: "flex", height: "100%", userSelect: "none"}}>
                                                        <button onClick={this.unreply} style={{backgroundColor: "transparent", padding: 0, width: "20px", height: "20px", borderRadius: "100%"}}>
                                                            <i className="fas fa-times" style={{color: "rgba(7, 94, 84)"}}></i>
                                                        </button>
                                                    </div>
                                                </ChatReply>
                                            )} 
                                            <div
                                                style={{
                                                    height: '10vh',
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    background: "#fff",
                                                    gap: 10,
                                                    padding: "10px",
                                                    borderTopLeftRadius: "15px",
                                                    borderTopRightRadius: "15px",
                                                    position: 'absolute',
                                                    bottom: "0px"
                                                }}
                                            >
                                                {(!this.state.voiceRecording && !this.state.audioUrl) ? (
                                                    <>
                                                        <Dropdown
                                                            placement="topStart"
                                                            renderTitle={() => {
                                                                return <IconButton
                                                                    appearance="primary"
                                                                    style={
                                                                        {
                                                                            backgroundColor: 'rgb(0, 179, 171)'
                                                                        }
                                                                    }
                                                                    icon={<i className="fas fa-paperclip fa-lg"></i>} circle />;
                                                            }}
                                                        >
                                                            <Dropdown.Item
                                                                onSelect={() => {
                                                                    this.setState({ cameraIsOn: true })
                                                                }}
                                                            >
                                                                <i className="fas fa-camera"></i> Camera
                                                            </Dropdown.Item>

                                                            <Dropdown.Item
                                                                onSelect={() => {
                                                                    this.setState({ uploadFileOn: true })
                                                                }}
                                                            >
                                                                <i className="fas fa-folder-open"></i> Arquivos

                                                            </Dropdown.Item>
                                                        </Dropdown>
                                                        {this.state.uploadFileOn && (
                                                            <ChatFileUploader closeStateModal={(modalState: any) => this.setState({ uploadFileOn: modalState })} sendUploadFile={(value: any) => this.handleUploadFiles(value)} oldState={this.state} params={this.props} />
                                                        )}
                                                            {this.state.paramsRoute?.step_item?.step_name !== "CHAT DO CLIENTE" && (
                                                                <ChatUpdateStatus
                                                                    {...this.props}
                                                                    placement="rightEnd"
                                                                    projectProductId={projectProductId}
                                                                    ppgstfId={ppgstfId}
                                                                    chatId={this.state.idRoom}
                                                                    parentState={this.state}
                                                                    submitValues={this.sendDateMessage}
                                                                />
                                                            )}
                                                        <Input
                                                            type="text"
                                                            placeholder="Digite sua mensagem..."
                                                            value={this.state.newMessage}
                                                            onChange={(e) => this.setState({ newMessage: e })}
                                                            onKeyPress={this.handleKeyPress}
                                                            style={{
                                                                flex: "1",
                                                                padding: "10px",
                                                                border: "none",
                                                                borderRadius: "10px",
                                                                marginRight: "10px",
                                                                boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                            }}
                                                        />
                                                    </>) : ""}
                                                <AudioRecorder reset={this.state.senderAudio} setAudio={(url: any) => this.setState({ audioUrl: url })} action={this.state.voiceRecording ? "start" : "stop"} />
                                                {!this.state.audioUrl && (<Button
                                                    onClick={this.onRecording}
                                                    appearance="primary"
                                                    // loading={this.state.voiceRecording}
                                                    style={{
                                                        width: 80,
                                                        background: this.state.voiceRecording ? "rgb(124, 113, 255)" : "rgb(0, 179, 171)",
                                                        color: "#fff",
                                                        padding: "10px",
                                                        border: "none",
                                                        borderRadius: "10px",
                                                        cursor: "pointer",
                                                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                        marginLeft: "auto"
                                                    }}
                                                >
                                                    <i className="fa-solid fas fa-microphone"></i>
                                                </Button>)}



                                                {!this.state.voiceRecording && (<Button
                                                    onClick={this.state.audioUrl ? this.handleUploadAudio : this.onSendMessage}
                                                    appearance="primary"
                                                    style={{
                                                        background: "rgb(0, 179, 171)",
                                                        color: "#fff",
                                                        padding: "10px",
                                                        border: "none",
                                                        borderRadius: "10px",
                                                        cursor: "pointer",
                                                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                        minWidth: "50px",
                                                        marginLeft: "auto"
                                                    }}
                                                >
                                                    <i className="far fa-paper-plane"></i>
                                                </Button>)}
                                            </div>
                                        </div>
                                    </AudioProvider>
                                </div>

                            )}
                            {/* <ChatNecComponent 
                            messages={this.state.messages}
                            newMessage={this.state.newMessage}
                            sendNewMessage={this.handleSendMessage}
                        /> */}
                        </div>
                    </>
                )}
            </>
        )
    }
}