import axios from "../../utilities/axios";
import { Event } from "../Event";

export class ProjectEvents extends Event {
    public async onLoad(props: any) {
        if (this.globalProps.match.url.includes('/add')) {
            await this.setFieldValue('project_users', [this.userContextProps.user_id]);

            this.setState('loadings', [
                'project_users'
            ]);

            this.setState('loadings', []);
        }

        if (this.globalProps.isEdit) {
            this.setState('loadings', ['project_deadline_project']);

            axios
                .get(`/api/v1/project/${this.globalProps.match.params.id}/total-deadline`)
                .then((response: any) => {
                    const { totalMeasureDays } = response.data;
                    this.setFieldValue('project_deadline_project', totalMeasureDays || 0);
                })
                .catch((e: any) => {
                    console.error(e);
                })
                .finally(() => {
                    this.setState("loadings", []);
                });
        }
    }
}